/*/////////////////////////////////////////////////////////////////
//    FileName: login.js
//    Author: Ho Da Zong
//    Created On: 7-May-2021
//    Last Modified On: 18-Oct-2021
//    Copy Rights: Orinno Technology Pte Ltd
//    Description: login screen in JSX type. 
//                 
*/ /////////////////////////////////////////////////////////////////

import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import logo from "../images/logo-orinno-technology.png";

import axios from "axios";
import { baseURL } from "../util/port";

//the class component
class login extends Component {
  constructor(props) {
    super(props);

    //state variable
    this.state = {
      email: "",
      password: "",
      errors: [],
      loading: false,
    };
  }

  //field changes that will trigger state change
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  //send request to api login
  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    const userData = {
      email: this.state.email,
      password: this.state.password,
    };
    //validate user login details and route to home page if success
    await axios
      .post(`${baseURL}/login`, userData)
      .then((response) => {
        localStorage.setItem("AuthToken", `Bearer ${response.data.token}`);
        this.setState({
          loading: false,
        });
        this.props.setLogin(true);
      })
      .catch((error) => {
        this.setState({
          errors: error.response.data,
          loading: false,
        });
      });
  };

  render() {
    const { classes } = this.props;
    const { errors, loading } = this.state;

    //main UI
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img src={logo} alt="logo" className={classes.logo} />
          <br />
          <Typography component="h1" variant="h4">
            Dispatch Admin Portal
          </Typography>
          <br />
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              helperText={errors.email}
              error={errors.email ? true : false}
              onChange={this.handleChange}
              color="primary"
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              helperText={errors.password}
              error={errors.password ? true : false}
              onChange={this.handleChange}
              color="primary"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={this.handleSubmit}
              disabled={loading || !this.state.email || !this.state.password}
            >
              Sign In
              {loading && (
                <CircularProgress size={30} className={classes.progess} />
              )}
            </Button>

            {errors.general && (
              <Typography variant="body2" className={classes.customError}>
                {errors.general}
              </Typography>
            )}
          </form>
        </div>
      </Container>
    );
  }
}

//styles
const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  customError: {
    color: "red",
    fontSize: "0.8rem",
    marginTop: 10,
  },
  progess: {
    position: "absolute",
  },
  logo: {
    justifyContent: "flex-end",
    height: "56px",
  },
});

export default withStyles(styles)(login);
