/*/////////////////////////////////////////////////////////////////
//    FileName: sector.js
//    Author: Ho Da Zong
//    Created On: 7-May-2021
//    Last Modified On: 18-Oct-2021
//    Copy Rights: Orinno Technology Pte Ltd
//    Description: sector DIV component, 
//                 will display at beside home page drawer if called.
//    Changes Log: 18-May-2021 > To check if auth token in local is not NULL only
//				   				 proceed to get data, else route to login.
//				   19-May-2021 > To change delete param pass in also Site. Also
// 								 changing delete pop out phrase.
*/ /////////////////////////////////////////////////////////////////

import React, { Component } from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import CardContent from "@material-ui/core/CardContent";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import axios from "axios";

import { authMiddleWare } from "../util/auth";
import { baseURL } from "../util/port.js";
//transition for when add or edit pop out box
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

//the class component
class sector extends Component {
  constructor(props) {
    super(props);

    //state variable
    this.state = {
      sectorTypes: [],
      parentSites: [],
      sites: [],
      site: "",
      siteBase: "",
      sectorType: "",
      parentSite: "",
      siteLat: "",
      siteLon: "",
      siteId: "",
      errors: [],
      open: false,
      uiLoading: true,
      buttonType: "",
      siteOld: "",
      popOverTrigger: null,
    };
  }

  //when value change handle change also for the state variable
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  //will run once everytime the page render
  componentDidMount = async () => {
    await this.getSectorData();
    const authToken = localStorage.getItem("AuthToken");
    //retrieve the sector type data from db
    authMiddleWare(this.props.history);
    if (authToken == null) {
      alert("Your session is expired, please login to retry.");
      this.setState({ uiLoading: false });
      this.props.setLogin(false);
    } else {
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      await axios
        .get(`${baseURL}/getAllSectorType`)
        .then((response) => {
          this.setState({
            sectorTypes: response.data,
            //uiLoading: false
          });
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 403) {
            alert("Your session is expired, please login to retry.");
            this.setState({ uiLoading: false });
            this.props.setLogin(false);
          }
        });
    }

    //retrieve the sites data from db
    authMiddleWare(this.props.history);
    if (authToken == null) {
      alert("Your session is expired, please login to retry.");
      this.setState({ uiLoading: false });
      this.props.setLogin(false);
    } else {
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      await axios
        .get(`${baseURL}/getSite`)
        .then((response) => {
          this.setState({
            parentSites: response.data,
            uiLoading: false,
          });
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 403) {
            alert("Your session is expired, please login to retry.");
            this.setState({ uiLoading: false });
            this.props.setLogin(false);
          }
        });
    }
  };

  async getSectorData() {
    authMiddleWare(this.props.history);
    const authToken = localStorage.getItem("AuthToken");
    if (authToken == null) {
      alert("Your session is expired, please login to retry.");
      this.setState({ uiLoading: false });
      this.props.setLogin(false);
    } else {
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      //get all the sector from db
      await axios
        .get(`${baseURL}/getSector`)
        .then((response) => {
          this.setState({
            sites: response.data,
            // uiLoading: false
          });
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 403) {
            alert("Your session is expired, please login to retry.");
            this.setState({ uiLoading: false });
            this.props.setLogin(false);
          }
        });
    }
  }

  //edit site to trigger dialogbox open
  handleEditClickOpen(data) {
    this.setState({
      site: data.site.site,
      siteBase: data.site?.siteBase,
      sectorType: data.site?.sectorType || "",
      parentSite: data.site?.parentSite ? { name: data.site?.parentSite } : "",
      siteLat: data.site?.coordinates?.latitude || "",
      siteLon: data.site?.coordinates?.longitude || "",
      siteId: data.site.siteId,
      buttonType: "Edit",
      open: true,
      siteOld: data.site.site,
      errors: [],
    });
  }

  //delete dialogbox
  deleteTodoHandler(data) {
    confirmAlert({
      title: "Confirm to detele the record?",
      message: "This might affect all others related data.",
      buttons: [
        {
          label: "Delete",
          onClick: async () => {
            this.setState({ uiLoading: true });
            authMiddleWare(this.props.history);
            const authToken = localStorage.getItem("AuthToken");
            if (authToken == null) {
              alert("Your session is expired, please login to retry.");
              this.setState({ uiLoading: false });
              this.props.setLogin(false);
            } else {
              axios.defaults.headers.common = { Authorization: `${authToken}` };
              let siteId = data.site.siteId;
              let site = data.site.site;
              //send request to delete sector db
              await axios
                .delete(`${baseURL}/deleteSector/${siteId}/${site}`)
                .then(() => {
                  this.setState({ uiLoading: false });
                  window.location.reload();
                })
                .catch((err) => {
                  if (err.response.status === 403) {
                    alert("Your session is expired, please login to retry.");
                    this.setState({ uiLoading: false });
                    this.props.setLogin(false);
                  }
                  console.log(err);
                });
            }
          },
        },
        {
          label: "Cancel",
          onClick: () => onclose,
        },
      ],
    });
  }

  render() {
    const { classes } = this.props;

    //calling parent function for setting render DIV page
    const handleInputChange = (siteId) => {
      this.props.setRender("block", siteId);
    };

    const { open, errors, sectorTypes, sectorType, parentSites, parentSite } =
      this.state;

    //add sector to trigger the dialogbox open
    const handleClickOpen = () => {
      this.setState({
        siteId: "",
        site: "",
        siteBase: "",
        sectorType: "",
        parentSite: "",
        siteLat: "",
        siteLon: "",
        buttonType: "",
        errors: [],
        open: true,
      });
    };

    //close the add sector dialogbox
    const handleClose = (event) => {
      this.setState({
        open: false,
      });
    };

    //add the sector into db or edit the sector
    const handleSubmit = async (event) => {
      authMiddleWare(this.props.history);
      event.preventDefault();

      const siteAdd = {
        site: this.state.site,
        siteBase: this.state.siteBase,
        sectorType: this.state.sectorType,
        parentSite: this.state.parentSite.name || "",
        siteLat: this.state.siteLat.toString(),
        siteLon: this.state.siteLon.toString(),
      };
      let options = {};
      if (this.state.buttonType === "Edit") {
        options = {
          url: `${baseURL}/addSector/${this.state.siteId}/${encodeURIComponent(
            this.state.siteOld
          )}`,
          method: "put",
          data: siteAdd,
        };
      } else {
        options = {
          url: `${baseURL}/addSector`,
          method: "post",
          data: siteAdd,
        };
      }
      const authToken = localStorage.getItem("AuthToken");
      if (authToken == null) {
        alert("Your session is expired, please login to retry.");
        this.setState({ uiLoading: false });
        this.props.setLogin(false);
      } else {
        axios.defaults.headers.common = { Authorization: `${authToken}` };
        this.setState({
          uiLoading: true,
        });
        //send the request either add or edit
        await axios(options)
          .then(() => {
            this.setState({
              open: false,
              uiLoading: false,
            });
            this.getSectorData();
            // window.location.reload();
          })
          .catch((error) => {
            if (error.response.status === 403) {
              alert("Your session is expired, please login to retry.");
              this.setState({ uiLoading: false });
              this.props.setLogin(false);
            }
            this.setState({
              open: true,
              uiLoading: false,
              errors: error.response.data,
            });
            console.log(error);
          });
      }
    };

    //loading UI
    if (this.state.uiLoading === true) {
      return (
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {this.state.uiLoading && (
            <CircularProgress size={150} className={classes.uiProgess} />
          )}
        </main>
      );
    } else if (this.state.sites === "") {
      //if got no sector data
      return (
        <main className={classes.content}>
          <div className={classes.toolbar}></div>

          <IconButton
            className={classes.floatingButton}
            color="primary"
            aria-label="Add Sector"
            onClick={handleClickOpen}
          >
            <AddCircleIcon style={{ fontSize: 60 }} />
          </IconButton>

          <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
          >
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.site}>
                  {this.state.buttonType === "Edit"
                    ? "Edit Sector"
                    : "Create a new Sector"}
                </Typography>
                <Button
                  autoFocus
                  color="inherit"
                  onClick={handleSubmit}
                  className={classes.submitButton}
                >
                  {this.state.buttonType === "Edit" ? "Save" : "Submit"}
                </Button>
              </Toolbar>
            </AppBar>

            <form className={classes.form} onSubmit={handleSubmit} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="siteName"
                    label="Sector Name"
                    name="site"
                    autoComplete="siteName"
                    helperText={errors.site}
                    value={this.state.site}
                    error={errors.site ? true : false}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="siteNameBase"
                    label="Sector Name Base"
                    name="siteBase"
                    autoComplete="siteNameBase"
                    helperText={errors.siteBase}
                    value={this.state.siteBase}
                    error={errors.siteBase ? true : false}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    options={sectorTypes}
                    id={"sectorType"}
                    name="sectorType"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Sector Type"
                        required
                        helperText={errors.sectorType}
                        error={errors.sectorType ? true : false}
                        variant="outlined"
                        id={"sectorType"}
                      />
                    )}
                    onChange={(event, value) => {
                      this.setState({ sectorType: value });
                    }}
                    value={sectorType}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    options={parentSites}
                    getOptionLabel={(option) => option.name}
                    id={"parentSite"}
                    name="parentSite"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Site"
                        required
                        helperText={errors.parentSite}
                        error={errors.parentSite ? true : false}
                        variant="outlined"
                        id={"parentSite"}
                      />
                    )}
                    onChange={(event, value) => {
                      this.setState({ parentSite: value });
                    }}
                    value={parentSite}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="siteLat"
                    label="Sector Latitude"
                    name="siteLat"
                    autoComplete="siteLat"
                    helperText={errors.siteLat}
                    value={this.state.siteLat}
                    error={errors.siteLat ? true : false}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="siteLon"
                    label="Sector Longitude"
                    name="siteLon"
                    autoComplete="siteLon"
                    helperText={errors.siteLon}
                    value={this.state.siteLon}
                    error={errors.siteLon ? true : false}
                    onChange={this.handleChange}
                  />
                </Grid>
              </Grid>
            </form>
          </Dialog>

          <Typography variant="h3" component="h2">
            Dispatch Sector
          </Typography>

          <div style={{ width: "100%", marginTop: "50%", marginLeft: "100%" }}>
            <Typography variant="h5" component="h7">
              Click “+” button at the bottom right to add a site
            </Typography>
          </div>
        </main>
      );
    } else {
      //if got sites data
      return (
        <main className={classes.content}>
          <div className={classes.toolbar} />

          <IconButton
            className={classes.floatingButton}
            color="primary"
            aria-label="Add Sector"
            onClick={handleClickOpen}
          >
            <AddCircleIcon style={{ fontSize: 60 }} />
          </IconButton>

          <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
          >
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.site}>
                  {this.state.buttonType === "Edit"
                    ? "Edit Sector"
                    : "Create a new Sector"}
                </Typography>
                <Button
                  autoFocus
                  color="inherit"
                  onClick={handleSubmit}
                  className={classes.submitButton}
                >
                  {this.state.buttonType === "Edit" ? "Save" : "Submit"}
                </Button>
              </Toolbar>
            </AppBar>

            <form className={classes.form} onSubmit={handleSubmit} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="siteName"
                    label="Sector Name"
                    name="site"
                    autoComplete="siteName"
                    helperText={errors.site}
                    value={this.state.site}
                    error={errors.site ? true : false}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="siteNameBase"
                    label="Sector Name Base"
                    name="siteBase"
                    autoComplete="siteNameBase"
                    helperText={errors.siteBase}
                    value={this.state.siteBase}
                    error={errors.siteBase ? true : false}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    options={sectorTypes}
                    id={"sectorType"}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Sector Type"
                        required
                        helperText={errors.sectorType}
                        error={errors.sectorType ? true : false}
                        variant="outlined"
                        id={"sectorType"}
                      />
                    )}
                    onChange={(event, value) => {
                      this.setState({ sectorType: value });
                    }}
                    value={sectorType}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    options={parentSites}
                    getOptionLabel={(option) => option.name}
                    id={"parentSite"}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Site"
                        required
                        helperText={errors.parentSite}
                        error={errors.parentSite ? true : false}
                        variant="outlined"
                        id={"parentSite"}
                      />
                    )}
                    onChange={(event, value) => {
                      this.setState({ parentSite: value });
                    }}
                    value={parentSite}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="siteLat"
                    label="Sector Latitude"
                    name="siteLat"
                    autoComplete="siteLat"
                    helperText={errors.siteLat}
                    value={this.state.siteLat}
                    error={errors.siteLat ? true : false}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="siteLon"
                    label="Sector Longitude"
                    name="siteLon"
                    autoComplete="siteLon"
                    helperText={errors.siteLon}
                    value={this.state.siteLon}
                    error={errors.siteLon ? true : false}
                    onChange={this.handleChange}
                  />
                </Grid>
              </Grid>
            </form>
          </Dialog>

          <Typography variant="h3" component="h2">
            Dispatch Sector
          </Typography>
          <Grid container spacing={2}>
            {this.state.sites.map((site, idx) => (
              <Grid key={idx} item xs={12} sm={4}>
                <Card className={classes.root} variant="outlined">
                  <CardContent>
                    {!site.siteBase && (
                      <Typography variant="h5" component="h2" color="secondary">
                        {site.site}
                      </Typography>
                    )}
                    {!!site.siteBase && (
                      <Typography variant="h5" component="h2" color="primary">
                        {site.site}
                      </Typography>
                    )}
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => {
                        handleInputChange(site.site);
                      }}
                    >
                      {" "}
                      View{" "}
                    </Button>
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => this.handleEditClickOpen({ site })}
                    >
                      Edit
                    </Button>
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => this.deleteTodoHandler({ site })}
                    >
                      Delete
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </main>
      );
    }
  }
}

//styles
const styles = (theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  site: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  submitButton: {
    display: "block",
    color: "white",
    textAlign: "center",
    position: "absolute",
    top: 14,
    right: 10,
  },
  floatingButton: {
    position: "fixed",
    bottom: 0,
    right: 0,
  },
  form: {
    width: "98%",
    marginLeft: 13,
    marginTop: theme.spacing(10),
  },
  root: {
    minWidth: 470,
  },
  pos: {
    marginBottom: 12,
  },
  uiProgess: {
    position: "fixed",
    zIndex: "1000",
    height: "31px",
    width: "31px",
    left: "50%",
    top: "35%",
  },
});

export default withStyles(styles)(sector);
