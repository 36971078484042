//API server port

// local PC
// const baseURL = "http://127.0.0.1:9191";

// online server
const baseURL = "https://api.orinnotech.com";
// const baseURL = "http://dispatch.orinnotech.com:7000";
// const baseURL = "http://192.168.1.191:7000";

module.exports = { baseURL };
