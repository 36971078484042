/*/////////////////////////////////////////////////////////////////
//    FileName: damage.js
//    Author: Ramzi TM
//    Created On: 18-May-2022
//    Last Modified On: 18-May-2022
//    Copy Rights: Orinno Technology Pte Ltd
//    Description: damage details DIV component, 
//                 will display at beside home page drawer if called.
*/ /////////////////////////////////////////////////////////////////

import React, { Component, Fragment } from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Card,
  CardContent,
  Divider,
  Button,
  Grid,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import clsx from "clsx";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import { authMiddleWare } from "../util/auth";
import { baseURL } from "../util/port.js";

//main class component
class damage extends Component {
  constructor(props) {
    super(props);

    //state variable
    this.state = {
      uiLoading: true,
      loading: false,
      errors: [],
      recordList: [],
      containerList: [],
      userList: [],
      isEdit: false,
      container: "",
      reportBy: "",
      description: "",
      isReplaced: false,
      replaceDate: new Date().toISOString().slice(0, 10),
      acceptLoc: "",
      remarks: "",
    };
  }

  //will run once everytime the page render
  componentDidMount = async () => {
    const authToken = localStorage.getItem("AuthToken");
    //request API to get all users data
    await this.handleGetRecord();

    //retrieve the container data from db
    authMiddleWare(this.props.history);
    if (authToken == null) {
      alert("Your session is expired, please login to retry.");
      this.setState({ uiLoading: false });
      this.props.setLogin(false);
    } else {
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      await axios
        .get(`${baseURL}/getAllContainer`)
        .then((response) => {
          this.setState({
            containerList: response.data,
          });
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 403) {
            alert("Your session is expired, please login to retry.");
            this.setState({ uiLoading: false });
            this.props.setLogin(false);
          }
        });
    }

    //retrieve the user data from db
    authMiddleWare(this.props.history);
    if (authToken == null) {
      alert("Your session is expired, please login to retry.");
      this.setState({ uiLoading: false });
      this.props.setLogin(false);
    } else {
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      await axios
        .get(`${baseURL}/getAllUser`)
        .then((response) => {
          let fixData = [];
          response.data.forEach((data) => {
            fixData.push(`${data.firstName} ${data.lastName}`);
          });
          this.setState({
            userList: fixData,
            uiLoading: false,
          });
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 403) {
            alert("Your session is expired, please login to retry.");
            this.setState({ uiLoading: false });
            this.props.setLogin(false);
          }
        });
    }
  };

  handleGetRecord = async () => {
    const authToken = localStorage.getItem("AuthToken");
    //request API to get all users data
    authMiddleWare(this.props.history);
    if (authToken == null) {
      alert("Your session is expired, please login to retry.");
      this.setState({ uiLoading: false });
      this.props.setLogin(false);
    } else {
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      await axios
        .get(`${baseURL}/damageList`)
        .then((response) => {
          let fixData = [];
          response.data.forEach((data) => {
            fixData.push({
              id: data.id,
              rawDate: data.createdAt.seconds,
              date: new Date(data.createdAt.seconds * 1000).toLocaleString(
                "es-CL"
              ),
              type:
                data.container.toLowerCase().charAt(0) === "p"
                  ? "PUPAE"
                  : "ADULT",
              container: data.container,
              reportBy: data.reportBy,
              description: data.description,
              isReplaced: data.isReplaced,
              // replaceDate: data.replaceDate || '-',
              replaceDate: data.replaceDate
                ? new Date(data.replaceDate.seconds * 1000)
                    .toISOString()
                    .slice(0, 10)
                : "-",
              replaceBy: data.replaceBy || "-",
              passedTo: data.passedTo || "-",
              acceptLoc: data.acceptLoc || "-",
              remarks: data.remarks || "-",
            });
          });
          this.setState({
            recordList: fixData.sort((a, b) => b.rawDate - a.rawDate),
          });
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 403) {
            alert("Your session is expired, please login to retry.");
            this.setState({ uiLoading: false });
            this.props.setLogin(false);
          }
        });
    }
  };

  handleReset = () => {
    this.setState({
      isEdit: false,
      container: "",
      reportBy: "",
      description: "",
      isReplaced: false,
      replaceDate: new Date().toISOString().slice(0, 10),
      replaceBy: "",
      passedTo: "",
      acceptLoc: "",
      remarks: "",
    });
  };

  handleSubmit = async () => {
    authMiddleWare(this.props.history);
    const authToken = localStorage.getItem("AuthToken");
    if (authToken == null) {
      alert("Your session is expired, please login to retry.");
      this.setState({ uiLoading: false });
      this.props.setLogin(false);
    } else {
      this.setState({
        uiLoading: true,
      });

      //patch the add or update datas
      let recordEdit = {};
      let recordAdd = {};

      if (this.state.isReplaced) {
        recordEdit = {
          reportBy: this.state.reportBy,
          description: this.state.description,
          isReplaced: this.state.isReplaced,
          replaceDate: this.state.replaceDate,
          replaceBy: this.state.replaceBy,
          passedTo: this.state.passedTo,
          acceptLoc: this.state.acceptLoc,
          remarks: this.state.remarks,
        };
        recordAdd = {
          container: this.state.container,
          reportBy: this.state.reportBy,
          description: this.state.description,
          isReplaced: this.state.isReplaced,
          replaceDate: this.state.replaceDate,
          replaceBy: this.state.replaceBy,
          passedTo: this.state.passedTo,
          acceptLoc: this.state.acceptLoc,
          remarks: this.state.remarks,
        };
      } else {
        recordEdit = {
          reportBy: this.state.reportBy,
          description: this.state.description,
          isReplaced: this.state.isReplaced,
          remarks: this.state.remarks,
        };
        recordAdd = {
          container: this.state.container,
          reportBy: this.state.reportBy,
          description: this.state.description,
          isReplaced: this.state.isReplaced,
          remarks: this.state.remarks,
        };
      }

      let options = {};
      if (this.state.isEdit) {
        options = {
          url: `${baseURL}/damageEdit/${this.state.isEdit}`,
          method: "put",
          data: recordEdit,
        };
      } else {
        options = {
          url: `${baseURL}/damageAdd`,
          method: "post",
          data: recordAdd,
        };
      }

      axios.defaults.headers.common = { Authorization: `${authToken}` };
      //request to api for database update
      axios(options)
        .then(async () => {
          await this.handleGetRecord();
          this.handleReset();
          this.setState({
            uiLoading: false,
            errors: [],
          });
          alert("Record damage updated.");
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 403) {
            alert("Your session is expired, please login to retry.");
            this.setState({ uiLoading: false });
            this.props.setLogin(false);
          }
          this.setState({
            open: true,
            uiLoading: false,
            errors: error.response.data,
          });
          console.log(error.response.data);
        });
    }
  };

  handleEdit = (data) => {
    this.setState({
      isEdit: data.id,
      container: data.container,
      reportBy: data.reportBy,
      description: data.description,
      isReplaced: data.isReplaced,
      replaceDate: data.replaceDate,
      replaceBy: data.replaceBy,
      passedTo: data.passedTo,
      acceptLoc: data.acceptLoc,
      remarks: data.remarks,
    });
  };

  //delete dialogbox
  handleDelete = (data) => {
    confirmAlert({
      title: "Confirm to delete the record?",
      // message: 'This might affect all others related data.',
      buttons: [
        {
          label: "Delete",
          onClick: async () => {
            this.setState({ uiLoading: true });
            authMiddleWare(this.props.history);
            const authToken = localStorage.getItem("AuthToken");
            if (authToken == null) {
              alert("Your session is expired, please login to retry.");
              this.setState({ uiLoading: false });
              this.props.setLogin(false);
            } else {
              axios.defaults.headers.common = { Authorization: `${authToken}` };
              //send request to delete site db
              await axios
                .delete(`${baseURL}/damageDelete/${data.id}`)
                .then(async () => {
                  await this.handleGetRecord();
                  this.setState({
                    uiLoading: false,
                    errors: [],
                  });
                })
                .catch((err) => {
                  if (err.response.status === 403) {
                    alert("Your session is expired, please login to retry.");
                    this.setState({ uiLoading: false });
                    this.props.setLogin(false);
                  }
                  console.log(err);
                });
            }
          },
        },
        {
          label: "Cancel",
          onClick: () => onclose,
        },
      ],
    });
  };

  //change value and assign to state change
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  //change value and assign to state change
  handleChangeCheck = (event) => {
    this.setState({
      [event.target.name]: !this.state[event.target.name],
    });
  };

  //render function
  render() {
    const { classes } = this.props;
    const {
      recordList,
      containerList,
      userList,
      isEdit,
      container,
      reportBy,
      description,
      isReplaced,
      replaceDate,
      replaceBy,
      passedTo,
      acceptLoc,
      remarks,
    } = this.state;

    //loading UI
    if (this.state.uiLoading === true) {
      return (
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {this.state.uiLoading && (
            <CircularProgress size={150} className={classes.uiProgess} />
          )}
        </main>
      );
    } else {
      //if not loading, return actual UI and dialogbox
      return (
        <main className={classes.content}>
          <div className={classes.toolbar}></div>
          <Card className={clsx(classes.root, classes)}>
            <CardContent>
              <div className={classes.details}>
                <div>
                  <Typography
                    className={classes.locationText}
                    gutterBottom
                    variant="h4"
                  >
                    Damage Record
                  </Typography>
                </div>
              </div>
              <div className={classes.progress} />
            </CardContent>
            <Divider />
          </Card>

          <br />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={containerList}
                id={"container"}
                // renderInput={(params) => <TextField {...params} id={"container"} margin="normal" required helperText={errors["container"]} error={errors["container"] ? true : false} />}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="Container ID"
                    variant="outlined"
                    id={"container"}
                  />
                )}
                onChange={(event, value) => {
                  console.log(value);
                  this.setState({ container: value });
                }}
                value={container}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={userList}
                id={"reportBy"}
                // renderInput={(params) => <TextField {...params} id={"reportBy"} margin="normal" required helperText={errors["reportBy"]} error={errors["reportBy"] ? true : false} />}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="Reported By"
                    variant="outlined"
                    id={"reportBy"}
                  />
                )}
                onChange={(event, value) => {
                  this.setState({ reportBy: value });
                }}
                value={reportBy}
              />
            </Grid>

            <Grid item xs={11}>
              <TextField
                size="small"
                variant="outlined"
                fullWidth
                id="description"
                label="Damage Description"
                name="description"
                autoComplete="description"
                value={description}
                onChange={this.handleChange}
                color="primary"
              />
            </Grid>

            <Grid item xs={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isReplaced}
                    onChange={this.handleChangeCheck}
                    name="isReplaced"
                    color="primary"
                  />
                }
                label="Replaced?"
              />
            </Grid>

            {!isReplaced && (
              <Grid item xs={12}>
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  id="remarks"
                  label="Remarks"
                  name="remarks"
                  autoComplete="remarks"
                  value={remarks}
                  onChange={this.handleChange}
                  color="primary"
                />
              </Grid>
            )}

            {!!isReplaced && (
              <>
                <Grid item xs={12} sm={4}>
                  <TextField
                    size="small"
                    variant="outlined"
                    type="date"
                    fullWidth
                    id="replaceDate"
                    label="Replace at"
                    name="replaceDate"
                    autoComplete="replaceDate"
                    value={replaceDate}
                    onChange={this.handleChange}
                    color="primary"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    options={userList}
                    id={"replaceBy"}
                    // renderInput={(params) => <TextField {...params} id={"replaceBy"} margin="normal" required helperText={errors["replaceBy"]} error={errors["replaceBy"] ? true : false} />}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="Replace By"
                        variant="outlined"
                        id={"replaceBy"}
                      />
                    )}
                    onChange={(event, value) => {
                      this.setState({ replaceBy: value });
                    }}
                    value={replaceBy}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    options={userList}
                    id={"passTo"}
                    // renderInput={(params) => <TextField {...params} id={"passTo"} margin="normal" required helperText={errors["passTo"]} error={errors["passTo"] ? true : false} />}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="Passed to"
                        variant="outlined"
                        id={"passTo"}
                      />
                    )}
                    onChange={(event, value) => {
                      this.setState({ passedTo: value });
                    }}
                    value={passedTo}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    id="acceptLoc"
                    label="Accepting Location"
                    name="acceptLoc"
                    autoComplete="acceptLoc"
                    value={acceptLoc}
                    onChange={this.handleChange}
                    color="primary"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    id="remarks"
                    label="Remarks"
                    name="remarks"
                    autoComplete="remarks"
                    value={remarks}
                    onChange={this.handleChange}
                    color="primary"
                  />
                </Grid>
              </>
            )}
          </Grid>
          {!isEdit && (
            <Button
              style={{ marginTop: 20, marginBottom: 20 }}
              variant="contained"
              size="small"
              color="primary"
              onClick={this.handleSubmit}
            >
              Save
            </Button>
          )}
          {!!isEdit && (
            <>
              <Button
                style={{ marginTop: 20, marginBottom: 20, marginRight: 5 }}
                variant="contained"
                size="small"
                color="primary"
                onClick={this.handleSubmit}
              >
                Update
              </Button>
              <Button
                style={{ marginTop: 20, marginBottom: 20 }}
                variant="contained"
                size="small"
                color="primary"
                onClick={this.handleReset}
              >
                Cancel
              </Button>
            </>
          )}

          <br />

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className={classes.paperTable}>
                <Table stickyHeader className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Container ID</TableCell>
                      <TableCell>Report By</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Replaced?</TableCell>
                      <TableCell>Replace Date</TableCell>
                      <TableCell>Replace By</TableCell>
                      <TableCell>Passed To</TableCell>
                      <TableCell>Accepting Location</TableCell>
                      <TableCell>Remarks</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!recordList.length &&
                      recordList.map((item, i) => (
                        <Fragment key={`row-${i}`}>
                          <TableRow>
                            <TableCell>{item.date}</TableCell>
                            <TableCell>{item.type}</TableCell>
                            <TableCell>{item.container}</TableCell>
                            <TableCell>{item.reportBy}</TableCell>
                            <TableCell>{item.description}</TableCell>
                            <TableCell>
                              {item.isReplaced ? "Yes" : "No"}
                            </TableCell>
                            <TableCell>{item.replaceDate}</TableCell>
                            <TableCell>{item.replaceBy}</TableCell>
                            <TableCell>{item.passedTo}</TableCell>
                            <TableCell>{item.acceptLoc}</TableCell>
                            <TableCell>{item.remarks}</TableCell>
                            <TableCell>
                              <Button
                                style={{ marginRight: 5 }}
                                variant="contained"
                                size="small"
                                color="primary"
                                onClick={() => this.handleEdit(item)}
                              >
                                Edit
                              </Button>
                              <Button
                                style={{}}
                                variant="contained"
                                size="small"
                                color="primary"
                                onClick={() => this.handleDelete(item)}
                              >
                                Delete
                              </Button>
                            </TableCell>
                          </TableRow>
                        </Fragment>
                      ))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          </Grid>
        </main>
      );
    }
  }
}

//styles
const styles = (theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  root: { width: "800px" },
  details: {
    display: "flex",
  },
  locationText: {
    paddingLeft: "15px",
  },
  uiProgess: {
    position: "fixed",
    zIndex: "1000",
    height: "31px",
    width: "31px",
    left: "50%",
    top: "35%",
  },
  paperTable: {
    width: "100%",
    overflowX: "auto",
    maxHeight: 300,
  },
});

export default withStyles(styles)(damage);
