import React, { Component, Fragment } from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Card,
  CardContent,
  Divider,
  Button,
  Grid,
  TextField,
} from "@material-ui/core";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import clsx from "clsx";
import axios from "axios";
import { authMiddleWare } from "../util/auth.js";
import { confirmAlert } from "react-confirm-alert"; // Import
import { baseURL } from "../util/port.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Autocomplete from "@material-ui/lab/Autocomplete";

//main class component
class setting extends Component {
  constructor(props) {
    super(props);

    //state variable
    this.state = {
      uiLoading: true,
      loading: false,
      errors: [],
      userList: [],
      userListSupervisor: [],
      userSelected: null,
    };
  }

  //will run once everytime the page render
  componentDidMount = async () => {
    await this.handleGetUser();
  };

  handleGetUser = async () => {
    const authToken = localStorage.getItem("AuthToken");
    //retrieve the user data from db
    authMiddleWare(this.props.history);
    if (authToken == null) {
      alert("Your session is expired, please login to retry.");
      this.setState({ uiLoading: false });
      this.props.setLogin(false);
    } else {
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      await axios
        .get(`${baseURL}/getAllUser`)
        .then((response) => {
          let fixData = [];
          let fixDataSupervisor = [];
          response.data.forEach((data) => {
            if (data?.supervisorAssign) {
              fixDataSupervisor.push({
                email: data.id,
                id: data.userId,
                name: `${data.firstName} ${data.lastName}`,
                firstName: data.firstName,
                role: data.role,
              });
            } else {
              fixData.push({
                email: data.id,
                id: data.userId,
                name: `${data.firstName} ${data.lastName}`,
                firstName: data.firstName,
                role: data.role,
              });
            }
          });
          this.setState({
            userList: fixData,
            userListSupervisor: fixDataSupervisor,
            uiLoading: false,
          });
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 403) {
            alert("Your session is expired, please login to retry.");
            this.setState({ uiLoading: false });
            this.props.setLogin(false);
          }
        });
    }
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  //save data to db
  handleSubmit = async (data, value) => {
    this.setState({ uiLoading: true });
    authMiddleWare(this.props.history);
    const authToken = localStorage.getItem("AuthToken");
    if (authToken == null) {
      alert("Your session is expired, please login to retry.");
      this.setState({ uiLoading: false });
      this.props.setLogin(false);
    } else {
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      //send request to delete site db
      await axios
        .delete(`${baseURL}/setSupervisorAssign/${data.email}/${value}`)
        .then(async () => {
          await this.handleGetUser();
          this.setState({
            userSelected: null,
            uiLoading: false,
            errors: [],
          });
        })
        .catch((err) => {
          if (err.response.status === 403) {
            alert("Your session is expired, please login to retry.");
            this.setState({ uiLoading: false });
            this.props.setLogin(false);
          }
          console.log(err);
        });
    }
  };

  //remove dialogbox
  handleRemove = (data) => {
    confirmAlert({
      title: `Confirm to remove ${data.name} from supervisor assignment?`,
      buttons: [
        {
          label: "Remove",
          onClick: async () => {
            this.handleSubmit(data, "false");
          },
        },
        {
          label: "Cancel",
          onClick: () => onclose,
        },
      ],
    });
  };

  //render function
  render() {
    const { classes } = this.props;
    const { userList, userListSupervisor, userSelected } = this.state;

    //loading UI
    if (this.state.uiLoading === true) {
      return (
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {this.state.uiLoading && (
            <CircularProgress size={150} className={classes.uiProgess} />
          )}
        </main>
      );
    } else {
      //if not loading, return actual UI and dialogbox
      return (
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Card className={clsx(classes.root, classes)}>
            <CardContent>
              <div className={classes.details}>
                <div>
                  <Typography
                    className={classes.locationText}
                    gutterBottom
                    variant="h4"
                  >
                    Set Supervisor User
                  </Typography>
                </div>
              </div>
              <div className={classes.progress} />
            </CardContent>
            <Divider />
          </Card>

          <br />
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Autocomplete
                style={{ width: "100%" }}
                options={userList}
                getOptionLabel={(option) => option.name}
                id={"userSelected"}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="User"
                    id={"userSelected"}
                    required
                  />
                )}
                onChange={(event, value) => {
                  console.log(value);
                  this.setState({ userSelected: value });
                }}
                value={userSelected}
              />
            </Grid>
            <Grid item xs={4}>
              <Button
                color="primary"
                variant="contained"
                className={classes.addButton}
                onClick={() => this.handleSubmit(userSelected, "true")}
              >
                Add User
              </Button>
            </Grid>
          </Grid>

          <Divider
            variant="middle"
            style={{ marginTop: 30, marginBottom: 30, width: "100%" }}
          />

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className={classes.paperTable}>
                <Table stickyHeader className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Email</TableCell>
                      <TableCell>User Name</TableCell>
                      <TableCell>User Id</TableCell>
                      <TableCell>Role</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!userListSupervisor.length &&
                      userListSupervisor.map((item, i) => (
                        <Fragment key={`row-${i}`}>
                          <TableRow>
                            <TableCell>{item.email}</TableCell>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>{item.id}</TableCell>
                            <TableCell>{item.role}</TableCell>
                            <TableCell>
                              <Button
                                style={{}}
                                variant="contained"
                                size="small"
                                color="primary"
                                onClick={() => this.handleRemove(item)}
                              >
                                Remove
                              </Button>
                            </TableCell>
                          </TableRow>
                        </Fragment>
                      ))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          </Grid>
        </main>
      );
    }
  }
}

//styles
const styles = (theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  root: { width: "800px" },
  details: {
    display: "flex",
  },
  locationText: {
    paddingLeft: "15px",
  },
  errorText: {
    paddingLeft: "15px",
    color: "red",
  },
  input: {
    display: "none",
  },
  uiProgess: {
    position: "fixed",
    zIndex: "1000",
    height: "31px",
    width: "31px",
    left: "50%",
    top: "35%",
  },
  progess: {
    position: "absolute",
  },
  paperTable: {
    width: "100%",
    overflowX: "auto",
    maxHeight: 500,
  },
  assignText: {
    textAlign: "left",
  },
  assignButton: {
    position: "absolute",
    right: "0",
  },
  addButton: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  deleteButton: {
    marginTop: "10px",
    marginLeft: "10px",
  },
  dialogeStyle: {
    maxWidth: "50%",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

export default withStyles(styles)(setting);
