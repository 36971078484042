/*/////////////////////////////////////////////////////////////////
//    FileName: App.js
//    Author: Ho Da Zong
//    Created On: 7-May-2021
//    Last Modified On: 15-Oct-2021
//    Copy Rights: Orinno Technology Pte Ltd
//    Description: Will be rendered on index.html once the page launch, 
//                 including all the path of the app.
*//////////////////////////////////////////////////////////////////


import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import home from './pages/home';
import './App.css';


//function that will tag to index.html and index.js and just like JS coding, using function component to get and render UI
function App() {
  //return to render the UI, with Material UI theme, Router Switch is linking supply patch, path is specific then other page navigation can use, others page all is render under home.js by component
  return (
    <MuiThemeProvider theme={theme}>
    <Router>
    <div>
       <Switch>
           
           <Route exact path="/" component={home}/>
       </Switch>
    </div>
</Router>
</MuiThemeProvider>
  );
}

//material UI theme
const theme = createMuiTheme({
	palette: {
		primary: {
			
			main: '#000000',
			
		  },
	}
});

//must be export so other js code file can import and use it
export default App;
