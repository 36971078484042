/*/////////////////////////////////////////////////////////////////
//    FileName: release.js
//    Author: Ramzi TM
//    Created On: 18-May-2022
//    Last Modified On: 18-May-2022
//    Copy Rights: Orinno Technology Pte Ltd
//    Description: release details DIV component, 
//                 will display at beside home page drawer if called.
*/ /////////////////////////////////////////////////////////////////

import React, { Component, Fragment } from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
  Button,
} from "@material-ui/core";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import clsx from "clsx";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import dayjs from "dayjs";
import { CSVLink } from "react-csv";
import axios from "axios";
import { authMiddleWare } from "../util/auth";
import { baseURL } from "../util/port.js";

//export excel file header
const headers = [
  { label: "Type", key: "type" },
  { label: "Site", key: "site" },
  { label: "Sector ID", key: "sector" },
  { label: "Postal Code", key: "postal" },
  { label: "Block", key: "block" },
  { label: "Level", key: "level" },
  { label: "Release Point", key: "point" },
  { label: "Point Type", key: "pointType" },
  { label: "Container ID", key: "container" },
  { label: "Year", key: "year" },
  { label: "E-week", key: "eweek" },
  { label: "Date (DD/MM/YYYY)", key: "date" },
  { label: "Time (HH:MM:SS)", key: "time" },
  { label: "Name of Officer", key: "officer" },
  { label: "Crate ID", key: "crateNo" },
  { label: "Remarks", key: "remarks" },
];
const headersCrate = [
  { label: "Crate ID", key: "crateNo" },
  { label: "Quantity", key: "crateQty" },
  { label: "Date (DD/MM/YYYY)", key: "date" },
  { label: "Time (HH:MM:SS)", key: "time" },
  { label: "Name of Officer", key: "officer" },
  { label: "Site", key: "site" },
];
const headersMonthlyBlock = [
  { label: "Year", key: "year" },
  { label: "Week", key: "eweek" },
  { label: "Site", key: "site" },
  { label: "Sector ID", key: "sector" },
  { label: "Total no. of blocks deployed (adults)", key: "totalBlockAdult" },
  { label: "Total no. of blocks deployed (pupae)", key: "totalBlockPupae" },
  { label: "Total unique blocks deployed (adults)", key: "uniqueBlockAdult" },
  { label: "Total unique blocks deployed (pupae)", key: "uniqueBlockPupae" },
  {
    label: "Total no. of Release containers (adults) deployed",
    key: "totalAdult",
  },
  {
    label: "Total no. of Release containers (pupae) deployed",
    key: "totalPupae",
  },
];
const headersMonthlyLanded = [
  { label: "Year", key: "year" },
  { label: "Week", key: "eweek" },
  { label: "Site", key: "site" },
  {
    label: "Total no. of landed sectors deployed (adults)",
    key: "totalLandedAdult",
  },
  {
    label: "Total no. of landed sectors deployed (pupae)",
    key: "totalLandedPupae",
  },
  {
    label: "Total unique landed sectors deployed (adults)",
    key: "uniqueLandedAdult",
  },
  {
    label: "Total unique landed sectors deployed (pupae)",
    key: "uniqueLandedPupae",
  },
  {
    label: "Total no. of Release containers (adults) damaged",
    key: "totalAdult",
  },
  {
    label: "Total no. of Release containers (pupae) damaged",
    key: "totalPupae",
  },
];

let eweek = dayjs("06-06-2022");

//main class component
class release extends Component {
  constructor(props) {
    super(props);

    //state variable
    this.state = {
      uiLoading: true,
      loading: false,
      errors: [],
      invalidDatas: [],
      weeklyList: [],
      weeklyListCrate: [],
      monthlyBlocksRelease: [],
      monthlyLandedDamaged: [],
      startDate: new Date().toISOString().split("T")[0],
      endDate: new Date().toISOString().split("T")[0],
    };
  }

  //will run once everytime the page render
  componentDidMount = async () => {
    const authToken = localStorage.getItem("AuthToken");

    authMiddleWare(this.props.history);
    if (authToken == null) {
      alert("Your session is expired, please login to retry.");
      this.setState({ uiLoading: false });
      this.props.setLogin(false);
    } else {
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      await axios
        .get(`${baseURL}/getSetting`)
        .then((response) => {
          eweek = dayjs(
            response.data.filter((v) => v.name === "eWeek")[0].value
          );
          this.setState({
            uiLoading: false,
          });
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 403) {
            alert("Your session is expired, please login to retry.");
            this.setState({ uiLoading: false });
            this.props.setLogin(false);
          }
        });
    }
  };

  getReportData = async () => {
    this.setState({ uiLoading: true });
    const authToken = localStorage.getItem("AuthToken");
    //request API to get all dispatch data
    authMiddleWare(this.props.history);
    if (authToken == null) {
      alert("Your session is expired, please login to retry.");
      this.setState({ uiLoading: false });
      this.props.setLogin(false);
    } else {
      const getSite = (site, list) => {
        const search = list.find((v) => v.site === site);
        if (search?.parentSite) {
          return search.parentSite;
        } else {
          return "-";
        }
      };
      const getSiteByUserID = (userID, list) => {
        const search = list.find((v) => v.officerId === userID);
        if (search?.site) {
          return search.site;
        } else {
          return "-";
        }
      };
      const getPostal = (block, sector, list) => {
        const search = list.find((v) => v.block === block && v.site === sector);
        if (search?.postal) {
          return search.postal;
        } else {
          return "-";
        }
      };
      const getPointType = (sector, block, level, point, list) => {
        const search = list.find(
          (v) =>
            v.site === sector &&
            v.block === block &&
            v.level === level &&
            v.releasePointOne === point
        );
        if (search?.pointType) {
          return search.pointType;
        } else {
          return "-";
        }
      };
      const getWeek = (date) => {
        const checkDate = dayjs(date);
        const fixWeek = checkDate.diff(eweek, "week") < 0 ? -1 : 1;
        return checkDate.diff(eweek, "week") + fixWeek;
      };

      axios.defaults.headers.common = { Authorization: `${authToken}` };
      await axios
        .get(
          `${baseURL}/getWeeklyReleaseRecord/${this.state.startDate}/${this.state.endDate}`
        )
        .then((response) => {
          // sort by firstnName
          const sortName = response.data.list.sort((a, b) =>
            a.officer > b.officer ? 1 : b.officer > a.officer ? -1 : 0
          );
          // create structure for table
          let fixDataWeek = [];
          sortName.forEach((data) => {
            fixDataWeek.push({
              id: data.id,
              type:
                data.container.toLowerCase().charAt(0) === "p"
                  ? "PUPAE"
                  : "ADULT",
              site: getSite(data.sector, response.data.sites),
              sector: data.sector,
              postal: getPostal(data.block, data.sector, response.data.blocks),
              block: data.block,
              level: data.point.split("-")[0],
              point: data.point.split("-")[1],
              pointType: getPointType(
                data.sector,
                data.block,
                data.point.split("-")[0],
                data.point.split("-")[1],
                response.data.points
              ),
              container: data.container,
              year: new Date(data.date.seconds * 1000).getFullYear(),
              eweek: getWeek(
                new Date(data.date.seconds * 1000)
                  .toLocaleString("en-CA")
                  .split(", ")[0]
              ),
              rawDate: data.date.seconds,
              date: new Date(data.date.seconds * 1000)
                .toLocaleString("en-GB")
                .split(", ")[0],
              time: new Date(data.date.seconds * 1000)
                .toLocaleString("en-GB")
                .split(", ")[1],
              officer: data.officer,
              officerId: data.officerId,
              crateNo: "",
              remarks: "",
            });
          });

          const dataCrate = fixDataWeek.reduce(function (r, a) {
            r[a.officerId] = r[a.officerId] || [];
            r[a.officerId].push(a);
            return r;
          }, Object.create(null));
          console.log(dataCrate);

          let fixDataCrateWeek = [];
          for (const [key, value] of Object.entries(dataCrate)) {
            // eslint-disable-next-line no-loop-func
            response.data.crate.forEach((val) => {
              if (val.userId === key && val.crateNo[0].qty) {
                let counter = 0;
                for (var a = val.crateNo.length - 1; a > -1; a--) {
                  for (var b = 0; b < val.crateNo[a].qty; b++) {
                    const tempDat = value[b + counter];
                    if (tempDat) {
                      tempDat.crateNo = val.crateNo[a].number;
                      fixDataCrateWeek.push(tempDat);
                    } else {
                      fixDataCrateWeek.push(tempDat);
                    }
                  }
                  counter = counter + val.crateNo[a].qty;
                }
              } else {
                fixDataCrateWeek = [...fixDataCrateWeek, ...value];
              }
            });
          }
          console.log(fixDataCrateWeek);

          // search for crate ID that quantity not equal to 35
          let crateNot35 = [];
          response.data.crate.forEach((val) => {
            if (val.crateNo[0].qty) {
              for (var a = val.crateNo.length - 1; a > -1; a--) {
                if (val.crateNo[a].qty !== 35) {
                  crateNot35.push({
                    crateNo: val.crateNo[a].number,
                    crateQty: val.crateNo[a].qty,
                    date: new Date(val.date.seconds * 1000)
                      .toLocaleString("en-GB")
                      .split(", ")[0],
                    time: new Date(val.date.seconds * 1000)
                      .toLocaleString("en-GB")
                      .split(", ")[1],
                    officer: val.firstName,
                    site: getSiteByUserID(val.userId, fixDataWeek),
                  });
                }
              }
            }
          });

          // create structure damaged
          let fixDataDamaged = [];
          response.data.damaged.forEach((data) => {
            fixDataDamaged.push({
              id: data.id,
              container: data.container,
              type:
                data.container.toLowerCase().charAt(0) === "p"
                  ? "PUPAE"
                  : "ADULT",
              eweek: getWeek(
                new Date(data.date.seconds * 1000)
                  .toLocaleString("en-CA")
                  .split(", ")[0]
              ),
            });
          });
          console.log(fixDataDamaged);

          const dataMonthBlock = fixDataWeek.reduce(function (r, a) {
            r[`${a.eweek} - ${a.sector}`] = r[`${a.eweek} - ${a.sector}`] || [];
            r[`${a.eweek} - ${a.sector}`].push(a);
            return r;
          }, Object.create(null));
          console.log(dataMonthBlock);

          let fixDataMonthBlock = [];
          for (const [key, value] of Object.entries(dataMonthBlock)) {
            const adult = value.filter((a) => a.type === "ADULT");
            const pupae = value.filter((a) => a.type === "PUPAE");
            fixDataMonthBlock.push({
              id: key,
              year: value[0].year,
              eweek: value[0].eweek,
              site: value[0].site,
              sector: value[0].sector,
              totalAdult: adult.length,
              totalPupae: pupae.length,
              totalBlockAdult: adult.length,
              totalBlockPupae: pupae.length,
              uniqueBlockAdult: [...new Set(adult.map((item) => item.block))]
                .length,
              uniqueBlockPupae: [...new Set(pupae.map((item) => item.block))]
                .length,
            });
          }

          const dataMonthLanded = fixDataWeek.reduce(function (r, a) {
            r[`${a.eweek} - ${a.site}`] = r[`${a.eweek} - ${a.site}`] || [];
            r[`${a.eweek} - ${a.site}`].push(a);
            return r;
          }, Object.create(null));
          console.log(dataMonthLanded);

          let fixDataMonthLanded = [];
          for (const [key, value] of Object.entries(dataMonthLanded)) {
            const adult = value.filter((a) => a.type === "ADULT");
            const pupae = value.filter((a) => a.type === "PUPAE");
            fixDataMonthLanded.push({
              id: key,
              year: value[0].year,
              eweek: value[0].eweek,
              site: value[0].site,
              totalAdult: fixDataDamaged.filter(
                (a) => a.container === "ADULT" && a.eweek === value[0].eweek
              ).length,
              totalPupae: fixDataDamaged.filter(
                (a) => a.container === "PUPAE" && a.eweek === value[0].eweek
              ).length,
              totalLandedAdult: adult.length,
              totalLandedPupae: pupae.length,
              uniqueLandedAdult: [...new Set(adult.map((item) => item.sector))]
                .length,
              uniqueLandedPupae: [...new Set(pupae.map((item) => item.sector))]
                .length,
            });
          }

          // short by date descending, if ascending (a-b)
          this.setState({
            uiLoading: false,
            weeklyList: fixDataWeek.sort((a, b) => b.rawDate - a.rawDate),
            weeklyListCrate: crateNot35,
            monthlyBlocksRelease: fixDataMonthBlock,
            monthlyLandedDamaged: fixDataMonthLanded,
          });
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 403) {
            alert("Your session is expired, please login to retry.");
            this.setState({ uiLoading: false });
            this.props.setLogin(false);
          }
        });
    }
  };

  handleChange = (event) => {
    console.log(event.target.value);
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  //render function
  render() {
    const { classes } = this.props;
    const { weeklyList, weeklyListCrate, startDate, endDate } = this.state;

    //loading UI
    if (this.state.uiLoading === true) {
      return (
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {this.state.uiLoading && (
            <CircularProgress size={150} className={classes.uiProgess} />
          )}
        </main>
      );
    } else {
      //if not loading, return actual UI and dialogbox
      return (
        <main className={classes.content}>
          <div className={classes.toolbar}></div>
          <Card className={clsx(classes.root, classes)}>
            <CardContent>
              <div className={classes.details}>
                <div>
                  <Typography
                    className={classes.locationText}
                    gutterBottom
                    variant="h4"
                  >
                    Release Record
                  </Typography>
                </div>
              </div>
              <div className={classes.progress} />
            </CardContent>
            <Divider />
          </Card>

          <br />
          <Grid container spacing={4}>
            <Grid item xs={2}>
              <TextField
                variant="outlined"
                fullWidth
                onChange={this.handleChange}
                id="startDate"
                label="Start Date"
                name="startDate"
                autoComplete="startDate"
                type="date"
                value={startDate}
                color="primary"
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                variant="outlined"
                fullWidth
                onChange={this.handleChange}
                id="endDate"
                label="End Date"
                name="endDate"
                autoComplete="endDate"
                type="date"
                value={endDate}
                color="primary"
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                color="primary"
                variant="contained"
                className={classes.addButton}
                onClick={() => this.getReportData()}
              >
                Show
              </Button>
            </Grid>
          </Grid>

          <br />

          {/* export the selected user data to csv, if the date and person is not selected, button will no enabled */}
          <Button
            style={{ marginTop: 20, marginBottom: 20, marginRight: 20 }}
            variant="contained"
            size="small"
            color="primary"
            disabled={!weeklyList.length}
          >
            <CSVLink
              data={weeklyList}
              style={{ textDecoration: "none", color: "white" }}
              headers={headers}
              filename={`weeklyReport.csv`}
            >
              Export Report
            </CSVLink>
          </Button>
          <Button
            style={{ marginTop: 20, marginBottom: 20 }}
            variant="contained"
            size="small"
            color="primary"
            disabled={!weeklyList.length}
          >
            <CSVLink
              data={weeklyListCrate}
              style={{ textDecoration: "none", color: "white" }}
              headers={headersCrate}
              filename={`weeklyReportCrate.csv`}
            >
              Export Crate ID
            </CSVLink>
          </Button>

          <br />

          <Paper className={classes.paperTable}>
            <Table stickyHeader className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell>Site</TableCell>
                  <TableCell>Sector ID</TableCell>
                  <TableCell>Postal Code</TableCell>
                  <TableCell>Block</TableCell>
                  <TableCell>Level</TableCell>
                  <TableCell>
                    Release
                    <br />
                    Point
                  </TableCell>
                  <TableCell>Point Type</TableCell>
                  <TableCell>Container ID</TableCell>
                  <TableCell>Year</TableCell>
                  <TableCell>E-week</TableCell>
                  <TableCell>
                    Date
                    <br />
                    (DD/MM/YYYY)
                  </TableCell>
                  <TableCell>
                    Time
                    <br />
                    (HH:MM:SS)
                  </TableCell>
                  <TableCell>
                    Name of
                    <br />
                    Officer
                  </TableCell>
                  <TableCell>Crate ID</TableCell>
                  <TableCell>Remarks</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!!weeklyList.length &&
                  weeklyList.map((item, i) => (
                    <Fragment key={`row-${i}`}>
                      <TableRow>
                        <TableCell>{item.type}</TableCell>
                        <TableCell>{item.site}</TableCell>
                        <TableCell>{item.sector}</TableCell>
                        <TableCell>{item.postal}</TableCell>
                        <TableCell>{item.block}</TableCell>
                        <TableCell>{item.level}</TableCell>
                        <TableCell>{item.point}</TableCell>
                        <TableCell>{item.pointType}</TableCell>
                        <TableCell>{item.container}</TableCell>
                        <TableCell>{item.year}</TableCell>
                        <TableCell>{item.eweek}</TableCell>
                        <TableCell>{item.date}</TableCell>
                        <TableCell>{item.time}</TableCell>
                        <TableCell>{item.officer}</TableCell>
                        <TableCell>{item.crateNo}</TableCell>
                        <TableCell>{item.remarks}</TableCell>
                      </TableRow>
                    </Fragment>
                  ))}
              </TableBody>
            </Table>
          </Paper>
          <br />
          <Divider variant="middle" style={{ marginTop: 30, width: "100%" }} />
          {/* export the selected user data to csv, if the date and person is not selected, button will no enabled */}
          <Button
            style={{ marginTop: 20, marginBottom: 20 }}
            variant="contained"
            size="small"
            color="primary"
            disabled={!this.state.monthlyBlocksRelease.length}
          >
            <CSVLink
              data={this.state.monthlyBlocksRelease}
              style={{ textDecoration: "none", color: "white" }}
              headers={headersMonthlyBlock}
              filename={`Monthly_Report_HDB.csv`}
            >
              Export
            </CSVLink>
          </Button>
          <br />
          <Paper className={classes.paperTable}>
            <Table stickyHeader className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Year</TableCell>
                  <TableCell>Week</TableCell>
                  <TableCell>Site</TableCell>
                  <TableCell>Sector ID</TableCell>
                  <TableCell>
                    Total no. of blocks
                    <br />
                    deployed (adults)
                  </TableCell>
                  <TableCell>
                    Total no. of blocks
                    <br />
                    deployed (pupae)
                  </TableCell>
                  <TableCell>
                    Total unique blocks
                    <br />
                    deployed (adults)
                  </TableCell>
                  <TableCell>
                    Total unique blocks
                    <br />
                    deployed (pupae)
                  </TableCell>
                  <TableCell>
                    Total no. of Release
                    <br />
                    containers (adults) deployed
                  </TableCell>
                  <TableCell>
                    Total no. of Release
                    <br />
                    containers (pupae) deployed
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!!this.state.monthlyBlocksRelease.length &&
                  this.state.monthlyBlocksRelease.map((item, i) => (
                    <Fragment key={`row-${i}`}>
                      <TableRow>
                        <TableCell>{item.year}</TableCell>
                        <TableCell>{item.eweek}</TableCell>
                        <TableCell>{item.site}</TableCell>
                        <TableCell>{item.sector}</TableCell>
                        <TableCell>{item.totalBlockAdult}</TableCell>
                        <TableCell>{item.totalBlockPupae}</TableCell>
                        <TableCell>{item.uniqueBlockAdult}</TableCell>
                        <TableCell>{item.uniqueBlockPupae}</TableCell>
                        <TableCell>{item.totalAdult}</TableCell>
                        <TableCell>{item.totalPupae}</TableCell>
                      </TableRow>
                    </Fragment>
                  ))}
              </TableBody>
            </Table>
          </Paper>
          <br />
          <Divider variant="middle" style={{ marginTop: 30, width: "100%" }} />
          {/* export the selected user data to csv, if the date and person is not selected, button will no enabled */}
          <Button
            style={{ marginTop: 20, marginBottom: 20 }}
            variant="contained"
            size="small"
            color="primary"
            disabled={!this.state.monthlyLandedDamaged.length}
          >
            <CSVLink
              data={this.state.monthlyLandedDamaged}
              style={{ textDecoration: "none", color: "white" }}
              headers={headersMonthlyLanded}
              filename={`Monthly_Report_Landed.csv`}
            >
              Export
            </CSVLink>
          </Button>
          <br />
          <Paper className={classes.paperTable}>
            <Table stickyHeader className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Year</TableCell>
                  <TableCell>Week</TableCell>
                  <TableCell>Site</TableCell>
                  <TableCell>
                    Total no. of landed sectors
                    <br />
                    deployed (adults)
                  </TableCell>
                  <TableCell>
                    Total no. of landed sectors
                    <br />
                    deployed (pupae)
                  </TableCell>
                  <TableCell>
                    Total unique landed sectors
                    <br />
                    deployed (adults)
                  </TableCell>
                  <TableCell>
                    Total unique landed sectors
                    <br />
                    deployed (pupae)
                  </TableCell>
                  <TableCell>
                    Total no. of Release
                    <br />
                    containers (adults) damaged
                  </TableCell>
                  <TableCell>
                    Total no. of Release
                    <br />
                    containers (pupae) damaged
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!!this.state.monthlyLandedDamaged.length &&
                  this.state.monthlyLandedDamaged.map((item, i) => (
                    <Fragment key={`row-${i}`}>
                      <TableRow>
                        <TableCell>{item.year}</TableCell>
                        <TableCell>{item.eweek}</TableCell>
                        <TableCell>{item.site}</TableCell>
                        <TableCell>{item.totalLandedAdult}</TableCell>
                        <TableCell>{item.totalLandedPupae}</TableCell>
                        <TableCell>{item.uniqueLandedAdult}</TableCell>
                        <TableCell>{item.uniqueLandedPupae}</TableCell>
                        <TableCell>{item.totalAdult}</TableCell>
                        <TableCell>{item.totalPupae}</TableCell>
                      </TableRow>
                    </Fragment>
                  ))}
              </TableBody>
            </Table>
          </Paper>
        </main>
      );
    }
  }
}

//styles
const styles = (theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  root: { width: "800px" },
  details: {
    display: "flex",
  },
  addButton: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  locationText: {
    paddingLeft: "15px",
  },
  uiProgess: {
    position: "fixed",
    zIndex: "1000",
    height: "31px",
    width: "31px",
    left: "50%",
    top: "35%",
  },
  paperTable: {
    width: 1700,
    overflowX: "auto",
    maxHeight: 300,
  },
});

export default withStyles(styles)(release);
