/*/////////////////////////////////////////////////////////////////
//    FileName: site.js
//    Author: Ho Da Zong
//    Created On: 7-May-2021
//    Last Modified On: 18-Oct-2021
//    Copy Rights: Orinno Technology Pte Ltd
//    Description: site DIV component, 
//                 will display at beside home page drawer if called.
//    Changes Log: 18-May-2021 > To check if auth token in local is not NULL only
//				   				 proceed to get data, else route to login.
//				   19-May-2021 > To change delete param pass in also Site. Also
// 								 changing delete pop out phrase.
*/ /////////////////////////////////////////////////////////////////

import React, { Component } from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import CardContent from "@material-ui/core/CardContent";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import axios from "axios";

import { authMiddleWare } from "../util/auth";
import { baseURL } from "../util/port.js";
//transition for when add or edit pop out box
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

//the class component
class site extends Component {
  constructor(props) {
    super(props);

    //state variable
    this.state = {
      sectorTypes: [],
      sites: [],
      site: "",
      sectorType: "",
      siteLat: "",
      siteLon: "",
      siteId: "",
      errors: [],
      open: false,
      uiLoading: true,
      buttonType: "",
      siteOld: "",
    };
  }

  //when value change handle change also for the state variable
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  //will run once everytime the page render
  componentDidMount = async () => {
    await this.getAlldata();
  };

  //get all data from db
  getAlldata = async () => {
    authMiddleWare(this.props.history);
    const authToken = localStorage.getItem("AuthToken");
    if (authToken == null) {
      alert("Your session is expired, please login to retry.");
      this.setState({ uiLoading: false });
      this.props.setLogin(false);
    } else {
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      //get all the sites from db
      await axios
        .get(`${baseURL}/getSite`)
        .then((response) => {
          this.setState({
            sites: response.data,
            uiLoading: false,
          });
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 403) {
            alert("Your session is expired, please login to retry.");
            this.setState({ uiLoading: false });
            this.props.setLogin(false);
          }
        });
    }
  };

  //edit site to trigger dialogbox open
  handleEditClickOpen(data) {
    this.setState({
      site: data.site.name,
      siteId: data.site.siteId,
      buttonType: "Edit",
      open: true,
      siteOld: data.site.name,
      errors: [],
    });
  }

  //delete dialogbox
  deleteTodoHandler(data) {
    confirmAlert({
      title: "Confirm to detele the record?",
      buttons: [
        {
          label: "Delete",
          onClick: async () => {
            this.setState({ uiLoading: true });
            authMiddleWare(this.props.history);
            const authToken = localStorage.getItem("AuthToken");
            if (authToken == null) {
              alert("Your session is expired, please login to retry.");
              this.setState({ uiLoading: false });
              this.props.setLogin(false);
            } else {
              axios.defaults.headers.common = { Authorization: `${authToken}` };
              let siteId = data.site.siteId;
              let site = data.site.site;
              //send request to delete site db
              await axios
                .delete(`${baseURL}/deleteSite/${siteId}/${site}`)
                .then(() => {
                  this.setState({ uiLoading: false });
                  this.getAlldata();
                })
                .catch((err) => {
                  if (err.response.status === 403) {
                    alert("Your session is expired, please login to retry.");
                    this.setState({ uiLoading: false });
                    this.props.setLogin(false);
                  }
                  console.log(err);
                });
            }
          },
        },
        {
          label: "Cancel",
          onClick: () => onclose,
        },
      ],
    });
  }

  render() {
    const { classes } = this.props;

    const { open, errors } = this.state;

    //add site to trigger the dialogbox open
    const handleClickOpen = () => {
      this.setState({
        siteId: "",
        site: "",
        buttonType: "",
        errors: [],
        open: true,
      });
    };

    //close the add site dialogbox
    const handleClose = (event) => {
      this.setState({
        open: false,
      });
    };

    //add the site into db or edit the site
    const handleSubmit = async (event) => {
      authMiddleWare(this.props.history);
      event.preventDefault();

      const siteAdd = {
        site: this.state.site,
      };
      let options = {};
      if (this.state.buttonType === "Edit") {
        options = {
          url: `${baseURL}/addSite/${this.state.siteId}/${this.state.siteOld}`,
          method: "put",
          data: siteAdd,
        };
      } else {
        options = {
          url: `${baseURL}/addSite`,
          method: "post",
          data: siteAdd,
        };
      }
      const authToken = localStorage.getItem("AuthToken");
      if (authToken == null) {
        alert("Your session is expired, please login to retry.");
        this.setState({ uiLoading: false });
        this.props.setLogin(false);
      } else {
        axios.defaults.headers.common = { Authorization: `${authToken}` };
        this.setState({
          uiLoading: true,
        });
        //send the request either add or edit
        await axios(options)
          .then(() => {
            this.setState({
              open: false,
              uiLoading: false,
            });
            this.getAlldata();
          })
          .catch((error) => {
            if (error.response.status === 403) {
              alert("Your session is expired, please login to retry.");
              this.setState({ uiLoading: false });
              this.props.setLogin(false);
            }
            this.setState({
              open: true,
              uiLoading: false,
              errors: error.response.data,
            });
            console.log(error);
          });
      }
    };

    //loading UI
    if (this.state.uiLoading === true) {
      return (
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {this.state.uiLoading && (
            <CircularProgress size={150} className={classes.uiProgess} />
          )}
        </main>
      );
    } else if (!this.state.sites.length) {
      //if got no sites data
      return (
        <main className={classes.content}>
          <div className={classes.toolbar}></div>

          <IconButton
            className={classes.floatingButton}
            color="primary"
            aria-label="Add Site"
            onClick={handleClickOpen}
          >
            <AddCircleIcon style={{ fontSize: 60 }} />
          </IconButton>

          <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
          >
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.site}>
                  {this.state.buttonType === "Edit"
                    ? "Edit Site"
                    : "Create a new Site"}
                </Typography>
                <Button
                  autoFocus
                  color="inherit"
                  onClick={handleSubmit}
                  className={classes.submitButton}
                >
                  {this.state.buttonType === "Edit" ? "Save" : "Submit"}
                </Button>
              </Toolbar>
            </AppBar>

            <form className={classes.form} onSubmit={handleSubmit} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="siteName"
                    label="Site Name"
                    name="site"
                    autoComplete="siteName"
                    helperText={errors.site}
                    value={this.state.site}
                    error={errors.site ? true : false}
                    onChange={this.handleChange}
                  />
                </Grid>
              </Grid>
            </form>
          </Dialog>

          <Typography variant="h3" component="h2">
            Dispatch Site
          </Typography>

          <div style={{ width: "100%", marginTop: "50%", marginLeft: "100%" }}>
            <Typography variant="h5" component="h6">
              Click “+” button at the bottom right to add a site
            </Typography>
          </div>
        </main>
      );
    } else {
      //if got sites data
      return (
        <main className={classes.content}>
          <div className={classes.toolbar} />

          <IconButton
            className={classes.floatingButton}
            color="primary"
            aria-label="Add Sector"
            onClick={handleClickOpen}
          >
            <AddCircleIcon style={{ fontSize: 60 }} />
          </IconButton>

          <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
          >
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.site}>
                  {this.state.buttonType === "Edit"
                    ? "Edit Site"
                    : "Create a new Site"}
                </Typography>
                <Button
                  autoFocus
                  color="inherit"
                  onClick={handleSubmit}
                  className={classes.submitButton}
                >
                  {this.state.buttonType === "Edit" ? "Save" : "Submit"}
                </Button>
              </Toolbar>
            </AppBar>

            <form className={classes.form} onSubmit={handleSubmit} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="siteName"
                    label="Site Name"
                    name="site"
                    autoComplete="siteName"
                    helperText={errors.site}
                    value={this.state.site}
                    error={errors.site ? true : false}
                    onChange={this.handleChange}
                  />
                </Grid>
              </Grid>
            </form>
          </Dialog>

          <Typography variant="h3" component="h2">
            Dispatch Site
          </Typography>
          <Grid container spacing={2}>
            {this.state.sites.map((site) => (
              <Grid item xs={12} sm={4}>
                <Card className={classes.root} variant="outlined">
                  <CardContent>
                    <Typography variant="h5" component="h2">
                      {site.name}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => this.handleEditClickOpen({ site })}
                    >
                      {" "}
                      Edit{" "}
                    </Button>
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => this.deleteTodoHandler({ site })}
                    >
                      Delete
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </main>
      );
    }
  }
}

//styles
const styles = (theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  site: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  submitButton: {
    display: "block",
    color: "white",
    textAlign: "center",
    position: "absolute",
    top: 14,
    right: 10,
  },
  floatingButton: {
    position: "fixed",
    bottom: 0,
    right: 0,
  },
  form: {
    width: "98%",
    marginLeft: 13,
    marginTop: theme.spacing(10),
  },
  root: {
    minWidth: 470,
  },
  pos: {
    marginBottom: 12,
  },
  uiProgess: {
    position: "fixed",
    zIndex: "1000",
    height: "31px",
    width: "31px",
    left: "50%",
    top: "35%",
  },
});

export default withStyles(styles)(site);
