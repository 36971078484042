/*/////////////////////////////////////////////////////////////////
//    FileName: releasePhotoPhoto.js
//    Author: Ramzi TM
//    Created On: 18-May-2022
//    Last Modified On: 18-May-2022
//    Copy Rights: Orinno Technology Pte Ltd
//    Description: photo details DIV component, 
//                 will display at beside home page drawer if called.
*/ /////////////////////////////////////////////////////////////////

import React, { Component, Fragment } from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
  Button,
} from "@material-ui/core";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import clsx from "clsx";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import { authMiddleWare } from "../util/auth";
import { baseURL } from "../util/port.js";
import Autocomplete from "@material-ui/lab/Autocomplete";

//main class component
class releasePhoto extends Component {
  constructor(props) {
    super(props);

    //state variable
    this.state = {
      uiLoading: true,
      loading: false,
      errors: [],
      invalidDatas: [],
      userList: [],
      userSelected: null,
      sectorList: [],
      sectorSelected: null,
      photosData: [],
      startDate: new Date().toISOString().split("T")[0],
      endDate: new Date().toISOString().split("T")[0],
    };
  }

  //will run once everytime the page render
  componentDidMount = async () => {
    const authToken = localStorage.getItem("AuthToken");

    //retrieve the user data from db
    authMiddleWare(this.props.history);
    if (authToken == null) {
      alert("Your session is expired, please login to retry.");
      this.setState({ uiLoading: false });
      this.props.setLogin(false);
    } else {
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      await axios
        .get(`${baseURL}/getAllUser`)
        .then((response) => {
          let fixData = [];
          response.data.forEach((data) => {
            fixData.push({
              id: data.userId,
              name: `${data.firstName} ${data.lastName}`,
            });
          });
          this.setState({
            userList: fixData,
          });
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 403) {
            alert("Your session is expired, please login to retry.");
            this.setState({ uiLoading: false });
            this.props.setLogin(false);
          }
        });
    }

    //request API to get all sector data
    authMiddleWare(this.props.history);
    if (authToken == null) {
      alert("Your session is expired, please login to retry.");
      this.setState({ uiLoading: false });
      this.props.setLogin(false);
    } else {
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      await axios
        .get(`${baseURL}/getSector`)
        .then((response) => {
          let fixData = [];
          response.data.forEach((data) => {
            fixData.push(`${data.site}`);
          });
          this.setState({
            sectorList: fixData,
            uiLoading: false,
          });
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 403) {
            alert("Your session is expired, please login to retry.");
            this.setState({ uiLoading: false });
            this.props.setLogin(false);
          }
        });
    }
  };

  getReportData = async () => {
    this.setState({ uiLoading: true });
    const authToken = localStorage.getItem("AuthToken");
    //request API to get all dispatch data
    authMiddleWare(this.props.history);
    if (authToken == null) {
      alert("Your session is expired, please login to retry.");
      this.setState({ uiLoading: false });
      this.props.setLogin(false);
    } else {
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      await axios
        .get(
          `${baseURL}/getReleasePhoto/${this.state.userSelected.id}/${this.state.sectorSelected}/${this.state.startDate}/${this.state.endDate}`
        )
        .then(async (response) => {
          await console.log(response.data);
          this.setState({
            uiLoading: false,
            photosData: response.data.sort(
              (a, b) => a.date.seconds - b.date.seconds
            ),
          });
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 403) {
            alert("Your session is expired, please login to retry.");
            this.setState({ uiLoading: false });
            this.props.setLogin(false);
          }
        });
    }
  };

  handleChange = (event) => {
    console.log(event.target.value);
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  //render function
  render() {
    const { classes } = this.props;
    const { photosData, startDate, endDate } = this.state;

    //loading UI
    if (this.state.uiLoading === true) {
      return (
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {this.state.uiLoading && (
            <CircularProgress size={150} className={classes.uiProgess} />
          )}
        </main>
      );
    } else {
      //if not loading, return actual UI and dialogbox
      return (
        <main className={classes.content}>
          <div className={classes.toolbar}></div>
          <Card className={clsx(classes.root, classes)}>
            <CardContent>
              <div className={classes.details}>
                <div>
                  <Typography
                    className={classes.locationText}
                    gutterBottom
                    variant="h4"
                  >
                    Release Point Photos
                  </Typography>
                </div>
              </div>
              <div className={classes.progress} />
            </CardContent>
            <Divider />
          </Card>

          <br />
          <Grid container spacing={4}>
            <Grid item xs={2}>
              <Autocomplete
                style={{ width: "100%" }}
                options={this.state.userList}
                getOptionLabel={(option) => option.name}
                id={"user"}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="User"
                    id={"user"}
                    required
                  />
                )}
                onChange={(event, value) => {
                  this.setState({ userSelected: value, errMsg: "" });
                }}
                value={this.state.userSelected}
              />
            </Grid>
            <Grid item xs={2}>
              <Autocomplete
                style={{ width: "100%" }}
                options={this.state.sectorList}
                id={"sector"}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Sector"
                    id={"sector"}
                    required
                  />
                )}
                onChange={(event, value) => {
                  this.setState({ sectorSelected: value, errMsg: "" });
                }}
                value={this.state.sectorSelected}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                variant="outlined"
                fullWidth
                onChange={this.handleChange}
                id="startDate"
                label="Start Date"
                name="startDate"
                autoComplete="startDate"
                type="date"
                value={startDate}
                color="primary"
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                variant="outlined"
                fullWidth
                onChange={this.handleChange}
                id="endDate"
                label="End Date"
                name="endDate"
                autoComplete="endDate"
                type="date"
                value={endDate}
                color="primary"
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                color="primary"
                variant="contained"
                className={classes.addButton}
                onClick={() => this.getReportData()}
              >
                Show
              </Button>
            </Grid>
          </Grid>

          <br />

          <Paper className={classes.paperTable}>
            <Table stickyHeader className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Site</TableCell>
                  <TableCell>Block</TableCell>
                  <TableCell>Release Point</TableCell>
                  <TableCell>
                    Date
                    <br />
                    (DD/MM/YYYY)
                  </TableCell>
                  <TableCell>
                    Time
                    <br />
                    (HH:MM:SS)
                  </TableCell>
                  <TableCell>Image</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!!photosData.length &&
                  photosData.map((item, i) => (
                    <Fragment key={`row-${i}`}>
                      <TableRow>
                        <TableCell>{item.site}</TableCell>
                        <TableCell>{item.block}</TableCell>
                        <TableCell>{item.nfc}</TableCell>
                        <TableCell>
                          {
                            new Date(item.date.seconds * 1000)
                              .toLocaleString("en-GB")
                              .split(", ")[0]
                          }
                        </TableCell>
                        <TableCell>
                          {
                            new Date(item.date.seconds * 1000)
                              .toLocaleString("en-GB")
                              .split(", ")[1]
                          }
                        </TableCell>
                        <TableCell>
                          <img src={item.imgUrl} alt="gambar" />
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  ))}
              </TableBody>
            </Table>
          </Paper>
        </main>
      );
    }
  }
}

//styles
const styles = (theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  root: { width: "800px" },
  details: {
    display: "flex",
  },
  addButton: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  locationText: {
    paddingLeft: "15px",
  },
  uiProgess: {
    position: "fixed",
    zIndex: "1000",
    height: "31px",
    width: "31px",
    left: "50%",
    top: "35%",
  },
  paperTable: {
    width: 1700,
    overflowX: "auto",
  },
});

export default withStyles(styles)(releasePhoto);
