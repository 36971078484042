/*/////////////////////////////////////////////////////////////////
//    FileName: setting.js
//    Author: Ramzi TM
//    Created On: 6-Apr-2022
//    Last Modified On: 6-Apr-2022
//    Copy Rights: Orinno Technology Pte Ltd
//    Description: sectorTime details DIV component, 
//                 will display at beside home page drawer if called.
*/ /////////////////////////////////////////////////////////////////

import React, { Component, Fragment } from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Card, CardContent, Divider, Button, Grid } from "@material-ui/core";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import clsx from "clsx";
import axios from "axios";
import { authMiddleWare } from "../util/auth";
import { baseURL } from "../util/port.js";
import { confirmAlert } from "react-confirm-alert"; // Import
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import * as XLSX from "xlsx";

//main class component
class sectorTime extends Component {
  constructor(props) {
    super(props);

    //state variable
    this.state = {
      uiLoading: false,
      loading: false,
      errors: [],
      errMsg: "",
      sheet00: "",
      sheet01: "",
      sheet1: "",
      sheet2: "",
      sheet3: "",
      sheet4: "",
      sheet5: "",
      col00: [],
      col01: [],
      col1: [],
      col2: [],
      col3: [],
      col4: [],
      col5: [],
      row00: [],
      row01: [],
      row1: [],
      row2: [],
      row3: [],
      row4: [],
      row5: [],
    };
  }

  //will run once everytime the page render
  componentDidMount = async () => {
    // const authToken = localStorage.getItem('AuthToken');
    // //retrieve the issue data from db
    // authMiddleWare(this.props.history);
    // if (authToken == null) {
    // 	alert('Your session is expired, please login to retry.');
    // 	this.setState({uiLoading: false});
    // 	this.props.setLogin(false);
    // } else {
    // 	axios.defaults.headers.common = { Authorization: `${authToken}` };
    // 	await axios
    // 		.get(`${baseURL}/getIssue`)
    // 		.then((response) => {
    // 			this.setState({
    // 				issues: response.data,
    // 				uiLoading: false
    // 			});
    // 		})
    // 		.catch((err) => {
    // 			console.log(err);
    // 			if (err.response.status === 403) {
    // 				alert('Your session is expired, please login to retry.');
    // 				this.setState({uiLoading: false});
    // 				this.props.setLogin(false);
    // 			}
    // 		});
    // }
  };

  //render function
  render() {
    let uploadbtn = null;

    const leadingZero = (num, totalLength) => {
      return String(num).padStart(totalLength, "0");
    };

    //postal refresh
    const handlePostalFetch = async (postal) => {
      if (postal === "") {
        return "ERROR POSTAL IS EMPTY";
      } else {
        let res = "";

        axios.defaults.headers.common = {};
        await axios
          .get(
            `https://developers.onemap.sg/commonapi/search?searchVal=${postal}&returnGeom=Y&getAddrDetails=Y&pageNum=1`
          )
          .then((response) => {
            if (response.data.found === 0) {
              res = "ERROR POSTAL NOT FOUND";
            } else {
              res = response.data.results[0].ROAD_NAME;
            }
          })
          .catch((error) => {
            console.log(error);
            res = "Error in retrieving the data";
          });

        return res;
      }
    };

    //get ID user
    const handleUserID = async (data) => {
      let res = { id: "", name: "" };
      const authToken = localStorage.getItem("AuthToken");
      //retrieve the issue data from db
      authMiddleWare(this.props.history);
      if (authToken == null) {
        alert("Your session is expired, please login to retry.");
        this.setState({ uiLoading: false });
        this.props.setLogin(false);
      } else {
        const options = {
          url: `${baseURL}/getUserId`,
          method: "post",
          data: {
            email: data,
          },
        };
        axios.defaults.headers.common = { Authorization: `${authToken}` };
        await axios(options)
          .then((response) => {
            console.log(response.data);
            this.setState({
              uiLoading: false,
            });
            res.id = response.data.id;
            res.name = response.data.name;
          })
          .catch((err) => {
            console.log(err);
            if (err.response.status === 403) {
              alert("Your session is expired, please login to retry.");
              this.setState({ uiLoading: false });
              this.props.setLogin(false);
            }
          });

        return res;
      }
    };

    //trigger reset
    const handleReset = () => {
      uploadbtn.value = "";
      this.setState({
        sheet00: "",
        col00: [],
        row00: [],
        sheet01: "",
        col01: [],
        row01: [],
        sheet1: "",
        col1: [],
        row1: [],
        sheet2: "",
        col2: [],
        row2: [],
        sheet3: "",
        col3: [],
        row3: [],
        sheet4: "",
        col4: [],
        row4: [],
        sheet5: "",
        col5: [],
        row5: [],
      });
    };

    //trigger event upload launchPoint
    const handleUploadLaunchPoint = () => {
      confirmAlert({
        title: "Confirm to push the data to launchPoint?",
        message: "Make sure to double check the data, or duplication.",
        buttons: [
          {
            label: "Submit",
            onClick: async () => {
              this.setState({ uiLoading: true });
              const authToken = localStorage.getItem("AuthToken");
              const options = {
                url: `${baseURL}/writeBulkLaunchPoint`,
                method: "post",
                data: {
                  data: this.state.row00,
                },
              };
              axios.defaults.headers.common = { Authorization: `${authToken}` };
              await axios(options)
                .then((res) => {
                  console.log(res);
                  this.setState({ uiLoading: false });
                })
                .catch((error) => {
                  if (error.response.status === 403) {
                    alert("Your session is expired, please login to retry.");
                    this.setState({ uiLoading: false });
                    this.props.setLogin(false);
                  }
                  this.setState({
                    uiLoading: false,
                    errors: error.response.data,
                  });
                  console.log(error);
                });
            },
          },
          {
            label: "Cancel",
            onClick: () => onclose,
          },
        ],
      });
    };

    //trigger event upload releaseInformation
    const handleUploadReleaseInformation = () => {
      confirmAlert({
        title: "Confirm to push the data to releaseInformation?",
        message: "Make sure to double check the data, or duplication.",
        buttons: [
          {
            label: "Submit",
            onClick: async () => {
              this.setState({ uiLoading: true });
              const authToken = localStorage.getItem("AuthToken");
              const options = {
                url: `${baseURL}/writeBulkReleaseInformation`,
                method: "post",
                data: {
                  data: this.state.row01,
                },
              };
              axios.defaults.headers.common = { Authorization: `${authToken}` };
              await axios(options)
                .then((res) => {
                  console.log(res);
                  this.setState({ uiLoading: false });
                })
                .catch((error) => {
                  if (error.response.status === 403) {
                    alert("Your session is expired, please login to retry.");
                    this.setState({ uiLoading: false });
                    this.props.setLogin(false);
                  }
                  this.setState({
                    uiLoading: false,
                    errors: error.response.data,
                  });
                  console.log(error);
                });
            },
          },
          {
            label: "Cancel",
            onClick: () => onclose,
          },
        ],
      });
    };

    //trigger event upload Region
    const handleUploadRegion = () => {
      confirmAlert({
        title: "Confirm to push the data to Region?",
        message: "Make sure to double check the data, or duplication.",
        buttons: [
          {
            label: "Submit",
            onClick: async () => {
              this.setState({ uiLoading: true });
              const authToken = localStorage.getItem("AuthToken");
              const options = {
                url: `${baseURL}/writeBulkRegion`,
                method: "post",
                data: {
                  data: this.state.row1,
                },
              };
              axios.defaults.headers.common = { Authorization: `${authToken}` };
              await axios(options)
                .then((res) => {
                  console.log(res);
                  this.setState({ uiLoading: false });
                })
                .catch((error) => {
                  if (error.response.status === 403) {
                    alert("Your session is expired, please login to retry.");
                    this.setState({ uiLoading: false });
                    this.props.setLogin(false);
                  }
                  this.setState({
                    uiLoading: false,
                    errors: error.response.data,
                  });
                  console.log(error);
                });
            },
          },
          {
            label: "Cancel",
            onClick: () => onclose,
          },
        ],
      });
    };

    //trigger event upload launchBlock
    const handleUploadLaunchBlock = () => {
      confirmAlert({
        title: "Confirm to push the data to launchBlock?",
        message: "Make sure to double check the data, or duplication.",
        buttons: [
          {
            label: "Submit",
            onClick: async () => {
              this.setState({ uiLoading: true });
              const authToken = localStorage.getItem("AuthToken");
              const options = {
                url: `${baseURL}/writeBulkLaunchBlock`,
                method: "post",
                data: {
                  data: this.state.row2,
                },
              };
              axios.defaults.headers.common = { Authorization: `${authToken}` };
              await axios(options)
                .then((res) => {
                  console.log(res);
                  this.setState({ uiLoading: false });
                })
                .catch((error) => {
                  if (error.response.status === 403) {
                    alert("Your session is expired, please login to retry.");
                    this.setState({ uiLoading: false });
                    this.props.setLogin(false);
                  }
                  this.setState({
                    uiLoading: false,
                    errors: error.response.data,
                  });
                  console.log(error);
                });
            },
          },
          {
            label: "Cancel",
            onClick: () => onclose,
          },
        ],
      });
    };

    //trigger event upload containerInformation
    const handleUploadContainer = () => {
      confirmAlert({
        title: "Confirm to push the data to containerInformation?",
        message: "Make sure to double check the data, or duplication.",
        buttons: [
          {
            label: "Submit",
            onClick: async () => {
              this.setState({ uiLoading: true });
              const authToken = localStorage.getItem("AuthToken");
              const options = {
                url: `${baseURL}/writeBulkContainer`,
                method: "post",
                data: {
                  data: this.state.row3,
                },
              };
              axios.defaults.headers.common = { Authorization: `${authToken}` };
              await axios(options)
                .then((res) => {
                  console.log(res);
                  this.setState({ uiLoading: false });
                })
                .catch((error) => {
                  if (error.response.status === 403) {
                    alert("Your session is expired, please login to retry.");
                    this.setState({ uiLoading: false });
                    this.props.setLogin(false);
                  }
                  this.setState({
                    uiLoading: false,
                    errors: error.response.data,
                  });
                  console.log(error);
                });
            },
          },
          {
            label: "Cancel",
            onClick: () => onclose,
          },
        ],
      });
    };

    //trigger event upload users
    const handleUploadUsers = () => {
      confirmAlert({
        title: "Confirm to push the data to users?",
        message: "Make sure to double check the data, or duplication.",
        buttons: [
          {
            label: "Submit",
            onClick: async () => {
              this.setState({ uiLoading: true });
              const authToken = localStorage.getItem("AuthToken");
              const options = {
                url: `${baseURL}/writeBulkUsers`,
                method: "post",
                data: {
                  data: this.state.row4,
                },
              };
              axios.defaults.headers.common = { Authorization: `${authToken}` };
              await axios(options)
                .then((res) => {
                  console.log(res);
                  this.setState({ uiLoading: false });
                })
                .catch((error) => {
                  if (error.response.status === 403) {
                    alert("Your session is expired, please login to retry.");
                    this.setState({ uiLoading: false });
                    this.props.setLogin(false);
                  }
                  this.setState({
                    uiLoading: false,
                    errors: error.response.data,
                  });
                  console.log(error);
                });
            },
          },
          {
            label: "Cancel",
            onClick: () => onclose,
          },
        ],
      });
    };

    //trigger event upload usersRegion
    const handleUploadUsersRegion = () => {
      confirmAlert({
        title: "Confirm to push the data to usersRegion?",
        message: "Make sure to double check the data, or duplication.",
        buttons: [
          {
            label: "Submit",
            onClick: async () => {
              this.setState({ uiLoading: true });
              const authToken = localStorage.getItem("AuthToken");
              const options = {
                url: `${baseURL}/writeBulkUsersRegion`,
                method: "post",
                data: {
                  data: this.state.row5,
                },
              };
              axios.defaults.headers.common = { Authorization: `${authToken}` };
              await axios(options)
                .then((res) => {
                  console.log(res);
                  this.setState({ uiLoading: false });
                })
                .catch((error) => {
                  if (error.response.status === 403) {
                    alert("Your session is expired, please login to retry.");
                    this.setState({ uiLoading: false });
                    this.props.setLogin(false);
                  }
                  this.setState({
                    uiLoading: false,
                    errors: error.response.data,
                  });
                  console.log(error);
                });
            },
          },
          {
            label: "Cancel",
            onClick: () => onclose,
          },
        ],
      });
    };

    const onChange = (e) => {
      this.setState({ uiLoading: true });
      const [file] = e.target.files;
      if (file) {
        const reader = new FileReader();

        reader.onload = async (evt) => {
          const bstr = evt.target.result;
          const wb = XLSX.read(bstr, { type: "binary" });
          const wsname0 = wb.SheetNames[0];
          const wsname1 = wb.SheetNames[1];
          const wsname2 = wb.SheetNames[2];
          const wsname3 = wb.SheetNames[3];
          const wsname4 = wb.SheetNames[4];
          const wsname5 = wb.SheetNames[5];
          const ws0 = wb.Sheets[wsname0];
          const ws1 = wb.Sheets[wsname1];
          const ws2 = wb.Sheets[wsname2];
          const ws3 = wb.Sheets[wsname3];
          const ws4 = wb.Sheets[wsname4];
          const ws5 = wb.Sheets[wsname5];
          const data0 = XLSX.utils.sheet_to_json(ws0, {
            header: 1,
            blankrows: false,
          });
          const data1 = XLSX.utils.sheet_to_json(ws1, {
            header: 1,
            blankrows: false,
          });
          const data2 = XLSX.utils.sheet_to_json(ws2, {
            header: 1,
            blankrows: false,
          });
          const data3 = XLSX.utils.sheet_to_json(ws3, {
            header: 1,
            blankrows: false,
          });
          const data4 = XLSX.utils.sheet_to_json(ws4, {
            header: 1,
            blankrows: false,
          });
          const data5 = XLSX.utils.sheet_to_json(ws5, {
            header: 1,
            blankrows: false,
          });

          const row0 = data0.slice(1).map((r) =>
            r.reduce((acc, x, i) => {
              acc[data0[0][i]] = x;
              return acc;
            }, {})
          );

          const row2 = data2.slice(1).map((r) =>
            r.reduce((acc, x, i) => {
              acc[data2[0][i]] = x;
              return acc;
            }, {})
          );

          const row2Fix = await Promise.all(
            row2.map(async (v) => {
              const area = await handlePostalFetch(v.postal);
              return {
                block: v.block,
                latitude: v.latitude,
                longitude: v.longitude,
                groundRouteType: leadingZero(v.groundRouteType, 2),
                isAvailable: v.isAvailable,
                loadingPoint: v.loadingPoint,
                postal: v.postal,
                regionArea: area,
                remarks: "",
                site: v.sector,
                status: "pending",
                upstairRouteType: "N.A",
              };
            })
          );

          const row5 = data5.slice(1).map((r) =>
            r.reduce((acc, x, i) => {
              acc[data5[0][i]] = x;
              return acc;
            }, {})
          );
          const row5fix = await Promise.all(
            row5.map(async (v) => {
              const userId = await handleUserID(v.email);
              return { ...{ id: userId.id }, ...{ name: userId.name }, ...v };
            })
          );
          const col5fix = data5[0].map((r) => r);
          col5fix.splice(4, 0, "userId");
          col5fix.splice(5, 0, "userName");

          this.setState(
            {
              sheet00: "launchPoint",
              col00: [
                "block",
                "coordinate",
                "dateTime",
                "isAvailable",
                "level",
                "pointType",
                "releasePointOne",
                "releasePointTwo",
                "site",
                "status",
                "type",
              ],
              row00: row0.map((v) => ({
                block: v.block,
                coordinate: null,
                dateTime: null,
                isAvailable: v.isAvailable,
                level: v.level,
                pointType: v.pointType,
                releasePointOne: v.releasePointOne,
                releasePointTwo: v.releasePointTwo,
                site: v.sector,
                status: "pending",
                type: "Ground",
              })),
              sheet01: "releaseInformation",
              col01: [
                "NFC",
                "blockName",
                "latitude",
                "longitude",
                "postal",
                "releasePointID",
                "site",
              ],
              row01: row0.map((v) => ({
                NFC: v.level + "-" + v.releasePointOne,
                blockName: v.block,
                latitude: v.latitude,
                longitude: v.longitude,
                postal: v.postal,
                releasePointID:
                  leadingZero(v.level, 2) +
                  "-" +
                  leadingZero(v.releasePointOne, 2),
                site: v.sector,
              })),
              sheet1: "Region",
              col1: data1[0].map((r) => r),
              row1: data1.slice(1).map((r) =>
                r.reduce((acc, x, i) => {
                  acc[data1[0][i]] = x;
                  return acc;
                }, {})
              ),
              sheet2: wsname2,
              col2: [
                "block",
                "latitude",
                "longitude",
                "groundRouteType",
                "isAvailable",
                "loadingPoint",
                "postal",
                "regionArea",
                "remarks",
                "site",
                "status",
                "upstairRouteType",
              ],
              row2: row2Fix,
              sheet3: wsname3,
              col3: data3[0].map((r) => r),
              row3: data3.slice(1).map((r) =>
                r.reduce((acc, x, i) => {
                  acc[data3[0][i]] = x;
                  return acc;
                }, {})
              ),
              sheet4: wsname4,
              col4: data4[0].map((r) => r),
              row4: data4.slice(1).map((r) =>
                r.reduce((acc, x, i) => {
                  acc[data4[0][i]] = x;
                  return acc;
                }, {})
              ),
              sheet5: wsname5,
              col5: col5fix,
              row5: row5fix,
            },
            () => {
              console.log(row5fix);
              this.setState({ uiLoading: false });
            }
          );
        };
        reader.readAsBinaryString(file);
      }
    };

    const { classes } = this.props;
    const {
      sheet00,
      sheet01,
      sheet1,
      sheet2,
      sheet3,
      sheet4,
      sheet5,
      col00,
      col01,
      row00,
      row01,
      col1,
      row1,
      col2,
      row2,
      col3,
      row3,
      col4,
      row4,
      col5,
      row5,
    } = this.state;

    //loading UI
    if (this.state.uiLoading === true) {
      return (
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {this.state.uiLoading && (
            <CircularProgress size={150} className={classes.uiProgess} />
          )}
        </main>
      );
    } else {
      //if not loading, return actual UI and dialogbox
      return (
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Card className={clsx(classes.root, classes)}>
            <CardContent>
              <div className={classes.details}>
                <div>
                  <Typography
                    className={classes.locationText}
                    gutterBottom
                    variant="h4"
                  >
                    Bulk Write
                  </Typography>
                </div>
              </div>
              <div className={classes.progress} />
            </CardContent>
            <Divider />
          </Card>

          <br />
          <input
            onChange={onChange}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            className={classes.input}
            id="contained-button-file"
            type="file"
            ref={(ref) => (uploadbtn = ref)}
          />
          <label htmlFor="contained-button-file">
            <Button
              disabled={sheet00 !== ""}
              variant="contained"
              color="primary"
              component="span"
            >
              Upload File
            </Button>
            {sheet00 !== "" && (
              <Button
                color="primary"
                variant="contained"
                component="span"
                onClick={() => handleReset()}
                style={{ marginLeft: "15px" }}
              >
                Reset All
              </Button>
            )}
          </label>

          {!!row00.length && (
            <Grid container spacing={4} justifycontent="space-between">
              <Grid item xs={6}>
                <Typography variant="h5" style={{ marginTop: "20px" }}>
                  {sheet00}
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right" }}>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.addButton}
                  onClick={() => handleUploadLaunchPoint()}
                >
                  Save launchPoint
                </Button>
              </Grid>
            </Grid>
          )}

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className={classes.paperTable}>
                <Table stickyHeader className={classes.table}>
                  <TableHead>
                    <TableRow>
                      {!!col00.length &&
                        col00.map((item, i) => (
                          <Fragment key={`col-${i}`}>
                            <TableCell>{item}</TableCell>
                          </Fragment>
                        ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!row00.length &&
                      row00.map((item, i) => (
                        <Fragment key={`row-${i}`}>
                          <TableRow>
                            <TableCell>{item.block}</TableCell>
                            <TableCell>{item.coordinate}</TableCell>
                            <TableCell>{item.dateTime}</TableCell>
                            <TableCell>
                              {item.isAvailable ? "true" : "false"}
                            </TableCell>
                            <TableCell>{item.level}</TableCell>
                            <TableCell>{item.pointType}</TableCell>
                            <TableCell>{item.releasePointOne}</TableCell>
                            <TableCell>{item.releasePointTwo}</TableCell>
                            <TableCell>{item.site}</TableCell>
                            <TableCell>{item.status}</TableCell>
                            <TableCell>{item.type}</TableCell>
                          </TableRow>
                        </Fragment>
                      ))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          </Grid>

          {!!row01.length && (
            <Grid container spacing={4} justifycontent="space-between">
              <Grid item xs={6}>
                <Typography variant="h5" style={{ marginTop: "20px" }}>
                  {sheet01}
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right" }}>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.addButton}
                  onClick={() => handleUploadReleaseInformation()}
                >
                  Save releasePoint
                </Button>
              </Grid>
            </Grid>
          )}

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className={classes.paperTable}>
                <Table stickyHeader className={classes.table}>
                  <TableHead>
                    <TableRow>
                      {!!col01.length &&
                        col01.map((item, i) => (
                          <Fragment key={`col-${i}`}>
                            <TableCell>{item}</TableCell>
                          </Fragment>
                        ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!row01.length &&
                      row01.map((item, i) => (
                        <Fragment key={`row-${i}`}>
                          <TableRow>
                            <TableCell>{item.NFC}</TableCell>
                            <TableCell>{item.blockName}</TableCell>
                            <TableCell>{item.latitude}</TableCell>
                            <TableCell>{item.longitude}</TableCell>
                            <TableCell>{item.postal}</TableCell>
                            <TableCell>{item.releasePointID}</TableCell>
                            <TableCell>{item.site}</TableCell>
                          </TableRow>
                        </Fragment>
                      ))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          </Grid>

          {!!row1.length && (
            <Grid container spacing={4} justifycontent="space-between">
              <Grid item xs={6}>
                <Typography variant="h5" style={{ marginTop: "20px" }}>
                  {sheet1}
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right" }}>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.addButton}
                  onClick={() => handleUploadRegion()}
                >
                  Save Region
                </Button>
              </Grid>
            </Grid>
          )}

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className={classes.paperTable}>
                <Table stickyHeader className={classes.table}>
                  <TableHead>
                    <TableRow>
                      {!!col1.length &&
                        col1.map((item, i) => (
                          <Fragment key={`col-${i}`}>
                            <TableCell>{item}</TableCell>
                          </Fragment>
                        ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!row1.length &&
                      row1.map((item, i) => (
                        <Fragment key={`row-${i}`}>
                          <TableRow>
                            <TableCell>{item.site}</TableCell>
                            <TableCell>{item.parentSite}</TableCell>
                            <TableCell>{item.sectorType}</TableCell>
                            <TableCell>{item.latitude}</TableCell>
                            <TableCell>{item.longitude}</TableCell>
                          </TableRow>
                        </Fragment>
                      ))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          </Grid>

          {!!row2.length && (
            <Grid container spacing={4} justifycontent="space-between">
              <Grid item xs={6}>
                <Typography variant="h5" style={{ marginTop: "20px" }}>
                  {sheet2}
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right" }}>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.addButton}
                  onClick={() => handleUploadLaunchBlock()}
                >
                  Save launchBlock
                </Button>
              </Grid>
            </Grid>
          )}

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className={classes.paperTable}>
                <Table stickyHeader className={classes.table}>
                  <TableHead>
                    <TableRow>
                      {!!col2.length &&
                        col2.map((item, i) => (
                          <Fragment key={`col-${i}`}>
                            <TableCell>{item}</TableCell>
                          </Fragment>
                        ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!row2.length &&
                      row2.map((item, i) => (
                        <Fragment key={`row-${i}`}>
                          <TableRow>
                            <TableCell>{item.block}</TableCell>
                            <TableCell>{item.latitude}</TableCell>
                            <TableCell>{item.longitude}</TableCell>
                            <TableCell>{item.groundRouteType}</TableCell>
                            <TableCell>
                              {item.isAvailable ? "true" : "false"}
                            </TableCell>
                            <TableCell>
                              {item.loadingPoint ? "true" : "false"}
                            </TableCell>
                            <TableCell>{item.postal}</TableCell>
                            <TableCell>{item.regionArea}</TableCell>
                            <TableCell>{item.remarks}</TableCell>
                            <TableCell>{item.site}</TableCell>
                            <TableCell>{item.status}</TableCell>
                            <TableCell>{item.upstairRouteType}</TableCell>
                          </TableRow>
                        </Fragment>
                      ))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          </Grid>

          {!!row3.length && (
            <Grid container spacing={4} justifycontent="space-between">
              <Grid item xs={6}>
                <Typography variant="h5" style={{ marginTop: "20px" }}>
                  {sheet3}
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right" }}>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.addButton}
                  onClick={() => handleUploadContainer()}
                >
                  Save containerInformation
                </Button>
              </Grid>
            </Grid>
          )}

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className={classes.paperTable}>
                <Table stickyHeader className={classes.table}>
                  <TableHead>
                    <TableRow>
                      {!!col3.length &&
                        col3.map((item, i) => (
                          <Fragment key={`col-${i}`}>
                            <TableCell>{item}</TableCell>
                          </Fragment>
                        ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!row3.length &&
                      row3.map((item, i) => (
                        <Fragment key={`row-${i}`}>
                          <TableRow>
                            <TableCell>{item.containerQR}</TableCell>
                          </TableRow>
                        </Fragment>
                      ))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          </Grid>

          {!!row4.length && (
            <Grid container spacing={4} justifycontent="space-between">
              <Grid item xs={6}>
                <Typography variant="h5" style={{ marginTop: "20px" }}>
                  {sheet4}
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right" }}>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.addButton}
                  onClick={() => handleUploadUsers()}
                >
                  Save users
                </Button>
              </Grid>
            </Grid>
          )}

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className={classes.paperTable}>
                <Table stickyHeader className={classes.table}>
                  <TableHead>
                    <TableRow>
                      {!!col4.length &&
                        col4.map((item, i) => (
                          <Fragment key={`col-${i}`}>
                            <TableCell>{item}</TableCell>
                          </Fragment>
                        ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!row4.length &&
                      row4.map((item, i) => (
                        <Fragment key={`row-${i}`}>
                          <TableRow>
                            <TableCell>{item.email}</TableCell>
                            <TableCell>{item.firstName}</TableCell>
                            <TableCell>{item.lastName}</TableCell>
                            <TableCell>{item.role}</TableCell>
                            <TableCell>{item.phone}</TableCell>
                          </TableRow>
                        </Fragment>
                      ))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          </Grid>

          {!!row5.length && (
            <Grid container spacing={4} justifycontent="space-between">
              <Grid item xs={6}>
                <Typography variant="h5" style={{ marginTop: "20px" }}>
                  {sheet5}
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right" }}>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.addButton}
                  onClick={() => handleUploadUsersRegion()}
                >
                  Save usersRegion
                </Button>
              </Grid>
            </Grid>
          )}

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className={classes.paperTable}>
                <Table stickyHeader className={classes.table}>
                  <TableHead>
                    <TableRow>
                      {!!col5.length &&
                        col5.map((item, i) => (
                          <Fragment key={`col-${i}`}>
                            <TableCell>{item}</TableCell>
                          </Fragment>
                        ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!row5.length &&
                      row5.map((item, i) => (
                        <Fragment key={`row-${i}`}>
                          <TableRow>
                            <TableCell>{item.assignBy}</TableCell>
                            <TableCell>{item.sector}</TableCell>
                            <TableCell>{item.site}</TableCell>
                            <TableCell>{item.email}</TableCell>
                            <TableCell>{item.id}</TableCell>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>{item.date}</TableCell>
                            <TableCell>{item.time}</TableCell>
                          </TableRow>
                        </Fragment>
                      ))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          </Grid>
        </main>
      );
    }
  }
}

//styles
const styles = (theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: "1400px",
  },
  toolbar: theme.mixins.toolbar,
  root: { width: "800px" },
  details: {
    display: "flex",
  },
  locationText: {
    paddingLeft: "15px",
  },
  errorText: {
    paddingLeft: "15px",
    color: "red",
  },
  input: {
    display: "none",
  },
  uiProgess: {
    position: "fixed",
    zIndex: "1000",
    height: "31px",
    width: "31px",
    left: "50%",
    top: "35%",
  },
  progess: {
    position: "absolute",
  },
  paperTable: {
    width: "100%",
    overflowX: "auto",
    maxHeight: 500,
  },
  assignText: {
    textAlign: "left",
  },
  assignButton: {
    position: "absolute",
    right: "0",
  },
  addButton: {
    marginTop: "10px",
    marginBottom: "10px",
    textTransform: "none",
  },
  deleteButton: {
    marginTop: "10px",
    marginLeft: "10px",
  },
  dialogeStyle: {
    maxWidth: "50%",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

export default withStyles(styles)(sectorTime);
