/*/////////////////////////////////////////////////////////////////
//    FileName: account.js
//    Author: Ho Da Zong
//    Created On: 7-May-2021
//    Last Modified On: 18-Oct-2021
//    Copy Rights: Orinno Technology Pte Ltd
//    Description: account details DIV component, 
//                 will display at beside home page drawer if called.
*/ /////////////////////////////////////////////////////////////////

import React, { Component } from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Card,
  CardContent,
  Divider,
  Button,
  Grid,
  TextField,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import clsx from "clsx";
import axios from "axios";
import { authMiddleWare } from "../util/auth";
import { baseURL } from "../util/port.js";

//main class component
class account extends Component {
  constructor(props) {
    super(props);

    //state variable
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      uiLoading: true,
      users: [],
      isChecked: [],
      viewOpen: false,
      loading: false,
      errors: [],
      confirmPassword: "",
      personArray: [],
    };
  }

  //will run once everytime the page render
  componentDidMount = async () => {
    const authToken = localStorage.getItem("AuthToken");
    //request API to get all users data
    authMiddleWare(this.props.history);
    if (authToken == null) {
      alert("Your session is expired, please login to retry.");
      this.setState({ uiLoading: false });
      this.props.setLogin(false);
    } else {
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      await axios
        .get(`${baseURL}/getAllUser`)
        .then((response) => {
          this.setState({
            users: response.data,
            isChecked: response.data.slice().fill(false),
            uiLoading: false,
          });
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 403) {
            alert("Your session is expired, please login to retry.");
            this.setState({ uiLoading: false });
            this.props.setLogin(false);
          }
        });
    }

    //get all the sites from db
    authMiddleWare(this.props.history);
    if (authToken == null) {
      alert("Your session is expired, please login to retry.");
      this.setState({ uiLoading: false });
      this.props.setLogin(false);
    } else {
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      await axios
        .get(`${baseURL}/getSector`)
        .then((response) => {
          this.setState({
            sites: response.data,
            uiLoading: false,
          });
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 403) {
            alert("Your session is expired, please login to retry.");
            this.setState({ uiLoading: false });
            this.props.setLogin(false);
          }
        });
    }
  };

  //change value and assign to state change
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  //open add account dialog box
  handleViewOpen = (data) => {
    this.setState({
      viewOpen: true,
    });
  };

  deleteAccount = (data) => {
    var countSelected = 0;
    for (var i = 0; i < this.state.isChecked.length; i++) {
      if (this.state.isChecked[i] === true) {
        //get the selected person user ID
        this.state.personArray.push(this.state.users[i].userId);
        countSelected = countSelected + 1;
      }
    }

    if (countSelected === 0) {
      alert("Please select at least one record.");
    } else {
      confirmAlert({
        title: "Confirm Delete?",
        message: "This will earase the account data.",
        buttons: [
          {
            label: "Delete",
            onClick: async () => {
              this.setState({ uiLoading: true });
              const authToken = localStorage.getItem("AuthToken");
              authMiddleWare(this.props.history);
              if (authToken == null) {
                alert("Your session is expired, please login to retry.");
                this.setState({ uiLoading: false });
                this.props.setLogin(false);
              } else {
                const formRequestTry = {
                  userId: this.state.personArray,
                };
                if (authToken == null) {
                  alert("Your session is expired, please login to retry.");
                  this.setState({ uiLoading: false });
                  this.props.setLogin(false);
                } else {
                  axios.defaults.headers.common = {
                    Authorization: `${authToken}`,
                  };
                  //if confirmed, will delete data in db
                  await axios
                    .post(`${baseURL}/deleteAccount`, formRequestTry)
                    .then(async (response) => {
                      //refresh data page
                      const authToken = localStorage.getItem("AuthToken");
                      authMiddleWare(this.props.history);
                      if (authToken == null) {
                        alert(
                          "Your session is expired, please login to retry."
                        );
                        this.setState({ uiLoading: false });
                        this.props.setLogin(false);
                      } else {
                        axios.defaults.headers.common = {
                          Authorization: `${authToken}`,
                        };
                        await axios
                          .get(`${baseURL}/getAllUser`)
                          .then((response) => {
                            this.setState({
                              users: response.data,
                              isChecked: response.data.slice().fill(false),
                              uiLoading: false,
                            });
                          })
                          .catch((err) => {
                            console.log(err);
                            if (err.response.status === 403) {
                              alert(
                                "Your session is expired, please login to retry."
                              );
                              this.setState({ uiLoading: false });
                              this.props.setLogin(false);
                            }
                          });
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                      if (err.response.status === 403) {
                        alert(
                          "Your session is expired, please login to retry."
                        );
                        this.setState({ uiLoading: false });
                        this.props.setLogin(false);
                      }
                    });
                }
              }

              this.setState({
                uiLoading: false,
                personArray: [],
                viewOpen: false,
              });
            },
          },
          {
            label: "Cancel",
            onClick: () => onclose,
          },
        ],
      });
    }
  };

  //add account and save data to db
  handleAddAccount = async (event) => {
    event.preventDefault();
    this.setState({ uiLoading: true });
    const newUserData = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      phone: this.state.phone,
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
    };
    const authToken = localStorage.getItem("AuthToken");
    authMiddleWare(this.props.history);
    if (authToken == null) {
      alert("Your session is expired, please login to retry.");
      this.setState({ uiLoading: false });
      this.props.setLogin(false);
    } else {
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      //add the new account to db, and also firestore authentication
      await axios
        .post(`${baseURL}/signup`, newUserData)
        .then(async (response) => {
          this.setState({
            uiLoading: false,
            viewOpen: false,
            errors: [],
          });
          //refresh data page

          authMiddleWare(this.props.history);
          if (authToken == null) {
            alert("Your session is expired, please login to retry.");
            this.setState({ uiLoading: false });
            this.props.setLogin(false);
          } else {
            axios.defaults.headers.common = { Authorization: `${authToken}` };
            await axios
              .get(`${baseURL}/getAllUser`)
              .then((response) => {
                this.setState({
                  users: response.data,
                  isChecked: response.data.slice().fill(false),
                  uiLoading: false,
                });
              })
              .catch((err) => {
                console.log(err);
                if (err.response.status === 403) {
                  alert("Your session is expired, please login to retry.");
                  this.setState({ uiLoading: false });
                  this.props.setLogin(false);
                }
              });
          }
        })
        .catch((error) => {
          this.setState({
            errors: error.response.data,
            uiLoading: false,
          });
          if (error.response.status === 403) {
            alert("Your session is expired, please login to retry.");
            this.setState({ uiLoading: false });
            this.props.setLogin(false);
          }
        });
    }
  };

  //render function
  render() {
    //if the list checkbox value is changed, change the state of it accordingly
    const toggleCheckboxValue = (index) => {
      this.setState({
        isChecked: this.state.isChecked.map((v, i) => (i === index ? !v : v)),
      });
    };
    const { viewOpen, loading, errors } = this.state;
    const { classes } = this.props;

    //close the dialogbox
    const handleViewClose = () => {
      this.setState({ viewOpen: false });
    };

    //loading UI
    if (this.state.uiLoading === true) {
      return (
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {this.state.uiLoading && (
            <CircularProgress size={150} className={classes.uiProgess} />
          )}
        </main>
      );
    } else {
      //if not loading, return actual UI and dialogbox
      return (
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Card className={clsx(classes.root, classes)}>
            <CardContent>
              <div className={classes.details}>
                <div>
                  <Typography
                    className={classes.locationText}
                    gutterBottom
                    variant="h4"
                  >
                    User List
                  </Typography>
                </div>
              </div>
              <div className={classes.progress} />
            </CardContent>
            <Divider />
          </Card>

          <br />
          <Card className={clsx(classes.root, classes)}>
            <CardContent>
              {this.state.users.map((person, index) => (
                <div id={index} key={index} style={{ position: "relative" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        key={index}
                        checked={this.state.isChecked[index]}
                        color="primary"
                        onClick={() => toggleCheckboxValue(index)}
                        name={person.firstName}
                        value={person.userId}
                      />
                    }
                    label={person.email}
                  />
                </div>
              ))}
            </CardContent>
          </Card>
          <Button
            color="primary"
            variant="contained"
            className={classes.addButton}
            onClick={() => this.handleViewOpen()}
          >
            Add User
          </Button>

          <Button
            color="primary"
            variant="contained"
            className={classes.deleteButton}
            onClick={this.deleteAccount}
          >
            Delete Selected User
          </Button>

          <Dialog
            onClose={handleViewClose}
            aria-labelledby="customized-dialog-title"
            open={viewOpen}
            fullWidth
            classes={{ paperFullWidth: classes.dialogeStyle }}
          >
            <DialogTitle id="customized-dialog-title" onClose={handleViewClose}>
              Add User
            </DialogTitle>
            <DialogContent dividers>
              <form className={classes.form} noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="firstName"
                      label="First Name"
                      name="firstName"
                      autoComplete="firstName"
                      helperText={errors.firstName}
                      error={errors.firstName ? true : false}
                      onChange={this.handleChange}
                      color="primary"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      autoComplete="lastName"
                      helperText={errors.lastName}
                      error={errors.lastName ? true : false}
                      onChange={this.handleChange}
                      color="primary"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      type="email"
                      autoComplete="email"
                      helperText={errors.email}
                      error={errors.email ? true : false}
                      onChange={this.handleChange}
                      color="primary"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="phone"
                      label="Mobile Phone"
                      name="phone"
                      type="tel"
                      autoComplete="phone"
                      helperText={errors.phone}
                      error={errors.phone ? true : false}
                      onChange={this.handleChange}
                      color="primary"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      helperText={errors.password}
                      error={errors.password ? true : false}
                      onChange={this.handleChange}
                      color="primary"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      name="confirmPassword"
                      label="Confirm Password"
                      type="password"
                      id="confirmPassword"
                      autoComplete="current-password"
                      onChange={this.handleChange}
                      color="primary"
                    />
                  </Grid>
                </Grid>
                {!!errors?.error?.message && (
                  <Typography style={{ color: "red" }}>
                    {errors?.error?.message}
                  </Typography>
                )}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={this.handleAddAccount}
                  disabled={
                    loading ||
                    !this.state.email ||
                    !this.state.password ||
                    !this.state.firstName ||
                    !this.state.lastName ||
                    !this.state.confirmPassword
                  }
                >
                  Add
                  {loading && (
                    <CircularProgress size={30} className={classes.progess} />
                  )}
                </Button>
              </form>
            </DialogContent>
          </Dialog>
        </main>
      );
    }
  }
}

//styles
const styles = (theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  root: { width: "800px" },
  details: {
    display: "flex",
  },
  locationText: {
    paddingLeft: "15px",
  },
  uiProgess: {
    position: "fixed",
    zIndex: "1000",
    height: "31px",
    width: "31px",
    left: "50%",
    top: "35%",
  },
  progess: {
    position: "absolute",
  },
  assignText: {
    textAlign: "left",
  },
  assignButton: {
    position: "absolute",
    right: "0",
  },
  addButton: {
    marginTop: "10px",
  },
  deleteButton: {
    marginTop: "10px",
    marginLeft: "10px",
  },
  dialogeStyle: {
    maxWidth: "50%",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

export default withStyles(styles)(account);
