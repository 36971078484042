/*/////////////////////////////////////////////////////////////////
//    FileName: auth.js
//    Author: Ho Da Zong
//    Created On: 7-May-2021
//    Last Modified On: 14-May-2021
//    Copy Rights: Orinno Technology Pte Ltd
//    Description: getting local storage token, 
//                 if is null then route to login page.
*//////////////////////////////////////////////////////////////////


//get local token, if null route back to login
export const authMiddleWare = (history) => {
    const authToken = localStorage.getItem('AuthToken');
    if(authToken === null){
        history.push('/')
    }
}