/*/////////////////////////////////////////////////////////////////
//    FileName: setting.js
//    Author: Ramzi TM
//    Created On: 6-Apr-2022
//    Last Modified On: 6-Apr-2022
//    Copy Rights: Orinno Technology Pte Ltd
//    Description: sectorTime details DIV component, 
//                 will display at beside home page drawer if called.
*/ /////////////////////////////////////////////////////////////////

import React, { Component, Fragment } from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Card,
  CardContent,
  Divider,
  Button,
  Grid,
  TextField,
} from "@material-ui/core";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import clsx from "clsx";
import axios from "axios";
import { authMiddleWare } from "../util/auth";
import { baseURL } from "../util/port.js";
import { confirmAlert } from "react-confirm-alert"; // Import
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as XLSX from "xlsx";

//main class component
class sectorTime extends Component {
  constructor(props) {
    super(props);

    this.days = [
      { label: "Mon", value: 1 },
      { label: "Tue", value: 2 },
      { label: "Wed", value: 3 },
      { label: "Thurs", value: 4 },
      { label: "Fri", value: 5 },
    ];

    this.hours = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
    ];

    this.minutes = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
      "31",
      "32",
      "33",
      "34",
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
      "51",
      "52",
      "53",
      "54",
      "55",
      "56",
      "57",
      "58",
      "59",
    ];

    //state variable
    this.state = {
      uiLoading: true,
      loading: false,
      errors: [],
      errMsg: "",
      daysSelected: [],
      hoursSelected: null,
      minutesSelected: null,
      siteList: [],
      siteSelected: null,
      sectorList: [],
      sectorSelected: null,
      userList: [],
      userSelected: null,
      groupDate: new Date().toISOString().split("T")[0],
      startDate: new Date().toISOString().split("T")[0],
      endDate: new Date().toISOString().split("T")[0],
      dateList: [],
      isAvailable: null,
      allData: [],
      sheet0: "",
      col0: [],
      row0: [],
      deleteDate: new Date().toISOString().split("T")[0],
      deleteSite: null,
      deleteUser: null,
    };
    this.handleGetData = this.handleGetData.bind(this);
  }

  //will run once everytime the page render
  componentDidMount = async () => {
    const authToken = localStorage.getItem("AuthToken");
    //request API to get all site data
    authMiddleWare(this.props.history);
    if (authToken == null) {
      alert("Your session is expired, please login to retry.");
      this.setState({ uiLoading: false });
      this.props.setLogin(false);
    } else {
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      await axios
        .get(`${baseURL}/getSite`)
        .then((response) => {
          let fixData = [];
          response.data.forEach((data) => {
            fixData.push(`${data.name}`);
          });
          this.setState({
            siteList: fixData,
            // uiLoading: false
          });
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 403) {
            alert("Your session is expired, please login to retry.");
            this.setState({ uiLoading: false });
            this.props.setLogin(false);
          }
        });
    }

    //request API to get all sector data
    authMiddleWare(this.props.history);
    if (authToken == null) {
      alert("Your session is expired, please login to retry.");
      this.setState({ uiLoading: false });
      this.props.setLogin(false);
    } else {
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      await axios
        .get(`${baseURL}/getSector`)
        .then((response) => {
          let fixData = [];
          response.data.forEach((data) => {
            fixData.push(`${data.siteBase}`);
          });
          this.setState({
            sectorList: fixData,
            // uiLoading: false
          });
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 403) {
            alert("Your session is expired, please login to retry.");
            this.setState({ uiLoading: false });
            this.props.setLogin(false);
          }
        });
    }

    //retrieve the user data from db
    authMiddleWare(this.props.history);
    if (authToken == null) {
      alert("Your session is expired, please login to retry.");
      this.setState({ uiLoading: false });
      this.props.setLogin(false);
    } else {
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      await axios
        .get(`${baseURL}/getAllUser`)
        .then((response) => {
          let fixData = [];
          response.data.forEach((data) => {
            fixData.push({
              id: data.userId,
              name: `${data.firstName} ${data.lastName}`,
              firstName: data.firstName,
              role: data.role,
            });
          });
          this.setState({
            userList: fixData,
            uiLoading: false,
          });
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 403) {
            alert("Your session is expired, please login to retry.");
            this.setState({ uiLoading: false });
            this.props.setLogin(false);
          }
        });
    }

    await this.handleGetData();
  };

  handleGetData = async () => {
    this.setState({ uiLoading: true });
    const authToken = localStorage.getItem("AuthToken");
    //request API to get all site data
    authMiddleWare(this.props.history);
    if (authToken == null) {
      alert("Your session is expired, please login to retry.");
      this.setState({ uiLoading: false });
      this.props.setLogin(false);
    } else {
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      await axios
        .get(`${baseURL}/sectorTimeUser`)
        .then((response) => {
          console.log(response.data);
          const siteList = response.data.filter(
            (v, i, a) => a.findIndex((v2) => v2.site === v.site) === i
          );
          const sectorList = response.data.filter(
            (v, i, a) => a.findIndex((v2) => v2.sector === v.sector) === i
          );
          console.log(siteList);
          console.log(sectorList);
          this.setState({
            allData: response.data,
            uiLoading: false,
          });
        })
        .catch((err) => {
          console.log(err);
          if (err?.response?.status === 403) {
            alert("Your session is expired, please login to retry.");
            this.setState({ uiLoading: false });
            this.props.setLogin(false);
          }
        });
    }
  };

  //save data to db
  handleSubmit = async () => {
    if (!this.state.siteSelected) {
      this.setState({ errMsg: "Site is empty" });
      return;
    }
    if (!this.state.sectorSelected) {
      this.setState({ errMsg: "Sector is empty" });
      return;
    }
    if (!this.state.startDate) {
      this.setState({ errMsg: "Start date is empty" });
      return;
    }
    if (!this.state.endDate) {
      this.setState({ errMsg: "End date is empty" });
      return;
    }
    if (!this.state.daysSelected?.length) {
      this.setState({ errMsg: "Days is empty" });
      return;
    }
    if (!this.state.dateList?.length) {
      this.setState({ errMsg: "Dates is empty" });
      return;
    }
    if (!this.state.userSelected?.id) {
      this.setState({ errMsg: "User is empty" });
      return;
    }
    if (!this.state.hoursSelected) {
      this.setState({ errMsg: "Hours is empty" });
      return;
    }
    if (!this.state.minutesSelected) {
      this.setState({ errMsg: "Minutes is empty" });
      return;
    }
    console.log("aman");
    const adminName = localStorage.getItem("adminName");
    let param = [];
    this.state.dateList.map((v) =>
      param.push({
        site: this.state.siteSelected,
        sector: this.state.sectorSelected,
        date: new Date(v).toISOString().split("T")[0],
        dateFormat: new Date(
          v.setHours(this.state.hoursSelected, this.state.minutesSelected, 0)
        ).getTime(),
        userName: this.state.userSelected.name,
        firstName: this.state.userSelected.firstName,
        userRole: this.state.userSelected.role,
        userId: this.state.userSelected.id,
        assignBy: adminName,
      })
    );
    console.log(param);
    const authToken = localStorage.getItem("AuthToken");
    this.setState({ uiLoading: true });
    const options = {
      url: `${baseURL}/sectorTimeUser`,
      method: "put",
      data: {
        param: param,
      },
    };
    if (authToken == null) {
      alert("Your session is expired, please login to retry.");
      this.setState({ uiLoading: false });
      this.props.setLogin(false);
    } else {
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      //send the update
      await axios(options)
        .then(() => {
          this.setState(
            {
              siteSelected: null,
              sectorSelected: null,
              startDate: new Date().toISOString().split("T")[0],
              endDate: new Date().toISOString().split("T")[0],
              daysSelected: [],
              dateList: [],
              userSelected: {},
              hoursSelected: null,
              minutesSelected: null,
              uiLoading: false,
            },
            () => this.handleGetData()
          );
          // window.location.reload();
        })
        .catch((error) => {
          if (error.response.status === 403) {
            alert("Your session is expired, please login to retry.");
            this.setState({ uiLoading: false });
            this.props.setLogin(false);
          }
          this.setState({ uiLoading: false, errors: error.response.data });
          console.log(error);
        });
    }
  };

  getDaysArray = async () => {
    if (
      this.state.startDate &&
      this.state.endDate &&
      this.state.siteSelected &&
      this.state.sectorSelected &&
      this.state.daysSelected?.length
    ) {
      this.setState({ isAvailable: false }, async () => {
        for (
          var arr = [], dt = new Date(this.state.startDate);
          dt <= new Date(this.state.endDate);
          dt.setDate(dt.getDate() + 1)
        ) {
          arr.push(new Date(dt));
        }
        const checkDay = arr.filter((v) =>
          this.state.daysSelected.some(({ value: day }) => day === v.getDay())
        );
        console.log(checkDay);

        if (checkDay.length) {
          this.setState({ isAvailable: true, dateList: checkDay });

          // remove multiple user checking
          // let param = []
          // checkDay.map((v) => (
          //   param.push({
          //     site: this.state.siteSelected,
          //     sector: this.state.sectorSelected,
          //     date: new Date(v).toISOString().split('T')[0]
          //   })
          // ))

          // const authToken = localStorage.getItem('AuthToken');
          // this.setState({ isLoading: true });
          // const options = {
          //   url: `${baseURL}/sectorTimeUser`,
          //   method: 'post',
          //   data: {
          //     param: param,
          //   }
          // };
          // if (authToken == null) {
          //   alert('Your session is expired, please login to retry.');
          //   this.setState({isLoading: false});
          //   this.props.setLogin(false);
          // } else {
          //   axios.defaults.headers.common = { Authorization: `${authToken}` };
          //   //send the update
          //   await axios(options)
          //     .then((response) => {
          //       console.log(response.data);
          //       if (response.data?.length) {
          //         this.setState({ isAvailable: false, isMultiple: true, dateList: [] })
          //       } else {
          //         this.setState({ isAvailable: true, isMultiple: false, dateList: checkDay })
          //       }
          //       // this.setState({
          //       //   isLoading: false,
          //       // });
          //     })
          //     .catch((error) => {
          //       if (error.response.status === 403) {
          //         alert('Your session is expired, please login to retry.');
          //         this.setState({isLoading: false});
          //         this.props.setLogin(false);
          //       }
          //       this.setState({ isLoading: false, errors: error.response.data });
          //       console.log(error);
          //     });
          // }
        } else {
          this.setState({
            hoursSelected: null,
            minutesSelected: null,
            userSelected: null,
            isAvailable: false,
            dateList: checkDay,
          });
        }
        // return checkDay;
      });
    }
  };

  //change value and assign to state change
  handleChange = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.value,
        errMsg: "",
      },
      () => {
        this.getDaysArray();
      }
    );
  };

  //delete dialogbox
  handleDelete = (data) => {
    confirmAlert({
      title: "Confirm to delete the record?",
      // message: 'This might affect all others related data.',
      buttons: [
        {
          label: "Delete",
          onClick: async () => {
            this.setState({ uiLoading: true });
            authMiddleWare(this.props.history);
            const authToken = localStorage.getItem("AuthToken");
            if (authToken == null) {
              alert("Your session is expired, please login to retry.");
              this.setState({ uiLoading: false });
              this.props.setLogin(false);
            } else {
              axios.defaults.headers.common = { Authorization: `${authToken}` };
              //send request to delete site db
              await axios
                .delete(`${baseURL}/sectorTimeUser/${data.id}`)
                .then(async () => {
                  await this.handleGetData();
                  this.setState({
                    uiLoading: false,
                    errors: [],
                  });
                })
                .catch((err) => {
                  if (err.response.status === 403) {
                    alert("Your session is expired, please login to retry.");
                    this.setState({ uiLoading: false });
                    this.props.setLogin(false);
                  }
                  console.log(err);
                });
            }
          },
        },
        {
          label: "Cancel",
          onClick: () => onclose,
        },
      ],
    });
  };

  //render function
  render() {
    let uploadbtn = null;

    //trigger event upload usersRegion
    const handleUploadUsersRegion = () => {
      confirmAlert({
        title: "Confirm to push the data to usersRegion?",
        message: "Make sure to double check the data, or duplication.",
        buttons: [
          {
            label: "Submit",
            onClick: async () => {
              console.log(this.state.row0);
              this.setState({ uiLoading: true });
              const authToken = localStorage.getItem("AuthToken");
              const options = {
                url: `${baseURL}/sectorTimeUser`,
                method: "put",
                data: {
                  param: this.state.row0,
                },
              };
              axios.defaults.headers.common = { Authorization: `${authToken}` };
              await axios(options)
                .then((res) => {
                  console.log(res);
                  handleReset();
                  this.handleGetData();
                  this.setState({ uiLoading: false });
                })
                .catch((error) => {
                  console.log(error);
                  if (error.response.status === 403) {
                    alert("Your session is expired, please login to retry.");
                    this.setState({ uiLoading: false });
                    this.props.setLogin(false);
                  }
                  this.setState({
                    uiLoading: false,
                    errors: error.response.data,
                  });
                  console.log(error);
                });
            },
          },
          {
            label: "Cancel",
            onClick: () => onclose,
          },
        ],
      });
    };

    //trigger reset
    const handleReset = () => {
      if (uploadbtn) uploadbtn.value = "";
      this.setState({
        sheet0: "",
        col0: [],
        row0: [],
      });
    };

    // trigger create sector group
    const handleCreateGroup = () => {
      const date = this.state.groupDate;
      console.log(date);
      confirmAlert({
        title: "Confirm to rewrite usersRegionDaily?",
        message: "This will affect on driver's sector list.",
        buttons: [
          {
            label: "Confirm",
            onClick: async () => {
              this.setState({ uiLoading: true });
              const authToken = localStorage.getItem("AuthToken");
              authMiddleWare(this.props.history);
              if (authToken == null) {
                alert("Your session is expired, please login to retry.");
                this.setState({ uiLoading: false });
                this.props.setLogin(false);
              } else {
                axios.defaults.headers.common = {
                  Authorization: `${authToken}`,
                };
                //if confirmed, will change the status in collection
                await axios
                  .get(`${baseURL}/createSectorGroup/${date}`)
                  .then(async (response) => {
                    console.log(response);
                    this.setState({ uiLoading: false });
                  })
                  .catch((err) => {
                    console.log(err);
                    if (err.response.status === 403) {
                      alert("Your session is expired, please login to retry.");
                      this.setState({ uiLoading: false });
                      this.props.setLogin(false);
                    }
                  });
              }
            },
          },
          {
            label: "Cancel",
            onClick: () => onclose,
          },
        ],
      });
    };

    // trigger bulk delete
    const handleBulkDelete = () => {
      const dateDelete = this.state.deleteDate;
      const siteDelete = this.state.deleteSite;
      const userDelete = this.state.deleteUser;
      console.log(dateDelete);
      console.log(siteDelete);
      console.log(userDelete);
      confirmAlert({
        title: "Confirm to bulk delete?",
        message: `This will delete all assignment on date ${deleteDate}${
          siteDelete ? " with " + siteDelete : ""
        }${userDelete ? " for user " + userDelete?.name : ""}. ${
          userDelete?.role !== "driver"
            ? "This will also delete related assignment for the driver who have the same sector"
            : ""
        }`,
        buttons: [
          {
            label: "Confirm",
            onClick: async () => {
              this.setState({ uiLoading: true });
              const authToken = localStorage.getItem("AuthToken");
              authMiddleWare(this.props.history);
              if (authToken == null) {
                alert("Your session is expired, please login to retry.");
                this.setState({ uiLoading: false });
                this.props.setLogin(false);
              } else {
                axios.defaults.headers.common = {
                  Authorization: `${authToken}`,
                };
                //if confirmed, will change the status in collection
                await axios
                  .post(`${baseURL}/bulkDelSectorRangeUser`, {
                    date: dateDelete,
                    site: siteDelete,
                    userId: userDelete?.id,
                    role: userDelete?.role,
                  })
                  .then(async (response) => {
                    await this.handleGetData();
                    console.log(response);
                    this.setState({ uiLoading: false });
                  })
                  .catch((err) => {
                    console.log(err);
                    if (err?.response?.status === 403) {
                      alert("Your session is expired, please login to retry.");
                      this.props.setLogin(false);
                    } else {
                      alert("Something went wrong, please try again.");
                    }
                    this.setState({ uiLoading: false });
                  });
              }
            },
          },
          {
            label: "Cancel",
            onClick: () => onclose,
          },
        ],
      });
    };

    //get ID user
    const handleUserID = async (data) => {
      let res = { id: "", name: "", firstName: "", role: "" };
      const authToken = localStorage.getItem("AuthToken");
      //retrieve the issue data from db
      authMiddleWare(this.props.history);
      if (authToken == null) {
        alert("Your session is expired, please login to retry.");
        this.setState({ uiLoading: false });
        this.props.setLogin(false);
      } else {
        const options = {
          url: `${baseURL}/getUserId`,
          method: "post",
          data: {
            email: data,
          },
        };
        axios.defaults.headers.common = { Authorization: `${authToken}` };
        await axios(options)
          .then((response) => {
            console.log(response.data);
            this.setState({
              uiLoading: false,
            });
            res.id = response.data.id;
            res.name = response.data.name;
            res.firstName = response.data.firstName;
            res.role = response.data.role;
          })
          .catch((err) => {
            console.log(err);
            if (err.response.status === 403) {
              alert("Your session is expired, please login to retry.");
              this.setState({ uiLoading: false });
              this.props.setLogin(false);
            }
          });

        return res;
      }
    };

    const onChange = async (e) => {
      this.setState({ uiLoading: true });
      const [file] = e.target.files;
      if (file) {
        const reader = new FileReader();

        reader.onload = async (evt) => {
          const bstr = evt.target.result;
          const wb = XLSX.read(bstr, { type: "binary" });
          const wsname0 = wb.SheetNames[0];
          const ws0 = wb.Sheets[wsname0];
          const data0 = XLSX.utils.sheet_to_json(ws0, { header: 1 });

          const row0 = data0.slice(1).map((r) =>
            r.reduce((acc, x, i) => {
              acc[data0[0][i]] = x;
              return acc;
            }, {})
          );

          // filter unique email list
          const map = new Map();
          for (let row of row0) {
            map.set(row["email"], row);
          }
          const iterartoRow = map.values();
          const uniqueEmail = [...iterartoRow];
          console.log(uniqueEmail);

          const getEmail = await Promise.all(
            uniqueEmail.map(async (v) => {
              const userId = await handleUserID(v.email);
              return {
                ...{ userId: userId.id },
                ...{ userName: userId.name },
                ...{ firstName: userId.firstName },
                ...{ userRole: userId.role },
                ...v,
              };
            })
          );

          const row0fix = row0.map((v) => {
            const check = getEmail.find((a) => a.email === v.email);
            return {
              ...{ userId: check.userId },
              ...{ userName: check.userName },
              ...{ firstName: check.firstName },
              ...{ userRole: check.userRole },
              ...{
                dateFormat: new Date(
                  new Date(v.date).setHours(
                    v.time.split(".")[0],
                    v.time.split(".")[1],
                    0
                  )
                ).getTime(),
              },
              ...v,
            };
          });
          const col0fix = data0[0].map((r) => r);
          col0fix.splice(4, 0, "userId");
          col0fix.splice(5, 0, "userName");

          this.setState(
            {
              sheet0: wsname0,
              col0: col0fix,
              row0: row0fix,
            },
            () => {
              this.setState({ uiLoading: false });
            }
          );
        };
        reader.readAsBinaryString(file);
      }
    };

    const { classes } = this.props;
    const {
      startDate,
      endDate,
      allData,
      sheet0,
      col0,
      row0,
      groupDate,
      deleteDate,
    } = this.state;

    //loading UI
    if (this.state.uiLoading === true) {
      return (
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {this.state.uiLoading && (
            <CircularProgress size={150} className={classes.uiProgess} />
          )}
        </main>
      );
    } else {
      //if not loading, return actual UI and dialogbox
      return (
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Card className={clsx(classes.root, classes)}>
            <CardContent>
              <div className={classes.details}>
                <div>
                  <Typography
                    className={classes.locationText}
                    gutterBottom
                    variant="h4"
                  >
                    Set Sector Start Time
                  </Typography>
                </div>
              </div>
              <div className={classes.progress} />
            </CardContent>
            <Divider />
          </Card>

          <br />
          <Grid container spacing={4}>
            <Grid item xs={8}>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <Autocomplete
                    style={{ width: "100%" }}
                    options={this.state.siteList}
                    id={"site"}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Site"
                        id={"site"}
                        required
                      />
                    )}
                    onChange={(event, value) => {
                      this.setState(
                        { siteSelected: value, errMsg: "" },
                        async () => {
                          await this.getDaysArray();
                        }
                      );
                    }}
                    value={this.state.siteSelected}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    style={{ width: "100%" }}
                    options={this.state.sectorList}
                    id={"sector"}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Sector"
                        id={"sector"}
                        required
                      />
                    )}
                    onChange={(event, value) => {
                      this.setState(
                        { sectorSelected: value, errMsg: "" },
                        () => {
                          this.getDaysArray();
                        }
                      );
                    }}
                    value={this.state.sectorSelected}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    onChange={this.handleChange}
                    id="startDate"
                    label="Start Date"
                    name="startDate"
                    autoComplete="startDate"
                    type="date"
                    value={startDate}
                    color="primary"
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    onChange={this.handleChange}
                    id="endDate"
                    label="End Date"
                    name="endDate"
                    autoComplete="endDate"
                    type="date"
                    value={endDate}
                    color="primary"
                  />
                </Grid>
                <Grid item xs={2}>
                  <Autocomplete
                    multiple
                    style={{ width: "100%" }}
                    options={this.days}
                    getOptionLabel={(option) => option.label}
                    id={"days"}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Every"
                        id={"days"}
                        required
                      />
                    )}
                    onChange={(event, value) => {
                      this.setState({ daysSelected: value, errMsg: "" }, () => {
                        this.getDaysArray();
                      });
                    }}
                    value={this.state.daysSelected}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              {/* {!this.state.isAvailable && this.state.isMultiple && this.state.startDate && this.state.endDate && this.state.siteSelected && this.state.sectorSelected && !!this.state.daysSelected?.length && !this.state.dateList?.length &&
                <Typography className={classes.errorText} variant="p">
                  Multiple user detected
                </Typography>
              } */}
              {!this.state.isAvailable &&
                !this.state.isMultiple &&
                this.state.startDate &&
                this.state.endDate &&
                this.state.siteSelected &&
                this.state.sectorSelected &&
                !!this.state.daysSelected?.length &&
                !this.state.dateList?.length && (
                  <Typography className={classes.errorText} variant="p">
                    Day not found on selected date range
                  </Typography>
                )}
              {!!this.state.isAvailable &&
                this.state.startDate &&
                this.state.endDate &&
                this.state.siteSelected &&
                this.state.sectorSelected &&
                !!this.state.daysSelected?.length &&
                !!this.state.dateList?.length && (
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Autocomplete
                        style={{ width: "100%" }}
                        options={this.state.userList}
                        getOptionLabel={(option) => option.name}
                        id={"user"}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="User"
                            id={"user"}
                            required
                          />
                        )}
                        onChange={(event, value) => {
                          this.setState(
                            { userSelected: value, errMsg: "" },
                            () => {
                              this.getDaysArray();
                            }
                          );
                        }}
                        value={this.state.userSelected}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Autocomplete
                        style={{ width: "100%" }}
                        options={this.hours}
                        id={"hours"}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Hour"
                            id={"hours"}
                            required
                          />
                        )}
                        onChange={(event, value) => {
                          this.setState(
                            { hoursSelected: value, errMsg: "" },
                            () => {
                              this.getDaysArray();
                            }
                          );
                        }}
                        value={this.state.hoursSelected}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Autocomplete
                        style={{ width: "100%" }}
                        options={this.minutes}
                        id={"minutes"}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Minute"
                            id={"minutes"}
                            required
                          />
                        )}
                        onChange={(event, value) => {
                          this.setState(
                            { minutesSelected: value, errMsg: "" },
                            () => {
                              this.getDaysArray();
                            }
                          );
                        }}
                        value={this.state.minutesSelected}
                      />
                    </Grid>
                  </Grid>
                )}
            </Grid>
          </Grid>
          {this.state.errMsg !== "" && (
            <div>
              <Typography className={classes.errorText} variant="p">
                {this.state.errMsg}
              </Typography>
            </div>
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              height: 80,
            }}
          >
            <Button
              color="primary"
              variant="contained"
              className={classes.addButton}
              onClick={() => this.handleSubmit()}
            >
              Save
            </Button>
            <input
              onChange={onChange}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              className={classes.input}
              id="contained-button-file"
              type="file"
              ref={(ref) => (uploadbtn = ref)}
            />
            <label htmlFor="contained-button-file">
              <Button
                disabled={sheet0 !== ""}
                style={{ marginLeft: "15px" }}
                variant="contained"
                color="primary"
                component="span"
              >
                Upload File
              </Button>
            </label>
            {sheet0 !== "" && (
              <Button
                color="primary"
                variant="contained"
                component="span"
                onClick={() => handleReset()}
                style={{ marginLeft: "15px" }}
              >
                Reset All
              </Button>
            )}

            <Divider
              variant="middle"
              orientation="vertical"
              flexItem={true}
              style={{
                display: "inline-flex",
                marginTop: 10,
                marginBottom: 10,
                marginLeft: 25,
                marginRight: 25,
                width: 2,
              }}
            />

            <TextField
              onChange={this.handleChange}
              id="groupDate"
              label="Group Date"
              name="groupDate"
              autoComplete="groupDate"
              type="date"
              value={groupDate}
              color="primary"
            />

            <Button
              color="primary"
              variant="contained"
              className={classes.addButton}
              style={{ marginLeft: "15px" }}
              onClick={() => handleCreateGroup()}
            >
              Create Sector Group
            </Button>

            <Divider
              variant="middle"
              orientation="vertical"
              flexItem={true}
              style={{
                display: "inline-flex",
                marginTop: 10,
                marginBottom: 10,
                marginLeft: 25,
                marginRight: 25,
                width: 2,
              }}
            />

            <Grid container style={{ width: "50%" }} spacing={1}>
              <Grid item>
                <TextField
                  onChange={this.handleChange}
                  InputProps={{
                    inputProps: { min: new Date().toISOString().split("T")[0] },
                  }}
                  id="deleteDate"
                  label="Delete Date"
                  name="deleteDate"
                  autoComplete="deleteDate"
                  type="date"
                  value={deleteDate}
                  color="primary"
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  style={{ width: "100%" }}
                  options={this.state.siteList}
                  id={"deleteSite"}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Delete Site"
                      id={"deleteSite"}
                    />
                  )}
                  onChange={(event, value) => {
                    this.setState({ deleteSite: value, errMsg: "" });
                  }}
                  value={this.state.deleteSite}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  style={{ width: "100%" }}
                  options={this.state.userList}
                  getOptionLabel={(option) => option.name}
                  id={"deleteUser"}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Delete User"
                      id={"deleteUser"}
                    />
                  )}
                  onChange={(event, value) => {
                    this.setState({ deleteUser: value, errMsg: "" });
                  }}
                  value={this.state.deleteUser}
                />
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.addButton}
                  onClick={() => handleBulkDelete()}
                >
                  Bulk Delete
                </Button>
              </Grid>
            </Grid>
          </div>

          {!!row0.length && (
            <>
              <Divider
                variant="middle"
                style={{ marginTop: 30, marginBottom: 30, width: "100%" }}
              />
              <Grid container spacing={4} justifycontent="space-between">
                <Grid item xs={6}>
                  <Typography variant="h5">From File</Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }}>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.addButton}
                    onClick={() => handleUploadUsersRegion()}
                  >
                    Save to database
                  </Button>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Paper className={classes.paperTable}>
                    <Table stickyHeader className={classes.table}>
                      <TableHead>
                        <TableRow>
                          {!!col0.length &&
                            col0.map((item, i) => (
                              <Fragment key={`col-${i}`}>
                                <TableCell>{item}</TableCell>
                              </Fragment>
                            ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {!!row0.length &&
                          row0.map((item, i) => (
                            <Fragment key={`row-${i}`}>
                              <TableRow>
                                <TableCell>{item.assignBy}</TableCell>
                                <TableCell>{item.sector}</TableCell>
                                <TableCell>{item.site}</TableCell>
                                <TableCell>{item.email}</TableCell>
                                <TableCell>{item.userId}</TableCell>
                                <TableCell>{item.userName}</TableCell>
                                <TableCell>{item.date}</TableCell>
                                <TableCell>{item.time}</TableCell>
                              </TableRow>
                            </Fragment>
                          ))}
                      </TableBody>
                    </Table>
                  </Paper>
                </Grid>
              </Grid>
            </>
          )}

          <Divider
            variant="middle"
            style={{ marginTop: 30, marginBottom: 30, width: "100%" }}
          />
          <Grid container spacing={4} justifycontent="space-between">
            <Grid item xs={12}>
              <Typography variant="h5">From Database</Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className={classes.paperTable}>
                <Table stickyHeader className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Site</TableCell>
                      <TableCell>Sector</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>User Name</TableCell>
                      <TableCell>Assign By</TableCell>
                      <TableCell>Assign Date</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!allData.length &&
                      allData.map((item, i) => (
                        <Fragment key={`row-${i}`}>
                          <TableRow>
                            <TableCell>{item.site}</TableCell>
                            <TableCell>{item.sector}</TableCell>
                            <TableCell>
                              {new Date(
                                item.dateFormat?.seconds * 1000
                              ).toLocaleString()}
                            </TableCell>
                            <TableCell>{item.userName}</TableCell>
                            <TableCell>{item.assignBy}</TableCell>
                            <TableCell>
                              {new Date(
                                item.createdAt?.seconds * 1000
                              ).toLocaleString()}
                            </TableCell>
                            <TableCell>
                              <Button
                                style={{}}
                                variant="contained"
                                size="small"
                                color="primary"
                                onClick={() => this.handleDelete(item)}
                              >
                                Delete
                              </Button>
                            </TableCell>
                          </TableRow>
                        </Fragment>
                      ))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          </Grid>
        </main>
      );
    }
  }
}

//styles
const styles = (theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: "1400px",
  },
  toolbar: theme.mixins.toolbar,
  root: { width: "800px" },
  details: {
    display: "flex",
  },
  locationText: {
    paddingLeft: "15px",
  },
  errorText: {
    paddingLeft: "15px",
    color: "red",
  },
  input: {
    display: "none",
  },
  uiProgess: {
    position: "fixed",
    zIndex: "1000",
    height: "31px",
    width: "31px",
    left: "50%",
    top: "35%",
  },
  progess: {
    position: "absolute",
  },
  paperTable: {
    width: "100%",
    overflowX: "auto",
    maxHeight: 500,
  },
  assignText: {
    textAlign: "left",
  },
  assignButton: {
    position: "absolute",
    right: "0",
  },
  addButton: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  deleteButton: {
    marginTop: "10px",
    marginLeft: "10px",
  },
  dialogeStyle: {
    maxWidth: "50%",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

export default withStyles(styles)(sectorTime);
