/*/////////////////////////////////////////////////////////////////
//    FileName: index.js
//    Author: Ho Da Zong
//    Created On: 7-May-2021
//    Last Modified On: 14-May-2021
//    Copy Rights: Orinno Technology Pte Ltd
//    Description: index.js that render App.js component into 
//                 specific index.html element named: 'root'
*//////////////////////////////////////////////////////////////////

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.js';
import reportWebVitals from './reportWebVitals';

//App is the App.js function render, getelementbyID root is point to the index.html <div> id root
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
