/*/////////////////////////////////////////////////////////////////
//    FileName: setting.js
//    Author: Ramzi TM
//    Created On: 6-Apr-2022
//    Last Modified On: 6-Apr-2022
//    Copy Rights: Orinno Technology Pte Ltd
//    Description: setting details DIV component, 
//                 will display at beside home page drawer if called.
*/ /////////////////////////////////////////////////////////////////

import React, { Component } from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Card,
  CardContent,
  Divider,
  Button,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import clsx from "clsx";
import axios from "axios";
import { authMiddleWare } from "../util/auth";
import { confirmAlert } from "react-confirm-alert"; // Import
import { baseURL } from "../util/port.js";

//main class component
class setting extends Component {
  constructor(props) {
    super(props);

    //state variable
    this.state = {
      uiLoading: true,
      loading: false,
      errors: [],
      scanTimer: {},
      newScanTimer: 0,
      launchTimer: {},
      newLaunchTimer: 0,
      week: "",
      newWeek: "",
      radius: 0,
      newRadius: 0,
      crateInfo: true,
      newCrateInfo: true,
      apkVersion: "0.0.0",
      newApkVersion: "0.0.0",
    };
  }

  //will run once everytime the page render
  componentDidMount = async () => {
    const authToken = localStorage.getItem("AuthToken");
    //request API to get all users data
    authMiddleWare(this.props.history);
    if (authToken == null) {
      alert("Your session is expired, please login to retry.");
      this.setState({ uiLoading: false });
      this.props.setLogin(false);
    } else {
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      await axios
        .get(`${baseURL}/getSetting`)
        .then((response) => {
          const scanner = response.data.filter((v) => v.name === "timerNFC");
          const launcher = response.data.filter(
            (v) => v.name === "timerLaunchPoint"
          );
          const week = response.data.filter((v) => v.name === "eWeek");
          const radius = response.data.filter((v) => v.name === "blockRadius");
          const crateInfo = response.data.filter(
            (v) => v.name === "enableCrateInfo"
          );
          const apkVersion = response.data.filter((v) => v.id === "versionAPK");
          this.setState({
            scanTimer: scanner[0],
            launchTimer: launcher[0],
            week: week[0],
            radius: radius[0],
            crateInfo: crateInfo[0],
            apkVersion: apkVersion[0],
            newScanTimer: scanner[0].value,
            newLaunchTimer: launcher[0].value,
            newWeek: week[0].value,
            newRadius: radius[0].value,
            newCrateInfo: crateInfo[0].value,
            newApkVersion: apkVersion[0].value,
            uiLoading: false,
          });
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 403) {
            alert("Your session is expired, please login to retry.");
            this.setState({ uiLoading: false });
            this.props.setLogin(false);
          }
        });
    }
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  //FOR DEV TESTING ONLY
  // resetSectorName = () => {
  //   confirmAlert({
  //     title: "Confirm to test the api?",
  //     message: "Make sure it is not in the middle of release time.",
  //     buttons: [
  //       {
  //         label: "Test",
  //         onClick: async () => {
  //           // const grouped = [
  //           //   {
  //           //     userId: "8UqwNBKDnYUlvPxBErss8SXHamm2",
  //           //     userName: "Kamisah Tawil",
  //           //     sector: [
  //           //       "YS C (Thu) Kamisah",
  //           //       "YS AA (Thu) Kamisah",
  //           //       "YS AB (Thu) Kamisah",
  //           //     ],
  //           //   },
  //           //   {
  //           //     userId: "JUORczvJXTXavNg4xgnMIvvYHpH3",
  //           //     userName: "Roziyanna Mohamad",
  //           //     sector: [
  //           //       "CR F (Thu) Roziyanna",
  //           //       "CR G (Thu) Roziyanna",
  //           //       "CR E (Thu) Roziyanna",
  //           //       "CR L (Thu) Roziyanna",
  //           //       "CR J (Thu) Roziyanna",
  //           //       "CR M (Thu) Roziyanna",
  //           //     ],
  //           //   },
  //           //   {
  //           //     userId: "DphKHc1kWsaCkccEcXKjz8hqhMg1",
  //           //     userName: "Nora Tawil",
  //           //     sector: [
  //           //       "YS G (Thu) Nora",
  //           //       "YS H (Thu) Nora",
  //           //       "YS AM (Thu) Nora",
  //           //     ],
  //           //   },
  //           //   {
  //           //     userId: "63Q56sWXoxfOQKExFDGjgvG0NFm1",
  //           //     userName: "Alwin Chen",
  //           //     sector: [
  //           //       "YS N (Thu) Alwin",
  //           //       "YS AN (Thu) Alwin",
  //           //       "YS L (Thu) Alwin",
  //           //     ],
  //           //   },
  //           //   {
  //           //     userId: "fRCHug2QToMqeGh7UjsphOccp492",
  //           //     userName: "Nelson Lin",
  //           //     sector: [
  //           //       "YS Y (Thu) Nelson",
  //           //       "YS Z (Thu) Nelson",
  //           //       "YS B (Thu) Nelson",
  //           //       "YS X (Thu) Nelson",
  //           //     ],
  //           //   },
  //           //   {
  //           //     userId: "Mg7MquHYD4RmOicPEI5lgzKivLz1",
  //           //     userName: "Nasir Nasir",
  //           //     sector: [
  //           //       "CR A (Thu) Tham",
  //           //       "CR L (Thu) Roziyanna",
  //           //       "CR B (Thu) Tham",
  //           //       "CR J (Thu) Roziyanna",
  //           //       "CR F (Thu) Roziyanna",
  //           //       "CR D (Thu) Tham",
  //           //       "CR E (Thu) Roziyanna",
  //           //       "CR M (Thu) Roziyanna",
  //           //       "CR C (Thu) Tham",
  //           //       "CR G (Thu) Roziyanna",
  //           //     ],
  //           //   },
  //           //   {
  //           //     userId: "43FTAD0JiCOACwTayuYcEPHQbCp1",
  //           //     userName: "Azman Azman",
  //           //     sector: [
  //           //       "YS AG (Thu) tengjing",
  //           //       "YS AI (Thu) Wee Meng",
  //           //       "YS AO (Thu) Shu Zhen",
  //           //       "YS AP (Thu) Wee Meng",
  //           //       "YS AR (Thu) Wee Meng",
  //           //       "YS N (Thu) Alwin",
  //           //       "YS O (Thu) Lester",
  //           //       "YS P (Thu) Lester",
  //           //       "YS AU (Thu) Shu Zhen",
  //           //       "YS J (Thu) Wee Meng",
  //           //       "YS R (Thu) Chee Keong",
  //           //       "YS AC (Thu) Jason",
  //           //       "YS AN (Thu) Alwin",
  //           //       "YS AF (Thu) tengjing",
  //           //       "YS AD (Thu) Jason",
  //           //       "YS I (Thu) Shu Zhen",
  //           //       "YS AK (Thu) Lester",
  //           //       "YS AT (Thu) Chee Keong",
  //           //       "YS AH (Thu) tengjing",
  //           //       "YS L (Thu) Alwin",
  //           //     ],
  //           //   },
  //           //   {
  //           //     userId: "bWHHqBM5WbO76AQK44B6WWuV36H2",
  //           //     userName: "Harman Harman",
  //           //     sector: [
  //           //       "YS G (Thu) Nora",
  //           //       "YS AM (Thu) Nora",
  //           //       "YS F (Thu) Marzuki",
  //           //       "YS X (Thu) Nelson",
  //           //       "YS Z (Thu) Nelson",
  //           //       "YS AB (Thu) Kamisah",
  //           //       "YS Q (Thu) Dwight",
  //           //       "YS Y (Thu) Nelson",
  //           //       "YS A (Thu) Izzat",
  //           //       "YS D (Thu) Izzat",
  //           //       "YS H (Thu) Nora",
  //           //       "YS B (Thu) Nelson",
  //           //       "YS AS (Thu) CK",
  //           //       "YS C (Thu) Kamisah",
  //           //       "YS AA (Thu) Kamisah",
  //           //       "YS E (Thu) Marzuki",
  //           //       "YS S (Thu) Dwight",
  //           //       "YS W (Thu) Izzat",
  //           //       "YS T (Thu) CK",
  //           //     ],
  //           //   },
  //           //   {
  //           //     userId: "cCasvZevvEUVqoTWKl7bGls7S1C3",
  //           //     userName: "Izzat Mohd",
  //           //     sector: [
  //           //       "YS W (Thu) Izzat",
  //           //       "YS D (Thu) Izzat",
  //           //       "YS A (Thu) Izzat",
  //           //     ],
  //           //   },
  //           //   {
  //           //     userId: "azxtR4qIFpQ7bgoJBoo4iMM496G3",
  //           //     userName: "Shu Zhen Hng",
  //           //     sector: [
  //           //       "YS AO (Thu) Shu Zhen",
  //           //       "YS I (Thu) Shu Zhen",
  //           //       "YS AU (Thu) Shu Zhen",
  //           //     ],
  //           //   },
  //           //   {
  //           //     userId: "6OwJDk1FmDQYcjlo08BW9WLeRmk1",
  //           //     userName: "Tham Kwai Fong",
  //           //     sector: [
  //           //       "CR C (Thu) Tham",
  //           //       "CR D (Thu) Tham",
  //           //       "CR A (Thu) Tham",
  //           //       "CR B (Thu) Tham",
  //           //     ],
  //           //   },
  //           //   {
  //           //     userId: "LVtwnQo0jjgW609umR4aaOlHkCf1",
  //           //     userName: "Jason Lee",
  //           //     sector: ["YS AD (Thu) Jason", "YS AC (Thu) Jason"],
  //           //   },
  //           //   {
  //           //     userId: "JN8hc8K6MoT7EjbXo2tCa0sj7Ut2",
  //           //     userName: "Dwight Aguirre",
  //           //     sector: ["YS S (Thu) Dwight", "YS Q (Thu) Dwight"],
  //           //   },
  //           //   {
  //           //     userId: "ANeZVGnZFXeExCtEhpBccOY6Yc63",
  //           //     userName: "CK Hoe",
  //           //     sector: ["YS AS (Thu) CK", "YS T (Thu) CK"],
  //           //   },
  //           //   {
  //           //     userId: "8HmMgBjk9pTVwHDlAH7SHF1E3k63",
  //           //     userName: "Marzuki Noordiana",
  //           //     sector: ["YS E (Thu) Marzuki", "YS F (Thu) Marzuki"],
  //           //   },
  //           //   {
  //           //     userId: "FLWtx58snvMLeJH3GWmgxFXpkpp2",
  //           //     userName: "tengjing tan",
  //           //     sector: [
  //           //       "YS AH (Thu) tengjing",
  //           //       "YS AF (Thu) tengjing",
  //           //       "YS AG (Thu) tengjing",
  //           //     ],
  //           //   },
  //           //   {
  //           //     userId: "1wk70FHky9O8gZS1vsWtksu8CN73",
  //           //     userName: "Wee Meng Sin",
  //           //     sector: [
  //           //       "YS AR (Thu) Wee Meng",
  //           //       "YS J (Thu) Wee Meng",
  //           //       "YS AI (Thu) Wee Meng",
  //           //       "YS AP (Thu) Wee Meng",
  //           //     ],
  //           //   },
  //           //   {
  //           //     userId: "CteNo5iXzsTBnysbfmH1e3U77ex1",
  //           //     userName: "Lester Tan",
  //           //     sector: [
  //           //       "YS O (Thu) Lester",
  //           //       "YS P (Thu) Lester",
  //           //       "YS AK (Thu) Lester",
  //           //     ],
  //           //   },
  //           //   {
  //           //     userId: "jVkRRPAJsfNFLNK4O0N7nPu3AwZ2",
  //           //     userName: "Chee Keong Pek",
  //           //     sector: ["YS R (Thu) Chee Keong", "YS AT (Thu) Chee Keong"],
  //           //   },
  //           // ];
  //           // const driver = [
  //           //   "9yo3kV69VSXzcZvzrL33ttactCZ2",
  //           //   "IlYwyF7Vx8d4eyQg7s2m9v3npdJ3",
  //           //   "43FTAD0JiCOACwTayuYcEPHQbCp1",
  //           //   "3E8epJgljiYTpaM0gxuBQngV0u82",
  //           //   "bWHHqBM5WbO76AQK44B6WWuV36H2",
  //           //   "wS2H9AqgoAay0jTt4lQ2GqiTxRF2",
  //           //   "1qRaURuqt5hFXUVBoeU1aMmMRzJ3",
  //           //   "Mg7MquHYD4RmOicPEI5lgzKivLz1",
  //           //   "ip9CVyVP5YZMbx2rWwNuVG2N1yk2",
  //           //   "JXJlcFISHRSAKAMVRHuw6qBPU602",
  //           //   "UgvulBQkmqgm0tOTZwMbZDEF4Y82",
  //           //   "HN634tjfuWZRUdf36EJS1eYTiLg2",
  //           // ];

  //           // const groupedUser = grouped.filter(
  //           //   (b) => !driver.includes(b.userId)
  //           // );

  //           // const groupedDriver = grouped
  //           //   .filter((b) => driver.includes(b.userId))
  //           //   .map((v) => {
  //           //     let fixDriver = {};
  //           //     const fixUser = groupedUser.filter((c) =>
  //           //       v.sector.some((ai) => c.sector.includes(ai))
  //           //     );
  //           //     fixDriver["userId"] = v.userId;
  //           //     fixDriver["userName"] = v.userName;
  //           //     fixDriver["sectors"] = fixUser;
  //           //     return fixDriver;
  //           //   });

  //           // console.log(groupedDriver);
  //           // console.log(fixDriver);
  //           // console.log(groupedUser);

  //           this.setState({ uiLoading: true });
  //           const authToken = localStorage.getItem("AuthToken");
  //           authMiddleWare(this.props.history);
  //           if (authToken == null) {
  //             alert("Your session is expired, please login to retry.");
  //             this.setState({ uiLoading: false });
  //             this.props.setLogin(false);
  //           } else {
  //             axios.defaults.headers.common = { Authorization: `${authToken}` };
  //             //if confirmed, will change the status in collection
  //             await axios
  //               .get(`${baseURL}/createSectorGroup`)
  //               .then(async (response) => {
  //                 console.log(response);
  //                 this.setState({ uiLoading: false });
  //               })
  //               .catch((err) => {
  //                 console.log(err);
  //                 if (err.response.status === 403) {
  //                   alert("Your session is expired, please login to retry.");
  //                   this.setState({ uiLoading: false });
  //                   this.props.setLogin(false);
  //                 }
  //               });
  //           }
  //         },
  //       },
  //       {
  //         label: "Cancel",
  //         onClick: () => onclose,
  //       },
  //     ],
  //   });
  // };

  //manually reset pickupRequest
  resetPickupRequest = () => {
    confirmAlert({
      title: "Confirm to reset all the request on pickupRequest?",
      message: "Make sure it is not in the middle of release time.",
      buttons: [
        {
          label: "Reset",
          onClick: async () => {
            this.setState({ uiLoading: true });
            const authToken = localStorage.getItem("AuthToken");
            authMiddleWare(this.props.history);
            if (authToken == null) {
              alert("Your session is expired, please login to retry.");
              this.setState({ uiLoading: false });
              this.props.setLogin(false);
            } else {
              axios.defaults.headers.common = { Authorization: `${authToken}` };
              //if confirmed, will change the status in collection
              await axios
                .get(`${baseURL}/resetPickupRequest`)
                .then(async (response) => {
                  console.log(response);
                  this.setState({ uiLoading: false });
                })
                .catch((err) => {
                  console.log(err);
                  if (err.response.status === 403) {
                    alert("Your session is expired, please login to retry.");
                    this.setState({ uiLoading: false });
                    this.props.setLogin(false);
                  }
                });
            }
          },
        },
        {
          label: "Cancel",
          onClick: () => onclose,
        },
      ],
    });
  };

  //manually set status to pending
  resetCounterContainer = () => {
    confirmAlert({
      title: "Confirm to reset all the status on counterContainer?",
      message: "Make sure it is not in the middle of release time.",
      buttons: [
        {
          label: "Reset",
          onClick: async () => {
            this.setState({ uiLoading: true });
            const authToken = localStorage.getItem("AuthToken");
            authMiddleWare(this.props.history);
            if (authToken == null) {
              alert("Your session is expired, please login to retry.");
              this.setState({ uiLoading: false });
              this.props.setLogin(false);
            } else {
              axios.defaults.headers.common = { Authorization: `${authToken}` };
              //if confirmed, will change the status in collection
              await axios
                .get(`${baseURL}/resetBulkCounterContainer`)
                .then(async (response) => {
                  console.log(response);
                  this.setState({ uiLoading: false });
                })
                .catch((err) => {
                  console.log(err);
                  if (err.response.status === 403) {
                    alert("Your session is expired, please login to retry.");
                    this.setState({ uiLoading: false });
                    this.props.setLogin(false);
                  }
                });
            }
          },
        },
        {
          label: "Cancel",
          onClick: () => onclose,
        },
      ],
    });
  };

  //manually set status to pending
  resetLaunchBlock = () => {
    confirmAlert({
      title: "Confirm to reset all the status on launchBlock?",
      message: "Make sure it is not in the middle of release time.",
      buttons: [
        {
          label: "Reset",
          onClick: async () => {
            this.setState({ uiLoading: true });
            const authToken = localStorage.getItem("AuthToken");
            authMiddleWare(this.props.history);
            if (authToken == null) {
              alert("Your session is expired, please login to retry.");
              this.setState({ uiLoading: false });
              this.props.setLogin(false);
            } else {
              axios.defaults.headers.common = { Authorization: `${authToken}` };
              //if confirmed, will change the status in collection
              await axios
                .get(`${baseURL}/resetBulkLaunchBlock`)
                .then(async (response) => {
                  console.log(response);
                  this.setState({ uiLoading: false });
                })
                .catch((err) => {
                  console.log(err);
                  if (err.response.status === 403) {
                    alert("Your session is expired, please login to retry.");
                    this.setState({ uiLoading: false });
                    this.props.setLogin(false);
                  }
                });
            }
          },
        },
        {
          label: "Cancel",
          onClick: () => onclose,
        },
      ],
    });
  };

  //manually set status to pending
  resetLaunchPoint = () => {
    confirmAlert({
      title: "Confirm to reset all the status on launchPoint?",
      message: "Make sure it is not in the middle of release time.",
      buttons: [
        {
          label: "Reset",
          onClick: async () => {
            this.setState({ uiLoading: true });
            const authToken = localStorage.getItem("AuthToken");
            authMiddleWare(this.props.history);
            if (authToken == null) {
              alert("Your session is expired, please login to retry.");
              this.setState({ uiLoading: false });
              this.props.setLogin(false);
            } else {
              axios.defaults.headers.common = { Authorization: `${authToken}` };
              //if confirmed, will change the status in collection
              await axios
                .get(`${baseURL}/resetBulkLaunchPoint`)
                .then(async (response) => {
                  console.log(response);
                  this.setState({ uiLoading: false });
                })
                .catch((err) => {
                  console.log(err);
                  if (err.response.status === 403) {
                    alert("Your session is expired, please login to retry.");
                    this.setState({ uiLoading: false });
                    this.props.setLogin(false);
                  }
                });
            }
          },
        },
        {
          label: "Cancel",
          onClick: () => onclose,
        },
      ],
    });
  };

  //save data to db
  handleSubmit = async () => {
    console.log(this.state.apkVersion.name);
    console.log(this.state.newApkVersion);
    const authToken = localStorage.getItem("AuthToken");
    if (this.state.newScanTimer !== this.state.scanTimer.value) {
      this.setState({ uiLoading: true });
      const options = {
        url: `${baseURL}/editSetting/${this.state.scanTimer.id}`,
        method: "put",
        data: {
          name: this.state.scanTimer.name,
          value: this.state.newScanTimer,
        },
      };
      if (authToken == null) {
        alert("Your session is expired, please login to retry.");
        this.setState({ uiLoading: false });
        this.props.setLogin(false);
      } else {
        axios.defaults.headers.common = { Authorization: `${authToken}` };
        //send the update
        await axios(options)
          .then(() => {
            this.setState({
              uiLoading: false,
            });
            // window.location.reload();
          })
          .catch((error) => {
            if (error.response.status === 403) {
              alert("Your session is expired, please login to retry.");
              this.setState({ uiLoading: false });
              this.props.setLogin(false);
            }
            this.setState({
              open: true,
              uiLoading: false,
              errors: error.response.data,
            });
            console.log(error);
          });
      }
    }
    if (this.state.newLaunchTimer !== this.state.launchTimer.value) {
      this.setState({ uiLoading: true });
      const options = {
        url: `${baseURL}/editSetting/${this.state.launchTimer.id}`,
        method: "put",
        data: {
          name: this.state.launchTimer.name,
          value: this.state.newLaunchTimer,
        },
      };
      if (authToken == null) {
        alert("Your session is expired, please login to retry.");
        this.setState({ uiLoading: false });
        this.props.setLogin(false);
      } else {
        axios.defaults.headers.common = { Authorization: `${authToken}` };
        //send the update
        await axios(options)
          .then(() => {
            this.setState({
              uiLoading: false,
            });
            // window.location.reload();
          })
          .catch((error) => {
            if (error.response.status === 403) {
              alert("Your session is expired, please login to retry.");
              this.setState({ uiLoading: false });
              this.props.setLogin(false);
            }
            this.setState({
              open: true,
              uiLoading: false,
              errors: error.response.data,
            });
            console.log(error);
          });
      }
    }
    if (this.state.newWeek !== this.state.week.value) {
      this.setState({ uiLoading: true });
      const options = {
        url: `${baseURL}/editSetting/${this.state.week.id}`,
        method: "put",
        data: {
          name: this.state.week.name,
          value: this.state.newWeek,
        },
      };
      if (authToken == null) {
        alert("Your session is expired, please login to retry.");
        this.setState({ uiLoading: false });
        this.props.setLogin(false);
      } else {
        axios.defaults.headers.common = { Authorization: `${authToken}` };
        //send the update
        await axios(options)
          .then(() => {
            this.setState({
              uiLoading: false,
            });
            // window.location.reload();
          })
          .catch((error) => {
            if (error.response.status === 403) {
              alert("Your session is expired, please login to retry.");
              this.setState({ uiLoading: false });
              this.props.setLogin(false);
            }
            this.setState({
              open: true,
              uiLoading: false,
              errors: error.response.data,
            });
            console.log(error);
          });
      }
    }
    if (this.state.newRadius !== this.state.radius.value) {
      this.setState({ uiLoading: true });
      const options = {
        url: `${baseURL}/editSetting/${this.state.radius.id}`,
        method: "put",
        data: {
          name: this.state.radius.name,
          value: this.state.newRadius,
        },
      };
      if (authToken == null) {
        alert("Your session is expired, please login to retry.");
        this.setState({ uiLoading: false });
        this.props.setLogin(false);
      } else {
        axios.defaults.headers.common = { Authorization: `${authToken}` };
        //send the update
        await axios(options)
          .then(() => {
            this.setState({
              uiLoading: false,
            });
            // window.location.reload();
          })
          .catch((error) => {
            if (error.response.status === 403) {
              alert("Your session is expired, please login to retry.");
              this.setState({ uiLoading: false });
              this.props.setLogin(false);
            }
            this.setState({
              open: true,
              uiLoading: false,
              errors: error.response.data,
            });
            console.log(error);
          });
      }
    }
    if (this.state.newCrateInfo !== this.state.crateInfo.value) {
      this.setState({ uiLoading: true });
      const options = {
        url: `${baseURL}/editSetting/${this.state.crateInfo.id}`,
        method: "put",
        data: {
          name: this.state.crateInfo.name,
          value: this.state.newCrateInfo,
        },
      };
      if (authToken == null) {
        alert("Your session is expired, please login to retry.");
        this.setState({ uiLoading: false });
        this.props.setLogin(false);
      } else {
        axios.defaults.headers.common = { Authorization: `${authToken}` };
        //send the update
        await axios(options)
          .then(() => {
            this.setState({
              uiLoading: false,
            });
            // window.location.reload();
          })
          .catch((error) => {
            if (error.response.status === 403) {
              alert("Your session is expired, please login to retry.");
              this.setState({ uiLoading: false });
              this.props.setLogin(false);
            }
            this.setState({
              open: true,
              uiLoading: false,
              errors: error.response.data,
            });
            console.log(error);
          });
      }
    }
    if (this.state.newApkVersion !== this.state.apkVersion.value) {
      this.setState({ uiLoading: true });
      const options = {
        url: `${baseURL}/editSetting/${this.state.apkVersion.id}`,
        method: "put",
        data: {
          name: this.state.apkVersion.id,
          value: this.state.newApkVersion,
        },
      };
      if (authToken == null) {
        alert("Your session is expired, please login to retry.");
        this.setState({ uiLoading: false });
        this.props.setLogin(false);
      } else {
        axios.defaults.headers.common = { Authorization: `${authToken}` };
        //send the update
        await axios(options)
          .then(() => {
            this.setState({
              uiLoading: false,
            });
            // window.location.reload();
          })
          .catch((error) => {
            if (error.response.status === 403) {
              alert("Your session is expired, please login to retry.");
              this.setState({ uiLoading: false });
              this.props.setLogin(false);
            }
            this.setState({
              open: true,
              uiLoading: false,
              errors: error.response.data,
            });
            console.log(error);
          });
      }
    }
  };

  //render function
  render() {
    const { classes } = this.props;
    const {
      scanTimer,
      launchTimer,
      week,
      newScanTimer,
      newLaunchTimer,
      newWeek,
      radius,
      newRadius,
      crateInfo,
      newCrateInfo,
      apkVersion,
      newApkVersion,
      // testData,
    } = this.state;

    //loading UI
    if (this.state.uiLoading === true) {
      return (
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {this.state.uiLoading && (
            <CircularProgress size={150} className={classes.uiProgess} />
          )}
        </main>
      );
    } else {
      //if not loading, return actual UI and dialogbox
      return (
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Card className={clsx(classes.root, classes)}>
            <CardContent>
              <div className={classes.details}>
                <div>
                  <Typography
                    className={classes.locationText}
                    gutterBottom
                    variant="h4"
                  >
                    General Setting
                  </Typography>
                </div>
              </div>
              <div className={classes.progress} />
            </CardContent>
            <Divider />
          </Card>

          <br />
          <Card className={clsx(classes.root, classes)}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8} style={{ alignSelf: "center" }}>
                  <Typography variant="h6">
                    Time between NFC and QRCode Scan
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    onChange={this.handleChange}
                    id="scanner"
                    label="Second"
                    name="newScanTimer"
                    autoComplete="scanner"
                    type="number"
                    defaultValue={scanTimer?.value}
                    value={newScanTimer}
                    color="primary"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8} style={{ alignSelf: "center" }}>
                  <Typography variant="h6">
                    Time between Launch Point
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    onChange={this.handleChange}
                    id="launcher"
                    label="Second"
                    name="newLaunchTimer"
                    autoComplete="launcher"
                    type="number"
                    defaultValue={launchTimer?.value}
                    value={newLaunchTimer}
                    color="primary"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8} style={{ alignSelf: "center" }}>
                  <Typography variant="h6">E-week</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    onChange={this.handleChange}
                    id="week"
                    label="Date"
                    name="newWeek"
                    autoComplete="week"
                    type="date"
                    defaultValue={week?.value}
                    value={newWeek}
                    color="primary"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8} style={{ alignSelf: "center" }}>
                  <Typography variant="h6">Block Radius in KM</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    onChange={this.handleChange}
                    id="radius"
                    label="Block Radius in KM"
                    name="newRadius"
                    autoComplete="radius"
                    type="number"
                    defaultValue={radius?.value}
                    value={newRadius}
                    color="primary"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8} style={{ alignSelf: "center" }}>
                  <Typography variant="h6">Enable Photo Taking</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="crateInfo-label">
                      Enable Photo Taking
                    </InputLabel>
                    <Select
                      labelId="crateInfo-label"
                      id="crateInfo"
                      label="Enable Photo Taking"
                      name="newCrateInfo"
                      defaultValue={crateInfo?.value}
                      value={newCrateInfo}
                      onChange={this.handleChange}
                    >
                      <MenuItem value={1}>Enable</MenuItem>
                      <MenuItem value={0}>Disable</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8} style={{ alignSelf: "center" }}>
                  <Typography variant="h6">APK Version</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    onChange={this.handleChange}
                    id="apkVersion"
                    label="APK Version"
                    name="newApkVersion"
                    autoComplete="apkVersion"
                    type="text"
                    defaultValue={apkVersion?.value}
                    value={newApkVersion}
                    color="primary"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Button
            color="primary"
            variant="contained"
            className={classes.addButton}
            onClick={() => this.handleSubmit()}
          >
            Save Setting
          </Button>

          <br />
          <br />
          <br />
          <Card className={clsx(classes.root, classes)}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} style={{ alignSelf: "center" }}>
                  <Typography variant="h6">Manual Reset Status</Typography>
                </Grid>
                {/* <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.addButton}
                    onClick={() => this.resetSectorName()}
                  >
                    test api
                  </Button>
                </Grid> */}
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.addButton}
                    onClick={() => this.resetPickupRequest()}
                  >
                    Reset pickupRequest
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.addButton}
                    onClick={() => this.resetCounterContainer()}
                  >
                    Reset Status containerCounter
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.addButton}
                    onClick={() => this.resetLaunchBlock()}
                  >
                    Reset Status launchBlock
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.addButton}
                    onClick={() => this.resetLaunchPoint()}
                  >
                    Reset Status launchPoint
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </main>
      );
    }
  }
}

//styles
const styles = (theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  root: { width: "800px" },
  details: {
    display: "flex",
  },
  locationText: {
    paddingLeft: "15px",
  },
  uiProgess: {
    position: "fixed",
    zIndex: "1000",
    height: "31px",
    width: "31px",
    left: "50%",
    top: "35%",
  },
  progess: {
    position: "absolute",
  },
  assignText: {
    textAlign: "left",
  },
  assignButton: {
    position: "absolute",
    right: "0",
  },
  addButton: {
    marginTop: "10px",
  },
  deleteButton: {
    marginTop: "10px",
    marginLeft: "10px",
  },
  dialogeStyle: {
    maxWidth: "50%",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

export default withStyles(styles)(setting);
