/*/////////////////////////////////////////////////////////////////
//    FileName: home.js
//    Author: Ho Da Zong
//    Created On: 7-May-2021
//    Last Modified On: 15-Oct-2021
//    Copy Rights: Orinno Technology Pte Ltd
//    Description: home page after login success, 
//                 including drawer rendered.
//    Changes Log: 18-May-2021 > Remove data retrieve unnecessary
//				   21-May-2021 > Add point component link
*/ /////////////////////////////////////////////////////////////////

import React, { Component } from "react";
import axios from "axios";

import Bulk from "../components/bulkWrite";
import Damage from "../components/damage";
import Release from "../components/release";
import ReleasePhoto from "../components/releasePhoto";
import CratePhoto from "../components/cratePhoto";
import Setting from "../components/setting";
import SectorTime from "../components/sectorTime";
import DefaultAssign from "../components/defaultAssign";
import SupervisorAssign from "../components/supervisorAssign.js";
import Account from "../components/account";
import Site from "../components/site";
import Sector from "../components/sector";
import Block from "../components/blocks";
import RouteTrack from "../components/routeTrack";

import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import Collapse from "@material-ui/core/Collapse";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import withStyles from "@material-ui/core/styles/withStyles";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import SettingsIcon from "@material-ui/icons/Settings";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import Apartment from "@material-ui/icons/Apartment";
import Dot from "@material-ui/icons/DragIndicator";
import Description from "@material-ui/icons/Description";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import BrokenImage from "@material-ui/icons/BrokenImage";
import Language from "@material-ui/icons/Language";
import Avatar from "@material-ui/core/Avatar";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import NotificationsIcon from "@material-ui/icons/Notifications";
import logo from "../images/logo-orinno-technology.png";
import Login from "./login";

import { authMiddleWare } from "../util/auth";
import { baseURL } from "../util/port.js";

//set the left drawer width(with page navigation tab)
const drawerWidth = 240;

//set the font color to white when called this component
const WhiteTextTypography = withStyles({
  root: {
    color: "#000000",
  },
})(Typography);

//the home page component, call the js component name after import, will return content inside this component
class home extends Component {
  //state to control variable changes, dif with variable bcause any of the state value change will reload the page.
  state = {
    render: "site",
    param: "",
    isLoggedIn: null,
    settingOpen: false,
    photoOpen: false,
  };

  //state change to render the setting page beside the drawer but within the hompage
  loadBulkPage = (event) => {
    this.setState({ render: "bulk" });
  };

  //state change to render the setting page beside the drawer but within the hompage
  loadDamagePage = (event) => {
    this.setState({ render: "damage" });
  };

  //state change to render the setting page beside the drawer but within the hompage
  loadReleasePage = (event) => {
    this.setState({ render: "release" });
  };

  //state change to render the setting page beside the drawer but within the hompage
  loadReleasePhotoPage = (event) => {
    this.setState({ render: "releasePhoto" });
  };

  //state change to render the setting page beside the drawer but within the hompage
  loadCratePhotoPage = (event) => {
    this.setState({ render: "cratePhoto" });
  };

  //state change to render the sectorTime page beside the drawer but within the hompage
  loadSectorTimePage = (event) => {
    this.setState({ render: "sectorTime" });
  };

  //state change to render the defaultAssign page beside the drawer but within the hompage
  loadDefaultAssignPage = (event) => {
    this.setState({ render: "defaultAssign" });
  };

  //state change to render the supervisorAssign page beside the drawer but within the hompage
  loadSupervisorAssignPage = (event) => {
    this.setState({ render: "supervisorAssign" });
  };

  //state change to render the setting page beside the drawer but within the hompage
  loadSettingPage = (event) => {
    this.setState({ render: "setting" });
  };

  //state change to render the account page beside the drawer but within the hompage
  loadAccountPage = (event) => {
    this.setState({ render: "account" });
  };

  //state change to render the sector page beside the drawer but within the hompage
  loadSectorPage = (event) => {
    this.setState({ render: "sector" });
  };

  //state change to render the site page beside the drawer but within the hompage
  loadSitePage = (event) => {
    this.setState({ render: "site" });
  };

  //state change to render the route track page beside the drawer but within the hompage
  loadRouteTrackPage = (event) => {
    this.setState({ render: "routeTrack" });
  };

  //logout and route to login page
  logoutHandler = (event) => {
    localStorage.removeItem("AuthToken");
    this.setState({ isLoggedIn: false, render: "sector" });
  };

  //props and state
  constructor(props) {
    super(props);
    this.setRender = this.setRender.bind(this);
    this.setLogin = this.setLogin.bind(this);
    this.state = {
      firstName: "",
      lastName: "",
      profilePicture: "",
      uiLoading: true,
      isLoggedIn: null,
      issues: [],
      popOverTrigger: null,
    };
  }

  //will run once everytime the page render
  shouldComponentUpdate = async (nextProps, nextState) => {
    if (!this.state.isLoggedIn && nextState.isLoggedIn) {
      //retrieve authToken and perform axios fetch API with async and await
      authMiddleWare(this.props.history);
      const authToken = localStorage.getItem("AuthToken");
      if (authToken === null) {
        alert("Your session is expired, please login to retry.");
        this.setState({ isLoggedIn: false, uiLoading: false });
      } else {
        axios.defaults.headers.common = { Authorization: `${authToken}` };
        //function written in functionAPI.js to get user details
        await axios
          .get(`${baseURL}/user`)
          .then((response) => {
            localStorage.setItem(
              "adminName",
              `${response.data.userCredentials.firstName} ${response.data.userCredentials.lastName}`
            );
            //assign the state value after getting back response value
            this.setState({
              firstName: response.data.userCredentials.firstName,
              lastName: response.data.userCredentials.lastName,
              email: response.data.userCredentials.email,
              uiLoading: false,
              profilePicture: response.data.userCredentials.imageUrl,
              isLoggedIn: true,
            });
          })
          .catch((error) => {
            console.log(error);
            //error handle
            if (error.response.status === 403) {
              alert("Your session is expired, please login to retry.");
              this.setState({ isLoggedIn: false, uiLoading: false });
              return;
            }
          });
      }
      return true;
    }
  };

  componentDidMount = async () => {
    //retrieve authToken and perform axios fetch API with async and await
    authMiddleWare(this.props.history);
    const authToken = localStorage.getItem("AuthToken");
    if (authToken === null) {
      alert("Your session is expired, please login to retry.");
      this.setState({ isLoggedIn: false, uiLoading: false });
    } else {
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      //function written in functionAPI.js to get user details
      await axios
        .get(`${baseURL}/user`)
        .then((response) => {
          localStorage.setItem(
            "adminName",
            `${response.data.userCredentials.firstName} ${response.data.userCredentials.lastName}`
          );
          //assign the state value after getting back response value
          this.setState({
            firstName: response.data.userCredentials.firstName,
            lastName: response.data.userCredentials.lastName,
            email: response.data.userCredentials.email,
            // uiLoading: false,
            profilePicture: response.data.userCredentials.imageUrl,
            isLoggedIn: true,
          });
        })
        .catch((error) => {
          console.log(error);
          //error handle
          if (error?.response?.status === 403) {
            alert("Your session is expired, please login to retry.");
            this.setState({ isLoggedIn: false, uiLoading: false });
            return;
          }
        });
    }

    //retrieve the issue data from db
    authMiddleWare(this.props.history);
    if (authToken == null) {
      alert("Your session is expired, please login to retry.");
      this.setState({ isLoggedIn: false, uiLoading: false });
    } else {
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      await axios
        .get(`${baseURL}/getIssueLimit`)
        .then((response) => {
          this.setState({
            issues: response.data,
            uiLoading: false,
          });
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 403) {
            alert("Your session is expired, please login to retry.");
            this.setState({ isLoggedIn: false, uiLoading: false });
          }
        });
    }
    //render preload sector once page start, will get data from the API call to grab data from firestore
    this.setState({ render: "sector" });
  };

  // Function to set the parent's state from child, child can call this function and set the value, so that the parent page will get same changes and response to the state change
  setRender = (text, param) => {
    this.setState({ render: text });
    this.setState({ param: param });
  };

  handleSetting = () => {
    this.setState({ settingOpen: !this.state.settingOpen });
  };

  handlePhoto = () => {
    this.setState({ photoOpen: !this.state.photoOpen });
  };

  // Function to set the parent's state from child, child can call this function and set the value, so that the parent page will get same changes and response to the state change
  setLogin = (isLoggedIn) => {
    this.setState({ isLoggedIn: isLoggedIn });
  };

  render() {
    //click the notification icon and trigger the pop out window
    const handleClickNotification = (event) => {
      this.setState({
        popOverTrigger: event.currentTarget,
      });
    };

    //trigger event to close the notification popover
    const handleCloseNotification = () => {
      this.setState({
        popOverTrigger: null,
      });
    };

    //to control the popover open or close
    const openPop = Boolean(this.state.popOverTrigger);
    //assign ID to the popover
    const id = openPop ? "popover-notification" : undefined;

    const { classes } = this.props;
    //to control the child DIV page, will change the page according to the state value to display dif child page
    let pageComponent;
    if (this.state.render === "sector") {
      pageComponent = (
        <Sector
          setRender={this.setRender}
          history={this.props.history}
          setLogin={this.setLogin}
        />
      );
    } else if (this.state.render === "site") {
      pageComponent = (
        <Site history={this.props.history} setLogin={this.setLogin} />
      );
    } else if (this.state.render === "damage") {
      pageComponent = (
        <Damage history={this.props.history} setLogin={this.setLogin} />
      );
    } else if (this.state.render === "release") {
      pageComponent = (
        <Release history={this.props.history} setLogin={this.setLogin} />
      );
    } else if (this.state.render === "releasePhoto") {
      pageComponent = (
        <ReleasePhoto history={this.props.history} setLogin={this.setLogin} />
      );
    } else if (this.state.render === "cratePhoto") {
      pageComponent = (
        <CratePhoto history={this.props.history} setLogin={this.setLogin} />
      );
    } else if (this.state.render === "setting") {
      pageComponent = (
        <Setting history={this.props.history} setLogin={this.setLogin} />
      );
    } else if (this.state.render === "sectorTime") {
      pageComponent = (
        <SectorTime history={this.props.history} setLogin={this.setLogin} />
      );
    } else if (this.state.render === "defaultAssign") {
      pageComponent = (
        <DefaultAssign history={this.props.history} setLogin={this.setLogin} />
      );
    } else if (this.state.render === "supervisorAssign") {
      pageComponent = (
        <SupervisorAssign
          history={this.props.history}
          setLogin={this.setLogin}
        />
      );
    } else if (this.state.render === "account") {
      pageComponent = (
        <Account history={this.props.history} setLogin={this.setLogin} />
      );
    } else if (this.state.render === "block") {
      pageComponent = (
        <Block
          site={this.state.param}
          setRender={this.setRender}
          history={this.props.history}
          setLogin={this.setLogin}
        />
      );
    } else if (this.state.render === "routeTrack") {
      pageComponent = (
        <RouteTrack
          block={this.state.param}
          setRender={this.setRender}
          history={this.props.history}
          setLogin={this.setLogin}
        />
      );
    } else if (this.state.render === "bulk") {
      pageComponent = (
        <Bulk history={this.props.history} setLogin={this.setLogin} />
      );
    }
    //loading UI spinner
    if (this.state.uiLoading === true) {
      return (
        <div className={classes.root}>
          {this.state.uiLoading && (
            <CircularProgress size={150} className={classes.uiProgess} />
          )}
        </div>
      );
    } else if (this.state.isLoggedIn === true) {
      //actual ui displayed
      return (
        <div className={classes.root}>
          <CssBaseline />
          <AppBar color="default" position="fixed" className={classes.appBar}>
            <Toolbar>
              <WhiteTextTypography variant="h6" noWrap>
                Dispatch Admin Portal
              </WhiteTextTypography>
              <div style={{ marginLeft: "auto" }}>
                <img src={logo} alt="logo" className={classes.logo} />
              </div>
            </Toolbar>
          </AppBar>
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.toolbar} />
            <Divider />
            <center>
              <Avatar
                src={this.state.profilePicture}
                className={classes.avatar}
              />
              <p>
                {" "}
                {this.state.firstName} {this.state.lastName}
              </p>
            </center>
            <Divider />
            <List>
              <ListItem button key="Manage Site" onClick={this.loadSitePage}>
                <ListItemIcon>
                  {" "}
                  <Language />{" "}
                </ListItemIcon>
                <ListItemText primary="Manage Site" />
              </ListItem>

              <ListItem
                button
                key="Manage Release Block"
                onClick={this.loadSectorPage}
              >
                <ListItemIcon>
                  {" "}
                  <Apartment />{" "}
                </ListItemIcon>
                <ListItemText primary="Manage Release Block" />
              </ListItem>

              <ListItem
                button
                key="Release Route Tracking"
                onClick={this.loadRouteTrackPage}
              >
                <ListItemIcon>
                  {" "}
                  <Dot />{" "}
                </ListItemIcon>
                <ListItemText primary="Release Route Tracking" />
              </ListItem>

              <ListItem
                button
                key="Damage Records"
                onClick={this.loadDamagePage}
              >
                <ListItemIcon>
                  {" "}
                  <BrokenImage />{" "}
                </ListItemIcon>
                <ListItemText primary="Damage Records" />
              </ListItem>

              <ListItem
                button
                key="Release Records"
                onClick={this.loadReleasePage}
              >
                <ListItemIcon>
                  {" "}
                  <Description />{" "}
                </ListItemIcon>
                <ListItemText primary="Release Records" />
              </ListItem>

              <ListItem button key="Photo Records" onClick={this.handlePhoto}>
                <ListItemIcon>
                  {" "}
                  <PhotoCamera />{" "}
                </ListItemIcon>
                <ListItemText primary="Photo Records" />
                {this.state.photoOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={this.state.photoOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    button
                    className={classes.nested}
                    key="Release Point Photos"
                    onClick={this.loadReleasePhotoPage}
                  >
                    <ListItemIcon>
                      {" "}
                      <PhotoCamera />{" "}
                    </ListItemIcon>
                    <ListItemText primary="Release Point Photos" />
                  </ListItem>
                  <ListItem
                    button
                    className={classes.nested}
                    key="Crate ID Photos"
                    onClick={this.loadCratePhotoPage}
                  >
                    <ListItemIcon>
                      {" "}
                      <PhotoCamera />{" "}
                    </ListItemIcon>
                    <ListItemText primary="Crate ID Photos" />
                  </ListItem>
                </List>
              </Collapse>

              <ListItem
                button
                key="User Management"
                onClick={this.loadAccountPage}
              >
                <ListItemIcon>
                  {" "}
                  <AccountBoxIcon />{" "}
                </ListItemIcon>
                <ListItemText primary="User Management" />
              </ListItem>

              <ListItem button key="Bulk" onClick={this.loadBulkPage}>
                <ListItemIcon>
                  {" "}
                  <AddToPhotosIcon />{" "}
                </ListItemIcon>
                <ListItemText primary="Bulk" />
              </ListItem>

              <ListItem button onClick={this.handleSetting}>
                <ListItemIcon>
                  {" "}
                  <SettingsIcon />{" "}
                </ListItemIcon>
                <ListItemText primary="Setting" />
                {this.state.settingOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={this.state.settingOpen}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  <ListItem
                    button
                    className={classes.nested}
                    key="General Setting"
                    onClick={this.loadSettingPage}
                  >
                    <ListItemIcon>
                      {" "}
                      <SettingsIcon />{" "}
                    </ListItemIcon>
                    <ListItemText primary="General Setting" />
                  </ListItem>
                  <ListItem
                    button
                    className={classes.nested}
                    key="Set Sector Start Time"
                    onClick={this.loadSectorTimePage}
                  >
                    <ListItemIcon>
                      {" "}
                      <SettingsIcon />{" "}
                    </ListItemIcon>
                    <ListItemText primary="Set Sector Start Time" />
                  </ListItem>
                  <ListItem
                    button
                    className={classes.nested}
                    key="Set Default Assignment User"
                    onClick={this.loadDefaultAssignPage}
                  >
                    <ListItemIcon>
                      {" "}
                      <SettingsIcon />{" "}
                    </ListItemIcon>
                    <ListItemText primary="Set Default Assignment User" />
                  </ListItem>
                  <ListItem
                    button
                    className={classes.nested}
                    key="Set Supervisor User"
                    onClick={this.loadSupervisorAssignPage}
                  >
                    <ListItemIcon>
                      {" "}
                      <SettingsIcon />{" "}
                    </ListItemIcon>
                    <ListItemText primary="Set Supervisor User" />
                  </ListItem>
                </List>
              </Collapse>

              <ListItem button key="Logout" onClick={this.logoutHandler}>
                <ListItemIcon>
                  {" "}
                  <ExitToAppIcon />{" "}
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItem>
            </List>
          </Drawer>

          {
            //the notification
          }
          <IconButton
            className={classes.floatingButtonPop}
            color="primary"
            aria-label="Notification"
            onClick={handleClickNotification}
          >
            <NotificationsIcon style={{ fontSize: 60 }} />
          </IconButton>

          <Popover
            id={id}
            open={openPop}
            anchorEl={this.state.popOverTrigger}
            onClose={handleCloseNotification}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div className={classes.notifContainer}>
              {this.state.issues.map((e, idx) => (
                <div key={idx} className={classes.notifItem}>
                  <Typography sx={{ p: 2 }}>Issue {idx + 1}</Typography>
                  <Typography sx={{ p: 2 }}>
                    Submitted: {e.firstName}
                  </Typography>
                  <Typography sx={{ p: 2 }}>Type: {e.type}</Typography>
                  <Typography sx={{ p: 2 }}>
                    Date: {new Date(e.date.seconds * 1000).toLocaleString()}
                  </Typography>
                  <Typography sx={{ p: 2 }}>
                    Status:{" "}
                    {e.proofImg === ""
                      ? "Reported"
                      : e?.approveName
                      ? "Resolved"
                      : "Proof Submitted"}
                  </Typography>
                  <Typography sx={{ p: 2 }}>
                    Approved By: {e.approveName || "N.A"}
                  </Typography>
                </div>
              ))}
            </div>
          </Popover>

          <div>
            {
              //the component called will display here
              pageComponent
            }
          </div>
        </div>
      );
    } else {
      return <Login setLogin={this.setLogin} />;
    }
  }
}

//style sheet
const styles = (theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  avatar: {
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0,
    marginTop: 20,
  },
  uiProgess: {
    position: "fixed",
    zIndex: "1000",
    height: "31px",
    width: "31px",
    left: "50%",
    top: "35%",
  },
  toolbar: theme.mixins.toolbar,
  logo: {
    justifyContent: "flex-end",
    height: "56px",
  },
  floatingButtonPop: {
    position: "fixed",
    top: 70,
    right: 0,
  },
  notifContainer: {
    padding: 15,
    height: "500px",
    overflow: "auto",
  },
  notifItem: {
    paddingBottom: 10,
    marginBottom: 10,
    borderBottom: "1px solid #aaa",
  },
});

//must be export so other js code file can import and use it
export default withStyles(styles)(home);
