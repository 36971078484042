/*/////////////////////////////////////////////////////////////////
//    FileName: blocks.js
//    Author: Ho Da Zong
//    Created On: 21-June-2021
//    Last Modified On: 20-Oct-2021
//    Copy Rights: Orinno Technology Pte Ltd
//    Description: block DIV component, 
//                 will display at beside home page drawer if called.
*/ /////////////////////////////////////////////////////////////////

import React, { Component, Fragment } from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import CardContent from "@material-ui/core/CardContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import FiberManualRecordTwoToneIcon from "@material-ui/icons/FiberManualRecordTwoTone";
import { Badge } from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Modal from "@material-ui/core/Modal";
import CachedIcon from "@material-ui/icons/Cached";
import Divider from "@material-ui/core/Divider";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import { baseURL } from "../util/port.js";

import axios from "axios";

import { authMiddleWare } from "../util/auth";

//declare all the variable
var dataTable = [];
var ifBlockGotRecords;

//assign all the icon with color
const BlueFiberManualRecordTwoToneIcon = withStyles({
  root: {
    color: "#0b4ab0",
  },
})(FiberManualRecordTwoToneIcon);

const WhiteRadioButtonUncheckedIcon = withStyles({
  root: {
    color: "#000000",
  },
})(RadioButtonUncheckedIcon);

const GreyFiberManualRecordIcon = withStyles({
  root: {
    color: "#b5b0b0",
  },
})(FiberManualRecordIcon);

const OrangeFiberManualRecordIcon = withStyles({
  root: {
    color: "#d45608",
  },
})(FiberManualRecordIcon);

//the main component
class block extends Component {
  constructor(props) {
    super(props);

    //state variable
    this.state = {
      blocks: [],
      blockId: "",
      block: "",
      postal: "",
      remarks: "",
      uiLoading: true,
      open: false,
      errors: [],
      buttonType: "",
      groundRoute: "",
      upstairRoute: "",
      regionArea: "",
      isAvailable: true,
      meetingPoint: false,
      latitude: "",
      longitude: "",
      viewOpen: false,
      blockOld: "",
      sites: [],
      site: "",
      launchPointTypes: [],
      pointType: "",
      selectedDays: [],
      selectedDate: new Date(),
      groundSelectData: [],
      groundSelectedData: [],
      upstairSelectData: [],
      upstairSelectedData: [],
      blockStatus: [],
      editAddSite: "",
      openEditAddBlock: false,
      openDeletePoint: false,
      editAddBlock: "",
      blockSelectData: [],
      regionSelectData: [],
      editAddBlockPoint: "",
      levelSelectData: [],
      releasePointSelectData: [],
      altPointSelectData: [],
      level: "",
      releasePoint: "",
      altReleasePoint: "",
      isAvailablePoint: true,
      tableViewData: [],
      points: [],
      iValue: "",
      buttonEnable: true,
      // tag location
      openTag: false,
      openDeleteTag: false,
      tableViewDataTag: [],
      isEditTag: false,
      selectedTag: "",
      allLaunchPoint: [],
      blockTag: "",
      launchPointTag: "",
      latitudeTag: "",
      longitudeTag: "",
    };

    this.deleteBlock = this.deleteBlock.bind(this);
    this.handleViewOpen = this.handleViewOpen.bind(this);
    this.handleCloseEditBlock = this.handleCloseEditBlock.bind(this);
    this.handleEditBlock = this.handleEditBlock.bind(this);
  }

  //handle state change of the field inserted
  handleChangeNormalField = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  //handle all checkbox state change
  handleChangeCheck = (event) => {
    this.setState({
      [event.target.name]: event.target.checked,
    });
  };

  //always will trigger everytime page load or refresh
  componentDidMount = async () => {
    //retrieve all launchPoint data from db
    authMiddleWare(this.props.history);
    const authToken = localStorage.getItem("AuthToken");
    if (authToken == null) {
      alert("Your session is expired, please login to retry.");
      this.setState({ uiLoading: false });
      this.props.setLogin(false);
    } else {
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      const formRequest = {
        site: this.props.site,
      };
      await axios
        .post(`${baseURL}/allPoint`, formRequest)
        .then((response) => {
          this.setState({
            allLaunchPoint: response.data,
            //uiLoading: false
          });
        })
        .catch((err) => {
          if (err.response.status === 403) {
            alert("Your session is expired, please login to retry.");
            this.setState({ uiLoading: false });
            this.props.setLogin(false);
          }
          console.log(err);
        });
    }
    //retrieve the blocks data from db
    authMiddleWare(this.props.history);
    if (authToken == null) {
      alert("Your session is expired, please login to retry.");
      this.setState({ uiLoading: false });
      this.props.setLogin(false);
    } else {
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      const formRequest = {
        site: this.props.site,
      };
      await axios
        .post(`${baseURL}/block`, formRequest)
        .then((response) => {
          this.setState({
            blocks: response.data,
            //uiLoading: false
          });
        })
        .catch((err) => {
          if (err.response.status === 403) {
            alert("Your session is expired, please login to retry.");
            this.setState({ uiLoading: false });
            this.props.setLogin(false);
          }
          console.log(err);
        });
    }
    //retrieve the sector data from db
    authMiddleWare(this.props.history);
    if (authToken == null) {
      alert("Your session is expired, please login to retry.");
      this.setState({ uiLoading: false });
      this.props.setLogin(false);
    } else {
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      await axios
        .get(`${baseURL}/getSector`)
        .then((response) => {
          this.setState({
            sites: response.data,
            //uiLoading: false
          });
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 403) {
            alert("Your session is expired, please login to retry.");
            this.setState({ uiLoading: false });
            this.props.setLogin(false);
          }
        });
    }
    //retrieve the sector data from db
    authMiddleWare(this.props.history);
    if (authToken == null) {
      alert("Your session is expired, please login to retry.");
      this.setState({ uiLoading: false });
      this.props.setLogin(false);
    } else {
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      await axios
        .get(`${baseURL}/getAllLaunchPointType`)
        .then((response) => {
          this.setState({
            launchPointTypes: response.data,
            //uiLoading: false
          });
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 403) {
            alert("Your session is expired, please login to retry.");
            this.setState({ uiLoading: false });
            this.props.setLogin(false);
          }
        });
    }

    //patch the ground route block seq and selection data
    this.getGroundDropdownOption();
    //patch the upstairs route block seq and selection data
    this.getUpstairDropdownOption();
    //set the selected site
    this.setState({ site: this.props.site });
    this.setState({ editAddSite: { site: this.props.site } });
    //get the all release status record based on today record
    this.getAllStatus(this.state.selectedDate);
    //get all the date data that have release record and patch into array for display dot on the calendar
    this.getAllReleaseRecordDate(this.state.selectedDate);
  };

  getAllReleaseRecordDate = async (todayDate) => {
    authMiddleWare(this.props.history);
    const authToken = localStorage.getItem("AuthToken");
    if (authToken == null) {
      alert("Your session is expired, please login to retry.");
      this.setState({ uiLoading: false });
      this.props.setLogin(false);
    } else {
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      const formRequest = {
        site: this.state.site,
      };
      await axios
        .post(`${baseURL}/getAllReleaseRecordDate`, formRequest)
        .then((response) => {
          var selectDate = [];
          var uniqueArray = [];
          //parse the pass in date to date format
          var date = new Date(todayDate.toString());

          for (var i = 0; i < response.data.length; i++) {
            var resultDate = new Date(response.data[i].date.seconds * 1000);

            if (
              date.getMonth() + 1 === resultDate.getMonth() + 1 &&
              date.getFullYear() === resultDate.getFullYear()
            ) {
              //if the return result month and year match the pass in value month and year then push it into an array
              selectDate.push(resultDate);
            }
          }

          // Loop through array values, to filter out those redundant date, and push those unique date in array
          for (var j = 0; j < selectDate.length; j++) {
            if (uniqueArray.indexOf(selectDate[j].getDate()) === -1) {
              uniqueArray.push(selectDate[j].getDate());
            }
          }

          //assign the unique date data into state array for patching later
          this.setState({
            selectedDays: uniqueArray,
            uiLoading: false,
          });
        })
        .catch((err) => {
          if (err.response.status === 403) {
            alert("Your session is expired, please login to retry.");
            this.setState({ uiLoading: false });
            this.props.setLogin(false);
          }
          console.log(err);
        });
    }
  };

  getAllStatus = async (todayDate) => {
    this.setState({ uiLoading: true });
    //patch today selected date into date form
    var date = new Date(todayDate.toString());
    var month;
    var day;
    if (
      date.getMonth() + 1 === 10 ||
      date.getMonth() + 1 === 11 ||
      date.getMonth() + 1 === 12
    ) {
      month = date.getMonth() + 1;
    } else {
      month = "0" + (date.getMonth() + 1);
    }

    if (
      date.getDate() === 1 ||
      date.getDate() === 2 ||
      date.getDate() === 3 ||
      date.getDate() === 4 ||
      date.getDate() === 5 ||
      date.getDate() === 6 ||
      date.getDate() === 7 ||
      date.getDate() === 8 ||
      date.getDate() === 9
    ) {
      day = "0" + date.getDate();
    } else {
      day = date.getDate();
    }
    var finaldate = date.getFullYear() + "-" + month + "-" + day;
    //request to get all the status from the selected date
    authMiddleWare(this.props.history);
    const authToken = localStorage.getItem("AuthToken");
    if (authToken === null) {
      alert("Your session is expired, please login to retry.");
      this.setState({ uiLoading: false });
      this.props.setLogin(false);
    } else {
      axios.defaults.headers.common = { Authorization: `${authToken}` };

      await axios
        .post(`${baseURL}/getAllStatus/${this.state.site}/${finaldate}`)
        .then((response) => {
          var uniqueArray = [];

          // Loop through array values and patch the datas
          for (var i = 0; i < response.data.length; i++) {
            //get only unique block data status
            if (uniqueArray.indexOf(response.data[i].block) === -1) {
              uniqueArray.push({
                block: response.data[i].block,
                id: response.data[i].id,
                site: response.data[i].site,
                date: response.data[i].date,
                upstairStatus: response.data[i].upstairStatus,
                downstairStatus: response.data[i].downstairStatus,
                qr: response.data[i].qr,
                nfc: response.data[i].nfc,
                user: response.data[i].user,
                latitude: response.data[i].latitude,
                longitude: response.data[i].longitude,
              });
            }
          }

          this.setState({
            blockStatus: uniqueArray,
            uiLoading: false,
          });
        })
        .catch((err) => {
          if (err.response.status === 403) {
            alert("Your session is expired, please login to retry.");
            this.setState({ uiLoading: false });
            this.props.setLogin(false);
          }
          console.log(err);
        });
    }
  };

  getGroundDropdownOption = () => {
    const data = [];
    const groundSeq = [];
    //loop and patch the ground seq selection dropdown
    for (var i = 0; i < this.state.blocks.length; i++) {
      if (i < 9) {
        data.push({ level: "0" + (i + 1).toString() });
      } else {
        data.push({ level: (i + 1).toString() });
      }
    }
    //patch the ground seq in database record
    this.state.blocks.map((block) =>
      groundSeq.push({ level: block.groundRouteType.toString() })
    );
    //patch N.A selection
    data.push({ level: "N.A" });
    this.setState({ groundSelectData: data });
    this.setState({ groundSelectedData: groundSeq });
  };

  getUpstairDropdownOption = () => {
    const data = [];
    const upstairSeq = [];
    //loop and patch the upstair seq selection dropdown
    for (var i = 0; i < this.state.blocks.length; i++) {
      if (i < 9) {
        data.push({ level: "0" + (i + 1).toString() });
      } else {
        data.push({ level: (i + 1).toString() });
      }
    }
    //patch the upstair seq in database record
    this.state.blocks.map((block) =>
      upstairSeq.push({ level: block.upstairRouteType.toString() })
    );
    //patch N.A selection
    data.push({ level: "N.A" });
    this.setState({ upstairSelectData: data });
    this.setState({ upstairSelectedData: upstairSeq });
  };

  //delete release point
  deleteTag = async () => {
    //for refresh the table view right after deleted data
    dataTable = [];
    this.setState({ uiLoading: true });
    authMiddleWare(this.props.history);
    const authToken = localStorage.getItem("AuthToken");
    if (authToken == null) {
      alert("Your session is expired, please login to retry.");
      this.setState({ uiLoading: false });
      this.props.setLogin(false);
    } else {
      //fire the delete request
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      await axios
        .delete(`${baseURL}/deleteTag/${this.state.selectedTag}`)
        .then(async () => {
          //after delete the data, fetch the data from DB again and patch data
          authMiddleWare(this.props.history);
          const authToken = localStorage.getItem("AuthToken");
          axios.defaults.headers.common = { Authorization: `${authToken}` };
          const formRequest = {
            site: this.state.site,
          };
          await axios
            .post(`${baseURL}/allTag`, formRequest)
            .then((response) => {
              for (var i = 0; i < response.data.length; i++) {
                dataTable.push({
                  id: response.data[i].id,
                  block: response.data[i].block,
                  launchPoint: response.data[i].launchPoint,
                  latitude: response.data[i].latitude,
                  longitude: response.data[i].longitude,
                });
              }
              this.setState({
                openDeleteTag: false,
                errors: [],
                tableViewDataTag: dataTable,
                uiLoading: false,
              });
            })
            .catch((err) => {
              if (err.response.status === 403) {
                alert("Your session is expired, please login to retry.");
                this.setState({ uiLoading: false });
                this.props.setLogin(false);
              }
              console.log(err);
            });
        })
        .catch((err) => {
          if (err.response.status === 403) {
            alert("Your session is expired, please login to retry.");
            this.setState({ uiLoading: false });
            this.props.setLogin(false);
          }
          console.log(err);
        });
    }
  };

  //delete release point
  deletePoint = async () => {
    //for refresh the table view right after deleted data
    dataTable = [];
    this.setState({ uiLoading: true });
    authMiddleWare(this.props.history);
    const authToken = localStorage.getItem("AuthToken");
    if (authToken == null) {
      alert("Your session is expired, please login to retry.");
      this.setState({ uiLoading: false });
      this.props.setLogin(false);
    } else {
      //fire the delete request
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      let pointId = this.state.tableViewData[this.state.iValue].id;
      await axios
        .delete(`${baseURL}/deletePoint/${pointId}/${this.state.site}`)
        .then(async () => {
          //after delete the data, fetch the data from DB again and patch data
          authMiddleWare(this.props.history);
          const authToken = localStorage.getItem("AuthToken");
          axios.defaults.headers.common = { Authorization: `${authToken}` };
          const formRequest = {
            block: this.state.editAddBlockPoint.block,
            site: this.state.site,
          };
          await axios
            .post(`${baseURL}/point`, formRequest)
            .then((response) => {
              for (var i = 0; i < response.data.length; i++) {
                dataTable.push({
                  id: response.data[i].id,
                  block: response.data[i].block,
                  releasePoint: response.data[i].releasePointOne,
                  altReleasePoint: response.data[i].releasePointTwo,
                  level: { level: response.data[i].level },
                  pointType: response.data[i].pointType,
                  isAvailable: response.data[i].isAvailable,
                });
              }
              this.setState({
                tableViewData: dataTable,
                uiLoading: false,
                openDeletePoint: false,
                errors: [],
              });
            })
            .catch((err) => {
              if (err.response.status === 403) {
                alert("Your session is expired, please login to retry.");
                this.setState({ uiLoading: false });
                this.props.setLogin(false);
              }
              console.log(err);
            });
        })
        .catch((err) => {
          if (err.response.status === 403) {
            alert("Your session is expired, please login to retry.");
            this.setState({ uiLoading: false });
            this.props.setLogin(false);
          }
          console.log(err);
        });
    }
  };

  //delete block
  deleteBlock(data) {
    confirmAlert({
      title: "Confirm to detele the record?",
      message: "This might affect all others related data.",
      buttons: [
        {
          label: "Delete",
          onClick: async () => {
            //after click confirm, request to delete the data
            authMiddleWare(this.props.history);
            const authToken = localStorage.getItem("AuthToken");
            if (authToken == null) {
              alert("Your session is expired, please login to retry.");
              this.setState({ uiLoading: false });
              this.props.setLogin(false);
            } else {
              axios.defaults.headers.common = { Authorization: `${authToken}` };
              let blockId = data.block.id;
              let block = data.block.block;
              this.setState({ uiLoading: true });
              await axios
                .delete(
                  `${baseURL}/deleteBlock/${blockId}/${block}/${this.state.site}`
                )
                .then(async () => {
                  //get latest updated block data after block deleted
                  authMiddleWare(this.props.history);
                  const authToken = localStorage.getItem("AuthToken");
                  if (authToken == null) {
                    alert("Your session is expired, please login to retry.");
                    this.setState({ uiLoading: false });
                    this.props.setLogin(false);
                  } else {
                    axios.defaults.headers.common = {
                      Authorization: `${authToken}`,
                    };
                    const formRequest = {
                      site: this.state.site,
                    };
                    await axios
                      .post(`${baseURL}/block`, formRequest)
                      .then((response) => {
                        this.setState({
                          blocks: response.data,
                          uiLoading: false,
                          errors: [],
                        });
                      })
                      .catch((err) => {
                        if (err.response.status === 403) {
                          alert(
                            "Your session is expired, please login to retry."
                          );
                          this.setState({ uiLoading: false });
                          this.props.setLogin(false);
                        }
                        console.log(err);
                      });
                  }
                })
                .catch((err) => {
                  if (err.response.status === 403) {
                    alert("Your session is expired, please login to retry.");
                    this.setState({ uiLoading: false });
                    this.props.setLogin(false);
                  }
                  console.log(err);
                });
            }
          },
        },
        {
          label: "Cancel",
          onClick: () => onclose,
        },
      ],
    });
  }

  //delete point row data triggered and save the selected row index into iValue state variable while also open the delete point view, after the delete point view clicked confirm,
  //deletePoint() will triggered with delete the row index of iValue
  deleteItem = (i) => {
    this.setState({ openDeletePoint: true, iValue: i });
  };

  //render the point table data
  displayTableData = () => {
    //if the state contain no data, then render nth
    if (this.state.tableViewData.length === 0) {
      return null;
    } else {
      //else map each data to the table row
      return this.state.tableViewData.map((item, i) => (
        <Fragment>
          <TableRow key={`row-${i}`}>
            <TableCell>{item.block}</TableCell>
            <TableCell>
              {item.level.level}-{item.releasePoint}
            </TableCell>
            <TableCell>
              {item.level.level}-{item.altReleasePoint}
            </TableCell>
            <TableCell>{item.pointType}</TableCell>
            <TableCell>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={item.isAvailable}
                    onChange={async (event) => {
                      //click for isAvailable point update

                      //creates the clone of the state using a var
                      let a = this.state.tableViewData.slice();
                      //patch the new updated data row state into the slice array
                      a[i] = {
                        id: this.state.tableViewData[i].id,
                        block: this.state.tableViewData[i].block,
                        releasePoint: this.state.tableViewData[i].releasePoint,
                        altReleasePoint:
                          this.state.tableViewData[i].altReleasePoint,
                        level: this.state.tableViewData[i].level,
                        isAvailable: event.target.checked,
                      };
                      //assign back the updated slice array back to the table state data
                      this.setState({ tableViewData: a });

                      //then update the point details to the db from api
                      authMiddleWare(this.props.history);
                      event.preventDefault();

                      const pointAdd = {
                        isAvailable: event.target.checked,
                      };
                      let options = {
                        url: `${baseURL}/editPoint/${this.state.tableViewData[i].id}`,
                        method: "put",
                        data: pointAdd,
                      };

                      const authToken = localStorage.getItem("AuthToken");
                      if (authToken == null) {
                        alert(
                          "Your session is expired, please login to retry."
                        );
                        this.setState({ uiLoading: false });
                        this.props.setLogin(false);
                      } else {
                        axios.defaults.headers.common = {
                          Authorization: `${authToken}`,
                        };

                        this.setState({
                          uiLoading: true,
                        });

                        await axios(options)
                          .then(async () => {
                            this.setState({
                              errors: [],
                              uiLoading: false,
                            });
                          })
                          .catch((error) => {
                            console.log(error);
                            if (error.response.status === 403) {
                              alert(
                                "Your session is expired, please login to retry."
                              );
                              this.setState({ uiLoading: false });
                              this.props.setLogin(false);
                            }
                            this.setState({
                              open: true,
                              uiLoading: false,
                              errors: error.response.data,
                            });
                            console.log(error);
                          });
                      }
                    }}
                    name="isAvailablePoint"
                    color="primary"
                  />
                }
              />
            </TableCell>
            <TableCell>
              <Button
                variant="contained"
                color="primary"
                onClick={this.deleteItem.bind(this, i)}
              >
                Delete
              </Button>
            </TableCell>
          </TableRow>
        </Fragment>
      ));
    }
  };

  updateItemTag = (i) => {
    this.setState({
      isEditTag: true,
      selectedTag: i,
      blockTag: { block: i.block },
      launchPointTag: { launchPoint: i.launchPoint },
      latitudeTag: i.latitude,
      longitudeTag: i.longitude,
    });
  };

  deleteItemTag = (i) => {
    this.setState({ openDeleteTag: true, selectedTag: i });
  };

  //render the point table data
  displayTableDataTag = () => {
    //if the state contain no data, then render nth
    if (this.state.tableViewDataTag.length === 0) {
      return null;
    } else {
      //else map each data to the table row
      return this.state.tableViewDataTag.map((item, i) => (
        <Fragment>
          <TableRow key={`row-${i}`}>
            <TableCell>{item.block}</TableCell>
            <TableCell>{item.launchPoint}</TableCell>
            <TableCell>{item.latitude}</TableCell>
            <TableCell>{item.longitude}</TableCell>
            <TableCell>
              <IconButton
                color="primary"
                aria-label="Edit"
                onClick={this.updateItemTag.bind(this, item)}
              >
                <EditIcon style={{ fontSize: 18 }} />
              </IconButton>
              <IconButton
                color="primary"
                aria-label="Delete"
                onClick={this.deleteItemTag.bind(this, item.id)}
              >
                <DeleteIcon style={{ fontSize: 18 }} />
              </IconButton>
            </TableCell>
          </TableRow>
        </Fragment>
      ));
    }
  };

  //patch data and after that open the edit block dialogs when clicked edit block button
  handleEditBlock = async (data) => {
    dataTable = [];
    this.setState({ uiLoading: true });
    authMiddleWare(this.props.history);
    const authToken = localStorage.getItem("AuthToken");
    if (authToken == null) {
      alert("Your session is expired, please login to retry.");
      this.setState({ uiLoading: false });
      this.props.setLogin(false);
    } else {
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      const formRequest = {
        block: data.block.block,
        site: this.state.site,
      };
      await axios
        .post(`${baseURL}/point`, formRequest)
        .then((response) => {
          for (var i = 0; i < response.data.length; i++) {
            dataTable.push({
              id: response.data[i].id,
              block: response.data[i].block,
              releasePoint: response.data[i].releasePointOne,
              altReleasePoint: response.data[i].releasePointTwo,
              level: { level: response.data[i].level },
              pointType: response.data[i].pointType,
              isAvailable: response.data[i].isAvailable,
            });
          }
          this.setState({
            tableViewData: dataTable,
            uiLoading: false,
          });
        })
        .catch((err) => {
          if (err.response.status === 403) {
            alert("Your session is expired, please login to retry.");
            this.setState({ uiLoading: false });
            this.props.setLogin(false);
          }
          console.log(err);
        });
    }

    this.setState({
      //open: true,
      postal: data.block.postal,
      remarks: data.block.remarks,
      editAddBlock: { block: data.block.block },
      regionArea: { region: data.block.regionArea },
      meetingPoint: data.block.loadingPoint,
      isAvailable: data.block.isAvailable,
      pointType: data.block.pointType,
      editAddBlockPoint: { block: data.block.block },
      level: "",
      releasePoint: "",
      altReleasePoint: "",
      blockId: data.block.id,
      blockOld: data.block.block,
      buttonEnable: false,
    });

    this.handleOpenEditAddBlock();
  };

  //close the edit block dialogs
  handleCloseEditBlock = () => {
    this.setState({ open: false });
  };

  //open the add block dialog after click on the next button of choosing site
  handleOpenAddBlock = async () => {
    dataTable = [];
    this.setState({
      buttonType: "Add",
      openEditAddBlock: true,
      open: false,
      uiLoading: true,
      tableViewData: dataTable,
      blockSelectData: [],
      editAddBlock: "",
      editAddBlockPoint: "",
      buttonEnable: true,
    });
    authMiddleWare(this.props.history);
    const authToken = localStorage.getItem("AuthToken");
    if (authToken == null) {
      alert("Your session is expired, please login to retry.");
      this.setState({ uiLoading: false });
      this.props.setLogin(false);
    } else {
      var datalevel = [];
      var point = [];
      //patch the level data available to choose
      for (var j = 0; j < 25; j++) {
        datalevel.push({ level: (j + 1).toString() });
      }
      //patch the release point data available to choose
      for (var k = 0; k < 9999; k++) {
        point.push({ point: (k + 1).toString() });
      }
      const point1 = [
        ...[{ point: "Front" }],
        ...[{ point: "Lift Lobby" }],
        ...[{ point: "Back" }],
        ...point,
      ];
      const point2 = [...[{ point: "NA" }], ...[{ point: "Back" }], ...point];
      this.setState({
        uiLoading: false,
        levelSelectData: datalevel,
        releasePointSelectData: point1,
        altPointSelectData: point2,
      });
    }
  };

  //open the edit block dialog after done patching data
  handleOpenEditAddBlock = async () => {
    dataTable = [];
    this.setState({
      buttonType: "Edit",
      openEditAddBlock: true,
      open: false,
      uiLoading: true,
    });

    var datalevel = [];
    var point = [];
    //patch level data for dropdown
    for (var j = 0; j < 25; j++) {
      datalevel.push({ level: (j + 1).toString() });
    }
    //patch point data for dropdown
    for (var k = 0; k < 9999; k++) {
      point.push({ point: (k + 1).toString() });
    }
    const point1 = [
      ...[{ point: "Front" }],
      ...[{ point: "Lift Lobby" }],
      ...[{ point: "Back" }],
      ...point,
    ];
    const point2 = [...[{ point: "NA" }], ...[{ point: "Back" }], ...point];
    this.setState({
      uiLoading: false,
      levelSelectData: datalevel,
      releasePointSelectData: point1,
      altPointSelectData: point2,
    });
  };

  //close the edit block dialog
  handleCloseEditAddBlock = async () => {
    this.setState({ openEditAddBlock: false, open: false });
    authMiddleWare(this.props.history);
    const authToken = localStorage.getItem("AuthToken");
    if (authToken == null) {
      alert("Your session is expired, please login to retry.");
      this.setState({ uiLoading: false });
      this.props.setLogin(false);
    } else {
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      const formRequest = {
        site: this.state.site,
      };
      //fetch the latest block data
      await axios
        .post(`${baseURL}/block`, formRequest)
        .then((response) => {
          this.setState({
            blocks: response.data,
            errors: [],
          });
          //get the latest ground and total dropdown data
          this.getGroundDropdownOption();
          //get the latest upstairs and total dropdown data
          this.getUpstairDropdownOption();
          //get all release status based on date
          this.getAllStatus(this.state.selectedDate);
          //get all release record date
          this.getAllReleaseRecordDate(this.state.selectedDate);
        })
        .catch((err) => {
          if (err.response.status === 403) {
            alert("Your session is expired, please login to retry.");
            this.setState({ uiLoading: false });
            this.props.setLogin(false);
          }
          console.log(err);
        });
    }

    this.setState({
      uiLoading: false,
    });
  };

  //close delete point confirmation dialog box
  handleCloseDeletePoint = () => {
    this.setState({ openDeletePoint: false });
  };

  //close delete point confirmation dialog box
  handleCloseDeleteTag = () => {
    this.setState({ openDeleteTag: false });
  };

  //open view dialog box
  handleViewOpen = async (data) => {
    this.setState({ uiLoading: true });
    authMiddleWare(this.props.history);
    const authToken = localStorage.getItem("AuthToken");
    if (authToken == null) {
      alert("Your session is expired, please login to retry.");
      this.setState({ uiLoading: false });
      this.props.setLogin(false);
    } else {
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      const formRequest = {
        block: data.block.block,
        site: this.state.site,
      };
      //fetch the point data of the selected block
      await axios
        .post(`${baseURL}/point`, formRequest)
        .then((response) => {
          this.setState({
            points: response.data,
            uiLoading: false,
          });
        })
        .catch((err) => {
          if (err.response.status === 403) {
            alert("Your session is expired, please login to retry.");
            this.setState({ uiLoading: false });
            this.props.setLogin(false);
          }
          console.log(err);
        });
    }

    this.setState({
      block: data.block.block,
      regionArea: data.block.regionArea,
      upstairRouteType: data.block.upstairRouteType,
      groundRouteType: data.block.groundRouteType,
      isAvailable: data.block.isAvailable,
      meetingPoint: data.block.loadingPoint,
      viewOpen: true,
      errors: [],
    });
  };

  //patch the status dot color according to response state result
  getStatusDot = (data) => {
    if (this.state.blockStatus === "") {
      return (
        <span style={{ paddingTop: 10 }}>
          <WhiteRadioButtonUncheckedIcon
            style={{ marginLeft: 95, fontSize: 50 }}
          />
          <WhiteRadioButtonUncheckedIcon
            style={{ marginLeft: 90, fontSize: 50 }}
          />
        </span>
      );
    } else {
      for (var i = 0; i < this.state.blockStatus.length; i++) {
        if (this.state.blockStatus[i].block === data) {
          // if (this.state.blockStatus[i].upstairStatus === "completed") {
          // 	if (this.state.blockStatus[i].downstairStatus === "completed") {
          // 		return (
          // 			<span style={{ paddingTop: 10 }}>
          // 				<OrangeFiberManualRecordIcon style={{ marginLeft: 95, fontSize: 50 }} />
          // 				<OrangeFiberManualRecordIcon style={{ marginLeft: 90, fontSize: 50 }} />
          // 			</span>
          // 		);
          // 	} else if (this.state.blockStatus[i].downstairStatus === "in progress") {
          // 		return (
          // 			<span style={{ paddingTop: 10 }}>
          // 				<BlueFiberManualRecordTwoToneIcon style={{ marginLeft: 95, fontSize: 50 }} />
          // 				<OrangeFiberManualRecordIcon style={{ marginLeft: 90, fontSize: 50 }} />
          // 			</span>
          // 		);
          // 	} else if (this.state.blockStatus[i].downstairStatus === "NA") {
          // 		return (
          // 			<span style={{ paddingTop: 10 }}>
          // 				<GreyFiberManualRecordIcon style={{ marginLeft: 95, fontSize: 50 }} />N.A.
          // 				<OrangeFiberManualRecordIcon style={{ marginLeft: 90, fontSize: 50 }} />
          // 			</span>
          // 		);
          // 	} else {
          // 		return (
          // 			<span style={{ paddingTop: 10 }}>
          // 				<WhiteRadioButtonUncheckedIcon style={{ marginLeft: 95, fontSize: 50 }} />
          // 				<OrangeFiberManualRecordIcon style={{ marginLeft: 90, fontSize: 50 }} />
          // 			</span>
          // 		);
          // 	}

          // } else if (this.state.blockStatus[i].upstairStatus === "in progress") {
          // 	if (this.state.blockStatus[i].downstairStatus === "completed") {
          // 		return (
          // 			<span style={{ paddingTop: 10 }}>
          // 				<OrangeFiberManualRecordIcon style={{ marginLeft: 95, fontSize: 50 }} />
          // 				<BlueFiberManualRecordTwoToneIcon style={{ marginLeft: 90, fontSize: 50 }} />
          // 			</span>
          // 		);
          // 	} else if (this.state.blockStatus[i].downstairStatus === "in progress") {
          // 		return (
          // 			<span style={{ paddingTop: 10 }}>
          // 				<BlueFiberManualRecordTwoToneIcon style={{ marginLeft: 95, fontSize: 50 }} />
          // 				<BlueFiberManualRecordTwoToneIcon style={{ marginLeft: 90, fontSize: 50 }} />
          // 			</span>
          // 		);
          // 	} else if (this.state.blockStatus[i].downstairStatus === "NA") {
          // 		return (
          // 			<span style={{ paddingTop: 10 }}>
          // 				<GreyFiberManualRecordIcon style={{ marginLeft: 95, fontSize: 50 }} />N.A.
          // 				<BlueFiberManualRecordTwoToneIcon style={{ marginLeft: 90, fontSize: 50 }} />
          // 			</span>
          // 		);
          // 	} else {
          // 		return (
          // 			<span style={{ paddingTop: 10 }}>
          // 				<WhiteRadioButtonUncheckedIcon style={{ marginLeft: 95, fontSize: 50 }} />
          // 				<BlueFiberManualRecordTwoToneIcon style={{ marginLeft: 90, fontSize: 50 }} />
          // 			</span>
          // 		);
          // 	}
          // } else if (this.state.blockStatus[i].upstairStatus === "NA") {
          // 	if (this.state.blockStatus[i].downstairStatus === "completed") {
          // 		return (
          // 			<span style={{ paddingTop: 10 }}>
          // 				<OrangeFiberManualRecordIcon style={{ marginLeft: 95, fontSize: 50 }} />
          // 				<GreyFiberManualRecordIcon style={{ marginLeft: 90, fontSize: 50 }} />N.A.
          // 			</span>
          // 		);
          // 	} else if (this.state.blockStatus[i].downstairStatus === "in progress") {
          // 		return (
          // 			<span style={{ paddingTop: 10 }}>
          // 				<BlueFiberManualRecordTwoToneIcon style={{ marginLeft: 95, fontSize: 50 }} />
          // 				<GreyFiberManualRecordIcon style={{ marginLeft: 90, fontSize: 50 }} />N.A.
          // 			</span>
          // 		);
          // 	} else if (this.state.blockStatus[i].downstairStatus === "NA") {
          // 		return (
          // 			<span style={{ paddingTop: 10 }}>
          // 				<GreyFiberManualRecordIcon style={{ marginLeft: 95, fontSize: 50 }} />N.A.
          // 				<GreyFiberManualRecordIcon style={{ marginLeft: 90, fontSize: 50 }} />N.A.
          // 			</span>
          // 		);
          // 	} else {
          // 		return (
          // 			<span style={{ paddingTop: 10 }}>
          // 				<WhiteRadioButtonUncheckedIcon style={{ marginLeft: 95, fontSize: 50 }} />
          // 				<GreyFiberManualRecordIcon style={{ marginLeft: 90, fontSize: 50 }} />N.A.
          // 			</span>
          // 		);
          // 	}
          // } else {
          if (this.state.blockStatus[i].downstairStatus === "completed") {
            return (
              <span style={{ paddingTop: 10 }}>
                <OrangeFiberManualRecordIcon
                  style={{ marginLeft: 20, fontSize: 50 }}
                />
              </span>
            );
          } else if (
            this.state.blockStatus[i].downstairStatus === "in progress"
          ) {
            return (
              <span style={{ paddingTop: 10 }}>
                <BlueFiberManualRecordTwoToneIcon
                  style={{ marginLeft: 20, fontSize: 50 }}
                />
              </span>
            );
          } else if (this.state.blockStatus[i].downstairStatus === "NA") {
            return (
              <span style={{ paddingTop: 10 }}>
                <GreyFiberManualRecordIcon
                  style={{ marginLeft: 20, fontSize: 50 }}
                />
                N.A.
              </span>
            );
          } else {
            return (
              <span style={{ paddingTop: 10 }}>
                <WhiteRadioButtonUncheckedIcon
                  style={{ marginLeft: 20, fontSize: 50 }}
                />
              </span>
            );
          }
          // }
        }
        if (i + 1 === this.state.blockStatus.length) {
          return (
            <span style={{ paddingTop: 10 }}>
              <WhiteRadioButtonUncheckedIcon
                style={{ marginLeft: 20, fontSize: 50 }}
              />
            </span>
          );
        }
      }
    }
  };

  render() {
    const { classes } = this.props;

    //handle site dropdown changing
    const handleSiteDropdown = async (event) => {
      this.setState({ site: event.target.value, uiLoading: true });
      this.setState({ editAddSite: { site: event.target.value } });
      var siteTemp = event.target.value;
      authMiddleWare(this.props.history);
      const authToken = localStorage.getItem("AuthToken");
      if (authToken == null) {
        alert("Your session is expired, please login to retry.");
        this.setState({ uiLoading: false });
        this.props.setLogin(false);
      } else {
        axios.defaults.headers.common = { Authorization: `${authToken}` };
        const formRequest = {
          site: siteTemp,
        };
        //site changing and reload the blocks data
        await axios
          .post(`${baseURL}/block`, formRequest)
          .then((response) => {
            this.setState({
              blocks: response.data,
              errors: [],
              uiLoading: false,
            });
            this.getGroundDropdownOption();
            this.getUpstairDropdownOption();
            this.getAllStatus(this.state.selectedDate);
            this.getAllReleaseRecordDate(this.state.selectedDate);
          })
          .catch((err) => {
            if (err.response.status === 403) {
              alert("Your session is expired, please login to retry.");
              this.setState({ uiLoading: false });
              this.props.setLogin(false);
            }
            console.log(err);
          });
      }
    };

    const {
      open,
      openTag,
      openDeleteTag,
      isEditTag,
      selectedTag,
      errors,
      viewOpen,
      openEditAddBlock,
      openDeletePoint,
    } = this.state;

    //handle click open add blocks(the dialog with select site)
    const handleAddBlock = () => {
      this.setState({
        blockId: "",
        block: "",
        buttonType: "",
        errors: [],
        open: true,
        postal: "",
        groundRoute: "",
        upstairRoute: "",
        regionArea: "",
        isAvailable: true,
        meetingPoint: false,
        blockOld: "",
      });
    };

    //handle click open add tag(the dialog with select site)
    const handleAddTag = async () => {
      //retrieve all tag data from db
      authMiddleWare(this.props.history);
      const authToken = localStorage.getItem("AuthToken");
      if (authToken == null) {
        alert("Your session is expired, please login to retry.");
        this.setState({ uiLoading: false });
        this.props.setLogin(false);
      } else {
        let dataTable = [];
        axios.defaults.headers.common = { Authorization: `${authToken}` };
        const formRequest = {
          site: this.state.site,
        };
        //get updated tags and patch to table data
        await axios
          .post(`${baseURL}/allTag`, formRequest)
          .then((response) => {
            for (var i = 0; i < response.data.length; i++) {
              dataTable.push({
                id: response.data[i].id,
                block: response.data[i].block,
                launchPoint: response.data[i].launchPoint,
                latitude: response.data[i].latitude,
                longitude: response.data[i].longitude,
              });
            }
            console.log("kesini", dataTable, this.state.site);
            this.setState({
              openTag: true,
              tableViewDataTag: dataTable,
            });
          })
          .catch((err) => {
            if (err.response.status === 403) {
              alert("Your session is expired, please login to retry.");
              this.setState({ uiLoading: false });
              this.props.setLogin(false);
            }
            console.log(err);
          });
      }
    };
    //handle click close add tag(the dialog with select site)
    const handleCloseAddTag = () => {
      this.setState({
        openTag: false,
      });
    };
    const cancelEditTag = () => {
      this.setState({
        isEditTag: false,
        selectedTag: "",
        blockTag: { block: "" },
        launchPointTag: { launchPoint: "" },
        latitudeTag: "",
        longitudeTag: "",
      });
    };

    //postal refresh
    const handlePostalFetch = async () => {
      if (this.state.postal === "") {
        this.setState({ errors: { postal: "Please fill in postal code." } });
      } else {
        axios.defaults.headers.common = {};
        await axios
          .get(
            `https://developers.onemap.sg/commonapi/search?searchVal=${this.state.postal}&returnGeom=Y&getAddrDetails=Y&pageNum=1`
          )
          .then((response) => {
            if (response.data.found === 0) {
              alert(
                "Could not find the entered postal code, please try again."
              );
            } else {
              this.setState({
                editAddBlock: { block: response.data.results[0].BLK_NO },
              });
              this.setState({
                regionArea: { region: response.data.results[0].ROAD_NAME },
              });
              this.setState({
                editAddBlockPoint: { block: response.data.results[0].BLK_NO },
              });
              this.setState({ buttonEnable: false, errors: [] });
            }
          })
          .catch((error) => {
            console.log(error);
            this.setState({ errorMsg: "Error in retrieving the data" });
          });
      }
    };

    //save sequence button
    const handleSaveSequence = async (event) => {
      authMiddleWare(this.props.history);
      event.preventDefault();

      var blockSeqUpdate = [];

      //patch each block state datas into array
      for (var i = 0; i < this.state.blocks.length; i++) {
        blockSeqUpdate.push({
          block: this.state.blocks[i],
          upstairSeq: this.state.upstairSelectedData[i],
          groundSeq: this.state.groundSelectedData[i],
        });
      }

      var options = {
        url: `${baseURL}/editBlockSeq`,
        method: "post",
        data: blockSeqUpdate,
      };

      //post API to update block sequence
      const authToken = localStorage.getItem("AuthToken");
      if (authToken == null) {
        alert("Your session is expired, please login to retry.");
        this.setState({ uiLoading: false });
        this.props.setLogin(false);
      } else {
        axios.defaults.headers.common = { Authorization: `${authToken}` };

        this.setState({
          uiLoading: true,
        });
        await axios(options)
          .then(async () => {
            this.setState({
              errors: [],
              uiLoading: false,
            });
            //after update fetch refresh updated block data again
            authMiddleWare(this.props.history);
            const authToken = localStorage.getItem("AuthToken");
            axios.defaults.headers.common = { Authorization: `${authToken}` };
            const formRequest = {
              site: this.state.site,
            };
            await axios
              .post(`${baseURL}/block`, formRequest)
              .then((response) => {
                this.setState({
                  blocks: response.data,
                  uiLoading: false,
                });
              })
              .catch((err) => {
                if (err.response.status === 403) {
                  alert("Your session is expired, please login to retry.");
                  this.setState({ uiLoading: false });
                  this.props.setLogin(false);
                }
                console.log(err);
              });
          })
          .catch((error) => {
            console.log(error);
            if (error.response.status === 403) {
              alert("Your session is expired, please login to retry.");
              this.setState({ uiLoading: false });
              this.props.setLogin(false);
            }
            this.setState({ uiLoading: false, errors: error.response.data });
            console.log(error);
          });
      }
    };

    //view data dialog close
    const handleViewClose = () => {
      this.setState({ viewOpen: false });
    };

    //add tag
    const handleSubmitTag = async (event) => {
      dataTable = [];
      authMiddleWare(this.props.history);

      const tagAdd = {
        siteTag: this.state.site,
        blockTag: this.state.blockTag.block,
        launchPointTag: this.state.launchPointTag.launchPoint,
        latitudeTag: this.state.latitudeTag,
        longitudeTag: this.state.longitudeTag,
      };
      console.log(tagAdd);
      let options = {
        url: `${baseURL}/${isEditTag ? "editTag/" + selectedTag.id : "addTag"}`,
        method: isEditTag ? "put" : "post",
        data: tagAdd,
      };
      const authToken = localStorage.getItem("AuthToken");
      if (authToken == null) {
        alert("Your session is expired, please login to retry.");
        this.setState({ uiLoading: false });
        this.props.setLogin(false);
      } else {
        axios.defaults.headers.common = { Authorization: `${authToken}` };
        this.setState({
          uiLoading: true,
        });
        //add point api called
        await axios(options)
          .then(async () => {
            authMiddleWare(this.props.history);
            if (authToken == null) {
              alert("Your session is expired, please login to retry.");
              this.setState({ uiLoading: false });
              this.props.setLogin(false);
            } else {
              axios.defaults.headers.common = { Authorization: `${authToken}` };
              const formRequest = {
                site: this.state.site,
              };
              //get updated tags and patch to table data
              await axios
                .post(`${baseURL}/allTag`, formRequest)
                .then((response) => {
                  for (var i = 0; i < response.data.length; i++) {
                    dataTable.push({
                      id: response.data[i].id,
                      block: response.data[i].block,
                      launchPoint: response.data[i].launchPoint,
                      latitude: response.data[i].latitude,
                      longitude: response.data[i].longitude,
                    });
                  }
                  this.setState({
                    errors: [],
                    tableViewDataTag: dataTable,
                    blockTag: { block: "" },
                    launchPointTag: { launchPoint: "" },
                    latitudeTag: "",
                    longitudeTag: "",
                    isEditTag: false,
                    uiLoading: false,
                  });
                })
                .catch((err) => {
                  if (err.response.status === 403) {
                    alert("Your session is expired, please login to retry.");
                    this.setState({ uiLoading: false });
                    this.props.setLogin(false);
                  }
                  console.log(err);
                });
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.response.status === 403) {
              alert("Your session is expired, please login to retry.");
              this.setState({ uiLoading: false });
              this.props.setLogin(false);
            }
            this.setState({ uiLoading: false, errors: error.response.data });
            console.log(error);
          });
      }
    };

    //add point
    const handleSubmitPoint = async (event) => {
      dataTable = [];
      authMiddleWare(this.props.history);

      const pointAdd = {
        block: this.state.editAddBlockPoint.block,
        level: this.state.level.level,
        releasePointOne: this.state.releasePoint.point,
        releasePointTwo: this.state.altReleasePoint.point,
        pointType: this.state.pointType,
        isAvailablePoint: this.state.isAvailablePoint,
        status: "pending",
        type: "Ground",
        site: this.state.site,
      };
      let options = {
        url: `${baseURL}/addPoint`,
        method: "post",
        data: pointAdd,
      };
      const authToken = localStorage.getItem("AuthToken");
      if (authToken == null) {
        alert("Your session is expired, please login to retry.");
        this.setState({ uiLoading: false });
        this.props.setLogin(false);
      } else {
        axios.defaults.headers.common = { Authorization: `${authToken}` };

        this.setState({
          uiLoading: true,
        });
        //add point api called
        await axios(options)
          .then(async () => {
            authMiddleWare(this.props.history);
            if (authToken == null) {
              alert("Your session is expired, please login to retry.");
              this.setState({ uiLoading: false });
              this.props.setLogin(false);
            } else {
              axios.defaults.headers.common = { Authorization: `${authToken}` };
              const formRequest = {
                block: this.state.editAddBlockPoint.block,
                site: this.state.site,
              };
              //get updated points and patch to table data
              await axios
                .post(`${baseURL}/point`, formRequest)
                .then((response) => {
                  for (var i = 0; i < response.data.length; i++) {
                    dataTable.push({
                      id: response.data[i].id,
                      block: response.data[i].block,
                      releasePoint: response.data[i].releasePointOne,
                      altReleasePoint: response.data[i].releasePointTwo,
                      level: { level: response.data[i].level },
                      pointType: response.data[i].pointType,
                      isAvailable: response.data[i].isAvailable,
                    });
                  }
                  this.setState({
                    errors: [],
                    tableViewData: dataTable,
                    uiLoading: false,
                  });
                })
                .catch((err) => {
                  if (err.response.status === 403) {
                    alert("Your session is expired, please login to retry.");
                    this.setState({ uiLoading: false });
                    this.props.setLogin(false);
                  }
                  console.log(err);
                });
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.response.status === 403) {
              alert("Your session is expired, please login to retry.");
              this.setState({ uiLoading: false });
              this.props.setLogin(false);
            }
            this.setState({
              open: true,
              uiLoading: false,
              errors: error.response.data,
            });
            console.log(error);
          });
      }
    };

    //add or edit block
    const handleSubmitBlock = async (event) => {
      // var blockNo = '';
      var latitude;
      var longitude;
      axios.defaults.headers.common = {};
      //get the coordinate details
      await axios
        .get(
          `https://developers.onemap.sg/commonapi/search?searchVal=${this.state.postal}&returnGeom=Y&getAddrDetails=Y&pageNum=1`
        )
        .then((response) => {
          if (response.data.found === 0) {
            alert("Could not find the entered postal code, please try again.");
          } else {
            // blockNo = response.data.results[0].BLK_NO;
            latitude = response.data.results[0].LATITUDE;
            longitude = response.data.results[0].LONGITUDE;
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({ errorMsg: "Error in retrieving the data" });
        });

      authMiddleWare(this.props.history);

      //patch the add or update datas
      const blockEdit = {
        isAvailable: this.state.isAvailable,
        loadingPoint: this.state.meetingPoint,
      };

      const blockAdd = {
        block: this.state.editAddBlock,
        site: this.state.editAddSite,
        latitude: latitude,
        longitude: longitude,
        isAvailable: this.state.isAvailable,
        loadingPoint: this.state.meetingPoint,
        regionArea: this.state.regionArea,
        status: "pending",
        postal: this.state.postal,
        remarks: this.state.remarks,
      };
      let options = {};
      if (this.state.buttonType === "Edit") {
        options = {
          url: `${baseURL}/editBlock/${this.state.blockId}`,
          method: "put",
          data: blockEdit,
        };
      } else {
        options = {
          url: `${baseURL}/addBlock`,
          method: "post",
          data: blockAdd,
        };
      }
      const authToken = localStorage.getItem("AuthToken");
      axios.defaults.headers.common = { Authorization: `${authToken}` };

      this.setState({
        uiLoading: true,
      });
      //request to api for database update
      axios(options)
        .then(async () => {
          this.setState({
            open: false,
            editAddBlockPoint: this.state.editAddBlock,
            uiLoading: false,
            errors: [],
          });
          alert("Block details updated.");
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 403) {
            alert("Your session is expired, please login to retry.");
            this.setState({ uiLoading: false });
            this.props.setLogin(false);
          }
          this.setState({
            open: true,
            uiLoading: false,
            errors: error.response.data,
          });
          console.log(error);
        });
    };

    //calander date change
    const handleDateChange = (date) => {
      this.setState({ selectedDate: date });
      this.getAllStatus(date);
    };

    //calander month change
    const handleMonthChange = (date) => {
      this.getAllReleaseRecordDate(date);
    };

    //calander year change
    const handleYearChange = (date) => {
      this.getAllReleaseRecordDate(date);
    };

    //click add blocks to show the dialog box component view
    const body = (
      <div style={{ top: "40%", left: "40%" }} className={classes.paper}>
        <h2 id="select-site">Sector Name</h2>
        <div id="confirm-site">
          <Autocomplete
            options={this.state.sites}
            getOptionLabel={(option) => option.site}
            id={"siteName"}
            renderInput={(params) => (
              <TextField
                {...params}
                id={"siteName"}
                margin="normal"
                required
                helperText={errors["siteName"]}
                error={errors["siteName"] ? true : false}
              />
            )}
            onChange={(event, value) => {
              this.setState({ editAddSite: value });
            }}
            value={this.state.editAddSite}
          />
          <Button
            style={{ marginTop: 10, marginLeft: 280 }}
            size="small"
            color="primary"
            onClick={this.handleOpenAddBlock}
          >
            Next
          </Button>
        </div>
      </div>
    );

    //the edit or add block and point dialog box component view
    const bodyEditAddBlock = (
      <div style={{ left: "30%" }} className={classes.paperBlock}>
        <h2 id="edit-block">
          {this.state.buttonType === "Edit" ? "Edit Block" : "Add Block"}
        </h2>
        <div id="content-edit-block">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={5}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="postal"
                label="Postal Code"
                name="postal"
                type="number"
                autoComplete="postalCode"
                helperText={errors.postal}
                value={this.state.postal}
                error={errors.postal ? true : false}
                onChange={this.handleChangeNormalField}
                disabled={this.state.buttonType === "Edit" ? true : false}
              />
            </Grid>

            <Grid item xs={12} sm={2} style={{ textAlign: "center" }}>
              <IconButton
                aria-label="refresh"
                onClick={handlePostalFetch}
                disabled={this.state.buttonType === "Edit" ? true : false}
              >
                <CachedIcon style={{ fontSize: 40 }} />
              </IconButton>
            </Grid>

            <Grid item xs={12} sm={5}>
              <Autocomplete
                style={{ width: "100%" }}
                options={this.state.blockSelectData}
                getOptionLabel={(option) => option.block}
                id={"blockSelectedData"}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Block"
                    id={"blockSelectedData"}
                    required
                    helperText={errors.block}
                    error={errors.block ? true : false}
                  />
                )}
                onChange={(event, value) => {
                  this.setState({ editAddBlock: value });
                }}
                value={this.state.editAddBlock}
                disabled={true}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Autocomplete
                style={{ width: "100%" }}
                options={this.state.regionSelectData}
                getOptionLabel={(option) => option.region}
                id={"regionSelectedData"}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Road Name"
                    id={"regionSelectedData"}
                    required
                    helperText={errors["regionSelect"]}
                    error={errors["regionSelect"] ? true : false}
                  />
                )}
                onChange={(event, value) => {
                  this.setState({ regionArea: value });
                }}
                value={this.state.regionArea}
                disabled={true}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.meetingPoint}
                    onChange={this.handleChangeCheck}
                    name="meetingPoint"
                    color="primary"
                  />
                }
                label="Is Meeting Point?"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                fullWidth
                id="remarks"
                label="Remarks"
                name="remarks"
                autoComplete="remarks"
                helperText={errors.remarks}
                value={this.state.remarks}
                error={errors.remarks ? true : false}
                onChange={this.handleChangeNormalField}
                disabled={this.state.buttonType === "Edit" ? true : false}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.isAvailable}
                    onChange={this.handleChangeCheck}
                    name="isAvailable"
                    color="primary"
                  />
                }
                label="Is Available? (uncheck if release block canceled)"
              />
            </Grid>
            <Button
              variant="contained"
              style={{ marginLeft: 650 }}
              size="small"
              color="primary"
              disabled={this.state.buttonEnable}
              onClick={() => {
                handleSubmitBlock();
              }}
            >
              {this.state.buttonType === "Edit" ? "UPDATE" : "ADD"}
            </Button>
          </Grid>
        </div>
        <Divider variant="middle" style={{ marginTop: 15 }} />
        <h2 id="title-edit-point">
          {this.state.buttonType === "Edit"
            ? "Edit Release Point"
            : "Add Release Point"}
        </h2>
        <div id="content-edit-point">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                style={{ width: "100%" }}
                options={this.state.blockSelectData}
                getOptionLabel={(option) => option.block}
                id={"blockSelectedData"}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Block"
                    id={"blockSelectedData"}
                    required
                    helperText={errors.editAddBlockPoint}
                    error={errors.editAddBlockPoint ? true : false}
                  />
                )}
                onChange={(event, value) => {
                  this.setState({ editAddBlockPoint: value });
                }}
                value={this.state.editAddBlockPoint}
                disabled={true}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Autocomplete
                style={{ width: "100%" }}
                options={this.state.levelSelectData}
                getOptionLabel={(option) => option.level}
                id={"levelSelectedData"}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Level"
                    id={"levelSelectedData"}
                    required
                    helperText={errors.levelSelectedData}
                    error={errors.levelSelectedData ? true : false}
                  />
                )}
                onChange={(event, value) => {
                  this.setState({ level: value });
                }}
                value={this.state.level}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Autocomplete
                style={{ width: "100%" }}
                options={this.state.releasePointSelectData}
                getOptionLabel={(option) => option.point}
                id={"releasePointSelectedData"}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Release Point"
                    id={"releasePointSelectedData"}
                    required
                    helperText={errors.releasePointSelectedData}
                    error={errors.releasePointSelectedData ? true : false}
                  />
                )}
                onChange={(event, value) => {
                  this.setState({ releasePoint: value });
                }}
                value={this.state.releasePoint}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Autocomplete
                style={{ width: "100%" }}
                options={this.state.altPointSelectData}
                getOptionLabel={(option) => option.point}
                id={"altReleasePoint"}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Alternative Release Point"
                    id={"altReleasePoint"}
                    required
                    helperText={errors.altReleasePoint}
                    error={errors.altReleasePoint ? true : false}
                  />
                )}
                onChange={(event, value) => {
                  this.setState({ altReleasePoint: value });
                }}
                value={this.state.altReleasePoint}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Autocomplete
                style={{ width: "100%" }}
                options={this.state.launchPointTypes}
                id={"pointType"}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Point Type"
                    id={"pointType"}
                    required
                    helperText={errors.pointType}
                    error={errors.pointType ? true : false}
                  />
                )}
                onChange={(event, value) => {
                  this.setState({ pointType: value });
                }}
                value={this.state.pointType}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.isAvailablePoint}
                    onChange={this.handleChangeCheck}
                    name="isAvailablePoint"
                    color="primary"
                  />
                }
                label="Is Available? (uncheck if release point canceled)"
              />
            </Grid>

            <Button
              variant="contained"
              style={{ marginLeft: 650 }}
              size="small"
              color="primary"
              disabled={
                !this.state.editAddBlockPoint ||
                !this.state.level ||
                !this.state.releasePoint ||
                !this.state.pointType ||
                !this.state.altReleasePoint
              }
              onClick={handleSubmitPoint}
            >
              {this.state.buttonType === "Edit" ? "ADD" : "ADD"}
            </Button>
          </Grid>
        </div>
        <Divider variant="middle" style={{ marginTop: 15 }} />
        <Paper className={classes.paperTable}>
          <Table stickyHeader className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Blocks</TableCell>
                <TableCell>Release Points</TableCell>
                <TableCell>Alternative Release Points</TableCell>
                <TableCell>Point Type</TableCell>
                <TableCell>Is Available</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{this.displayTableData()}</TableBody>
          </Table>
        </Paper>
      </div>
    );

    //the edit or add block and point dialog box component view
    const bodyEditAddTag = (
      <div style={{ left: "30%" }} className={classes.paperBlock}>
        <h2 id="edit-tag">{isEditTag ? "Edit Tag" : "Add Tag"}</h2>
        <div id="content-edit-tag">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                style={{ width: "100%" }}
                options={this.state.blocks}
                getOptionLabel={(option) => option.block}
                id={"blockTag"}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Block"
                    id={"blockTag"}
                    required
                    helperText={errors.blockTag}
                    error={errors.blockTag ? true : false}
                  />
                )}
                onChange={(event, value) => {
                  this.setState({ blockTag: value });
                }}
                value={this.state.blockTag}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Autocomplete
                style={{ width: "100%" }}
                options={this.state.allLaunchPoint}
                getOptionLabel={(option) => option.launchPoint}
                id={"launchPointTag"}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Unit"
                    id={"launchPointTag"}
                    required
                    helperText={errors.launchPointTag}
                    error={errors.launchPointTag ? true : false}
                  />
                )}
                onChange={(event, value) => {
                  this.setState({ launchPointTag: value });
                }}
                value={this.state.launchPointTag}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                fullWidth
                required
                id="latitudeTag"
                label="Latitude"
                name="latitudeTag"
                autoComplete="latitudeTag"
                helperText={errors.latitudeTag}
                value={this.state.latitudeTag}
                error={errors.latitudeTag ? true : false}
                onChange={this.handleChangeNormalField}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                fullWidth
                required
                id="longitudeTag"
                label="Longitude"
                name="longitudeTag"
                autoComplete="longitudeTag"
                helperText={errors.longitudeTag}
                value={this.state.longitudeTag}
                error={errors.longitudeTag ? true : false}
                onChange={this.handleChangeNormalField}
              />
            </Grid>

            {isEditTag && (
              <Button
                variant="contained"
                style={{ marginLeft: 550 }}
                size="small"
                color="primary"
                onClick={cancelEditTag}
              >
                Cancel
              </Button>
            )}

            <Button
              variant="contained"
              style={{ marginLeft: isEditTag ? 10 : 650 }}
              size="small"
              color="primary"
              onClick={handleSubmitTag}
            >
              {isEditTag ? "Update" : "Add"}
            </Button>
          </Grid>
        </div>
        <Divider variant="middle" style={{ marginTop: 15 }} />
        <Paper className={classes.paperTable}>
          <Table stickyHeader className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Blocks</TableCell>
                <TableCell>Unit</TableCell>
                <TableCell>Latitude</TableCell>
                <TableCell>Longitude</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{this.displayTableDataTag()}</TableBody>
          </Table>
        </Paper>
      </div>
    );

    //check if the site having any record of blocks, and return relevant component view
    if (this.state.blocks === "") {
      ifBlockGotRecords = (
        <Typography
          style={{ marginLeft: "10%", paddingTop: 10 }}
          variant="h5"
          component="h5"
        >
          No Records!
        </Typography>
      );
    } else {
      ifBlockGotRecords = (
        <Grid container spacing={2} alignItems="center" justify="center">
          {this.state.blocks.map((block, index) => (
            <Grid item xs={10} sm={10}>
              <Card className={classes.root} variant="outlined">
                <div
                  style={{
                    display: "flex",
                    margin: "auto",
                    justifyContent: "space-between",
                  }}
                >
                  <CardContent style={{ display: "flex", width: "100%" }}>
                    <Typography
                      style={{
                        width: "10%",
                        textAlign: "center",
                        paddingTop: 15,
                      }}
                      variant="h5"
                      component="h2"
                    >
                      {block.block}
                    </Typography>
                    <div
                      style={{
                        width: "22%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Autocomplete
                        style={{ width: "50%" }}
                        options={this.state.groundSelectData}
                        getOptionLabel={(option) => option.level}
                        id={"groundSelectedData" + index}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id={"groundSelectedData" + index}
                            margin="normal"
                            required
                            helperText={errors["groundSelect" + index]}
                            error={
                              errors["groundSelect" + index] ? true : false
                            }
                          />
                        )}
                        onChange={(event, value) => {
                          let a = this.state.groundSelectedData.slice(); //creates the clone of the state
                          a[index] = value;
                          this.setState({ groundSelectedData: a });
                        }}
                        value={this.state.groundSelectedData[index]}
                      />
                    </div>
                    {/* <Autocomplete
                      style={{ marginLeft: 80 }}
                      options={this.state.upstairSelectData}
                      getOptionLabel={(option) => option.level}
                      id={"upstairSelectedData" + index}
                      renderInput={(params) => <TextField {...params} id={"upstairSelectedData" + index} margin="normal" required helperText={errors["upstairSelect" + index]} error={errors["upstairSelect" + index] ? true : false} />}
                      onChange={(event, value) => {
                        let a = this.state.upstairSelectedData.slice(); //creates the clone of the state
                        a[index] = value;
                        this.setState({ upstairSelectedData: a });
                      }}
                      value={this.state.upstairSelectedData[index]}
                    /> */}
                    {this.getStatusDot(block.block)}
                  </CardContent>
                  <CardActions style={{ marginRight: "16px" }}>
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => this.handleViewOpen({ block })}
                    >
                      View
                    </Button>
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => this.handleEditBlock({ block })}
                    >
                      Edit
                    </Button>
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => this.deleteBlock({ block })}
                    >
                      Delete
                    </Button>
                  </CardActions>
                </div>
              </Card>
            </Grid>
          ))}
        </Grid>
      );
    }

    //loading UI
    if (this.state.uiLoading === true) {
      return (
        <div className={classes.root}>
          {this.state.uiLoading && (
            <CircularProgress size={150} className={classes.uiProgess} />
          )}
        </div>
      );
    } else {
      //not loading UI
      return (
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {/* top bar status indicator */}
          <div>
            <Typography
              style={{ paddingBottom: 10 }}
              variant="h7"
              component="h3"
            >
              Indicator:
            </Typography>

            <BlueFiberManualRecordTwoToneIcon style={{ fontSize: 55 }} />
            <span>Pending</span>

            <OrangeFiberManualRecordIcon style={{ fontSize: 50 }} />
            <span>Complete</span>
            <WhiteRadioButtonUncheckedIcon style={{ fontSize: 50 }} />
            <span>No record</span>
          </div>

          <div className={classes.topArrange}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                style={{ marginRight: 10 }}
                disableToolbar
                variant="inline"
                format="dd-MM-yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Release Date"
                value={this.state.selectedDate}
                onChange={handleDateChange}
                onMonthChange={handleMonthChange}
                onYearChange={handleYearChange}
                color="primary"
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                renderDay={(
                  day,
                  selectedDate,
                  isInCurrentMonth,
                  dayComponent
                ) => {
                  const date = new Date(day);
                  //patch the selected date from state selectedDays
                  const isSelected =
                    isInCurrentMonth &&
                    this.state.selectedDays.includes(date.getDate());

                  // You can also use our internal <Day /> component
                  return (
                    <Badge
                      overlap={"circular"}
                      badgeContent={isSelected ? "⚫" : undefined}
                    >
                      {dayComponent}
                    </Badge>
                  );
                }}
              />
            </MuiPickersUtilsProvider>

            <FormControl
              variant="outlined"
              style={{ width: "150px", paddingTop: 10 }}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Sector
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={this.state.site}
                onChange={handleSiteDropdown}
                color="primary"
                label="Sector"
              >
                {this.state.sites.map((site) => (
                  <MenuItem value={site.site}>{site.site}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          {/* <Typography style={{ marginLeft: '10%', paddingBottom: 10 }} variant="h7" component="h3">
						Block No.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ground Sequence&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Upstair Sequence&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ground Status&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Upstair Status
					</Typography> */}
          <Grid container spacing={2} alignItems="center" justify="center">
            <Grid
              item
              xs={10}
              sm={10}
              style={{ display: "flex", paddingBottom: 10 }}
            >
              <Typography
                style={{ width: "10%", textAlign: "center" }}
                variant="h7"
                component="h3"
              >
                Block No.
              </Typography>
              <Typography
                style={{ width: "15%", textAlign: "center" }}
                variant="h7"
                component="h3"
              >
                Sequence
              </Typography>
              <Typography
                style={{ width: "10%", textAlign: "center" }}
                variant="h7"
                component="h3"
              >
                Status
              </Typography>
            </Grid>
          </Grid>

          {/* floating style button */}
          <IconButton
            className={classes.floatingButton}
            color="primary"
            aria-label="Add Block"
            onClick={handleAddBlock}
          >
            <AddCircleIcon style={{ fontSize: 60 }} />
          </IconButton>

          <IconButton
            className={classes.floatingSaveButton}
            color="primary"
            aria-label="Save"
            onClick={handleSaveSequence}
          >
            <SaveIcon style={{ fontSize: 60 }} />
          </IconButton>

          <IconButton
            className={classes.floatingTagButton}
            color="primary"
            aria-label="Tag"
            onClick={handleAddTag}
          >
            <BookmarkIcon style={{ fontSize: 60 }} />
          </IconButton>

          {
            //render the component
            ifBlockGotRecords
          }

          {/*all dialog box and modal component*/}
          <Modal
            open={open}
            onClose={this.handleCloseEditBlock}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {body}
          </Modal>

          <Modal
            open={openEditAddBlock}
            onClose={this.handleCloseEditAddBlock}
            aria-labelledby="title-edit-block"
            aria-describedby="description-edit-block"
          >
            {bodyEditAddBlock}
          </Modal>

          <Modal
            open={openTag}
            onClose={handleCloseAddTag}
            aria-labelledby="title-add-tag"
            aria-describedby="description-add-tag"
          >
            {bodyEditAddTag}
          </Modal>

          <Dialog
            open={openDeleteTag}
            onClose={this.handleCloseDeleteTag}
            aria-labelledby="delete-point-title"
            aria-describedby="delete-point-description"
          >
            <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                This will delete the selected record.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseDeleteTag} color="primary">
                Cancel
              </Button>
              <Button onClick={this.deleteTag} color="primary" autoFocus>
                Confirm
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={openDeletePoint}
            onClose={this.handleCloseDeletePoint}
            aria-labelledby="delete-point-title"
            aria-describedby="delete-point-description"
          >
            <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                This will delete the selected record.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseDeletePoint} color="primary">
                Cancel
              </Button>
              <Button onClick={this.deletePoint} color="primary" autoFocus>
                Confirm
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            onClose={handleViewClose}
            aria-labelledby="customized-dialog-title"
            open={viewOpen}
            fullWidth
            classes={{ paperFullWidth: classes.dialogeStyle }}
          >
            <DialogTitle id="customized-dialog-title" onClose={handleViewClose}>
              Block NO: {this.state.block}
            </DialogTitle>
            <DialogContent dividers>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.meetingPoint}
                    onChange={this.handleChangeCheck}
                    name="meetingPointView"
                    color="primary"
                    disabled={true}
                  />
                }
                label="Is Meeting Point?"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.isAvailable}
                    onChange={this.handleChangeCheck}
                    name="isAvailableView"
                    color="primary"
                    disabled={true}
                  />
                }
                label="Is Available?"
              />

              <Divider variant="middle" style={{ marginTop: 15 }} />
              <Paper className={classes.paperTablePoints}>
                <Table stickyHeader className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Release Point</TableCell>
                      <TableCell>Alternative Release Point</TableCell>
                      <TableCell>Point Type</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.points.map((item, i) => (
                      <Fragment>
                        <TableRow key={`row-${i}`}>
                          <TableCell>
                            {item.level}-{item.releasePointOne}
                          </TableCell>
                          <TableCell>
                            {item.level}-{item.releasePointTwo}
                          </TableCell>
                          <TableCell>{item.pointType}</TableCell>
                          <TableCell>{item.status}</TableCell>
                        </TableRow>
                      </Fragment>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </DialogContent>
          </Dialog>
        </main>
      );
    }
  }
}

//styles
const styles = (theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    //get current device width
    width: window.innerWidth - 300,
  },
  toolbar: theme.mixins.toolbar,
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  submitButton: {
    display: "block",
    color: "white",
    textAlign: "center",
    position: "absolute",
    top: 14,
    right: 10,
  },
  floatingButton: {
    position: "fixed",
    bottom: 0,
    right: 0,
  },
  floatingSaveButton: {
    position: "fixed",
    bottom: 0,
    right: 80,
  },
  floatingTagButton: {
    position: "fixed",
    bottom: 0,
    right: 160,
  },
  form: {
    width: "98%",
    marginLeft: 13,
  },
  root: {
    minWidth: 470,
    borderRadius: 25,
    height: 100,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  pos: {
    marginBottom: 12,
  },
  uiProgess: {
    position: "fixed",
    zIndex: "1000",
    height: "31px",
    width: "31px",
    left: "50%",
    top: "35%",
  },
  dialogeStyle: {
    maxWidth: "50%",
  },
  viewRoot: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  topArrange: {
    marginLeft: "70%",
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  paperBlock: {
    position: "absolute",
    width: 800,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  paperTable: {
    width: "100%",
    marginTop: theme.spacing(2),
    overflowX: "auto",
    maxHeight: 200,
  },
  table: {
    minWidth: 700,
  },
  paperTablePoints: {
    width: "100%",
    marginTop: theme.spacing(2),
    overflowX: "auto",
    maxHeight: 500,
  },
});

//export the component so other could use
export default withStyles(styles)(block);
